<template>
  <f7-page>
    <f7-navbar :back-link="$t('back')" :title="form.project_name">
    </f7-navbar>
    <f7-list no-hairlines-md form class="">
        <!-- 管理No -->
        <f7-list-input
            :label="$t('management No')"
            type="text"
            :value="form.id"
            readonly>
        </f7-list-input>
        <!-- 登録日 -->
        <f7-list-input
            :label="$t('updated at')"
            type="text"
            :value="form.updated_at"
            readonly>
        </f7-list-input>
        <!-- 案件説明詳細 -->
        <f7-list-input
            outline
            :label="$t('req description')"
            type="textarea"
            resizable
            :value="form.req_desc"
            readonly>
        </f7-list-input>
        <!-- スキル -->
        <f7-list-input
            :label="$t('skill')"
            type="textarea" resizable
            :value="form.skill"
            readonly>
        </f7-list-input>
        <!-- 語学能力 -->
        <f7-list-input
            :label="$t('language skills')"
            type="textarea" resizable
            :value="form.foreign_language"
            readonly>
        </f7-list-input>
        <!-- 最寄りの駅 -->
        <f7-list-input
            :label="$t('nearest station')"
            type="text"
            :value="form.nearest_station"
            readonly>
        </f7-list-input>
        <!-- 稼働可能日 -->
        <f7-list-input
            :label="$t('available day')"
            type="text"
            :value="form.available_day"
            readonly>
        </f7-list-input>
        <!-- 参画工程 -->
        <f7-list-input
            :label="$t('process')"
            type="textarea" resizable
            :value="form.participation_process"
            readonly>
        </f7-list-input>
        <!-- 管理経験 -->
        <f7-list-input
            :label="$t('manage experience')"
            type="text"
            :value="form.manage_experience"
            readonly>
        </f7-list-input>
        <!-- 経験年数 -->
        <f7-list-input
            :label="$t('experience years')"
            type="text"
            :value="form.experience_years"
            readonly>
        </f7-list-input>
        <!-- 希望単価 -->
        <f7-list-input
            :label="$t('desired price')"
            type="text"
            :value="form.desired_price"
            readonly>
        </f7-list-input>
        <!-- 所属会社 -->
        <f7-list-input
            :label="$t('company belongs')"
            type="text"
            :value="form.company_name"
            readonly>
        </f7-list-input>
        <!-- wechat -->
        <f7-list-input
            :label="$t('wechat no')"
            type="text"
            :value="form.wechat_no"
            readonly>
        </f7-list-input>
        <!-- コメント -->
        <f7-list-input
            :label="$t('comment')"
            type="text"
            :value="form.comment"
            readonly>
        </f7-list-input>
        <!-- complaint_reasons -->
        <f7-list-input
            outline
            :label="$t('complaint reason')"
            type="textarea"
            resizable
            :value="complaint_reasons"
            readonly>
        </f7-list-input>
        <f7-list-button v-if="platformId=='ios'"
            type="submit"
            :loading="form.busy"
            v-on:click.stop.prevent="delete_complaint_project(0)">
            通報のみ削除
        </f7-list-button>
        <f7-list-item v-else>
            <f7-col></f7-col>
            <f7-col>
              <f7-button large v-on:click.stop.prevent="delete_complaint_project(0)">
                通報のみ削除
              </f7-button>
            </f7-col>
            <f7-col></f7-col>
        </f7-list-item>

        <f7-list-button v-if="platformId=='ios'"
            type="submit"
            :loading="form.busy"
            v-on:click.stop.prevent="delete_complaint_project(1)">
            {{ $t('delete') }}
        </f7-list-button>
        <f7-list-item v-else>
            <f7-col></f7-col>
            <f7-col>
              <f7-button large v-on:click.stop.prevent="delete_complaint_project(1)">
                {{ $t('delete') }}
              </f7-button>
            </f7-col>
            <f7-col></f7-col>
        </f7-list-item>
    </f7-list>
  </f7-page>
</template>

<script>
import { Device }  from 'framework7/framework7-lite.esm.bundle.js'
import axios from 'axios'
import swal from "sweetalert"
import Form from 'vform'

export default {
  name: 'DetailComplaintProject',
  data() {
    return {
      complaint_id: null,
      project_id: null,
      form: new Form({
        kbn: null,
        complaint_id: '',
        id: '',
        project_name: '',
        req_desc: '',
        available_day: '',
        nearest_station: '',
        experience_years: '',
        desired_price: '',
        wechat_no: '',
        comment: '',
        company_id: '',
        company_name: '',
        updated_at: '',
        skill: '',
        foreign_language: '',
        participation_process: '',
        manage_experience: '',
      }),
      complaint_reasons: '',
      platformId: 'ios',
    };
  },
  created() {
    if (!Device.cordova) {
      this.platformId = 'ios'  //pcの場合iosとする
    } else {
      this.platformId = cordova.platformId //ios,android,osx
    }
  },
  mounted() {
    this.complaint_id = this.$f7route.params.complaint_id
    this.project_id = this.$f7route.params.project_id
    this.get_project()
    this.get_complaint_mobile()
  },
  methods: {
    async delete_complaint_project(kbn) {
      this.form.kbn = kbn
      swal({
        title: this.$t('confirm delete'),
        dangerMode: true,
        buttons: [this.$t('cancel'), this.$t('delete')],
      }).then((value) => {
        if (value) {
          this.form.complaint_id = this.complaint_id
          this.form.patch('/api/system/delete_complaint_project_mobile')
          .then(response => {
            this.status = response.data.status
            let messageText = ''
            messageText = this.$t('delete success')
            var self = this
            // Create toast
            var toastSuccess = self.$f7.toast.create({
              text: messageText,
              position: 'center',
              closeTimeout: 2000,
            })
            toastSuccess.open()
            this.$f7router.navigate('/system/')
          })
          .catch(error => {
            if(error.response.status == 422){
              if (error.response.data.errors) {
              } else {
                if (error.response.data.message) {
                  swal({
                    icon: "warning",
                    text: error.response.data.message,
                  });
                }
              }
            } else {
              swal({
                icon: "error",
                text: error,
              });
            }
          });
        }
      })
    },
    get_project() {
      const options = {
        params: {
          id: this.project_id,
        }
      };
      axios.get('/api/admin/project_open/get_project', options)
      .then(response => {
        let item = response.data.project
        this.form.keys().forEach(key => {
          this.form[key] = item[key]
        })
      })
      .catch(function (error) {
        swal({
          icon: "error",
          text: error,
        })
      })
    },
    get_complaint_mobile() {
      const options = {
        params: {
          id: this.complaint_id,
        }
      };
      axios.get('/api/system/get_complaint_mobile', options)
      .then(response => {
        let item = response.data.complaint
        this.complaint_reasons = item.complaint_reasons
      })
      .catch(error => {
        if(error.response.status == 422){
          if (error.response.data.errors) {
          } else {
            if (error.response.data.message) {
              swal({
                icon: "warning",
                text: error.response.data.message,
              })
            }
          }
        } else {
          swal({
            icon: "error",
            text: error,
          });
        }
      })
    },
  }
}
</script>
<style>
</style>
