<template>
  <f7-page>
    <f7-navbar :title="$t('edit company info')" :back-link="$t('back')"></f7-navbar>
    <f7-list inline-labels no-hairlines-md form class="">
        <!-- 名称 -->
        <f7-list-input
            :label="$t('company name')"
            type="text"
            :value="form.name"
            @input="form.name = $event.target.value"
            @change="item_change('name')"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('name') }}</span>
        </f7-list-input>
        <!-- memo -->
        <f7-list-input
            :label="$t('memo')"
            type="textarea"
            :value="form.memo"
            @input="form.memo = $event.target.value"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('memo') }}</span>
        </f7-list-input>
        <!-- 会社印鑑証明書 -->
        <f7-list-item>
            <el-upload ref="file"
                class="upload-demo"
                action=""
                :on-remove="handleRemove"
                :on-change="handleChange"
                :limit="1"
                :on-exceed="handleExceed"
                :file-list="form.fileList"
                :auto-upload="false">
                <el-button slot="trigger" size="small" type="primary">会社印鑑証明書PDF</el-button>
                <div slot="tip" class="el-upload__tip">500kb以下のPDFファイルのみUPLOAD可</div>
            </el-upload>
        </f7-list-item>
        <f7-list-button v-if="platformId=='ios'"
            :title="$t('input detail')"
            v-on:click.stop.prevent="DetailShowControl">
        </f7-list-button>
        <f7-list-item v-else>
            <f7-col></f7-col>
            <f7-col>
              <f7-button large v-on:click.stop.prevent="DetailShowControl">
                {{ $t('input detail') }}
              </f7-button>
            </f7-col>
            <f7-col></f7-col>
        </f7-list-item>
        <!-- 郵便番号 -->
        <f7-list-input v-show="form.detail_show"
            :label="$t('zip code')"
            type="text"
            :value="form.zip"
            @input="form.zip = $event.target.value"
            @change="item_change('zip')"
            @blur="zipSearch()"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('zip') }}</span>
        </f7-list-input>
        <!-- 都道府県 -->
        <f7-list-item v-show="form.detail_show"
            :title="$t('prefecture')" smart-select :smart-select-params="{openIn: 'popup', searchbar: true, searchbarPlaceholder: ''}">
            <select name="pref_id" v-model="form.pref_id" @change="item_change('pref_id')">
              <option v-for="item in pref_list" :value="item.value">
                  {{ item.value }}
              </option>
            </select>
            <span style="color:#ff0000" v-if="form.errors.get('pref_id')" v-text="form.errors.get('pref_id')"></span>
            <div slot="after-title" v-show="form_show.pref_id">
              {{ form.pref_id }}</div>
        </f7-list-item>
        <!-- 住所1 -->
        <f7-list-input v-show="form.detail_show"
            :label="$t('address1')"
            type="text"
            :value="form.address1"
            @input="form.address1 = $event.target.value"
            @change="item_change('address1')"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('address1') }}</span>
        </f7-list-input>
        <!-- 住所2 -->
        <f7-list-input v-show="form.detail_show"
            :label="$t('address2')"
            type="text"
            :value="form.address2"
            @input="form.address2 = $event.target.value"
            @change="item_change('address2')"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('address2') }}</span>
        </f7-list-input>
        <!-- 電話番号 -->
        <f7-list-input v-show="form.detail_show"
            :label="$t('tel no')"
            type="text"
            :value="form.tel_no"
            placeholder="例:03-1234-5678"
            @input="form.tel_no = $event.target.value"
            @change="item_change('tel_no')"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('tel_no') }}</span>
        </f7-list-input>
        <!-- mobile番号 -->
        <f7-list-input v-show="form.detail_show"
            :label="$t('mobile telno')"
            type="text"
            :value="form.mobile_telno"
            placeholder="例:080-1234-5678"
            @input="form.mobile_telno = $event.target.value"
            @change="item_change('mobile_telno')"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('mobile_telno') }}</span>
        </f7-list-input>
        <!-- website -->
        <f7-list-input v-show="form.detail_show"
            :label="$t('homepage')"
            type="text"
            :value="form.website"
            placeholder="例:http://www.newways.co.jp"
            @input="form.website = $event.target.value"
            @change="item_change('website')"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('website') }}</span>
        </f7-list-input>
        <!-- email -->
        <f7-list-input v-show="form.detail_show"
            :label="$t('email')"
            type="text"
            :value="form.email"
            @input="form.email = $event.target.value"
            @change="item_change('email')"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('email') }}</span>
        </f7-list-input>
        <f7-list-button v-if="platformId=='ios'"
            type="submit"
            :loading="form.busy"
            v-on:click.stop.prevent="UpdateCompany">
            {{ $t('confirm') }}
        </f7-list-button>
        <f7-list-item v-else>
            <f7-col></f7-col>
            <f7-col>
              <f7-button large v-on:click.stop.prevent="UpdateCompany">
                {{ $t('confirm') }}
              </f7-button>
            </f7-col>
            <f7-col></f7-col>
        </f7-list-item>
    </f7-list>
  </f7-page>
</template>

<script>
import { Device }  from 'framework7/framework7-lite.esm.bundle.js';
import axios from 'axios'
import swal from "sweetalert"
import Form from 'vform'
import moment from 'moment';

export default {
  name: 'UpdateCompany',
  data() {
    return {
      pref_list: [],
      zip: null,
      form: new Form({
          detail_show: false,
          id: '',
          name: '',
          memo: '',
          fileList: [],
          zip: '',
          pref_id: '',
          address1: '',
          address2: '',
          tel_no: '',
          mobile_telno: '',
          website: '',
          email: '',
          certpdf_ServerFileName: '',
      }),
      form_show: new Form({
          pref_id: true,
      }),
      platformId: 'ios',
    }
  },
  created() {
    if (!Device.cordova) {
      this.platformId = 'ios'  //pcの場合iosとする
    } else {
      this.platformId = cordova.platformId //ios,android,osx
    }
  },
  mounted() {
    this.form.clear()
    this.form.reset()
    this.get_pref_list()
    this.get_company_info()
  },
  methods: {
    DetailShowControl() {
      if (this.form.detail_show) {
        this.form.detail_show = false
      } else {
        this.form.detail_show = true
      }
    },
    item_change(item_name) {
      this.form.errors.clear(item_name)
      // this.$refs[item_name].clearValidate() // 清除验证
      if (item_name == 'pref_id') {
        this.form_show.pref_id = false
      }
    },
    async get_pref_list() {
      this.pref_list = []
      const { data } = await axios.get('/api/admin/common/get_pref_list')
      this.pref_list = data.pref_list
    },
    async zipSearch() {
      await this.form.patch('/api/admin/common/postal_search')
      .then(response => {
          this.form.pref_id = response.data.prefecture;
          this.form.address1 = response.data.city;
          this.form.address2 = response.data.address;
      })
      .catch(error => {
        if(error.response.status == 422){
          if (error.response.data.errors) {
          } else {
            if (error.response.data.message) {
              swal({
                icon: "warning",
                text: error.response.data.message,
              });
            }
          }
        } else {
          swal({
            icon: "error",
            text: error,
          });
        }
      });
    },
    async get_company_info() {
      await axios.get('/api/admin/settings/get_company_info')
      .then(response => {
        let data = response.data.row;
        this.form.keys().forEach(key => {
          this.form[key] = data[key]
        })
        this.form.detail_show = false
      })
      .catch(function (error) {
        if (error.response.status == 422) {
          if (error.response.data.message){
            swal({
              icon: "warning",
              text: error.response.data.message,
            })
          }
        } else {
          swal({
            icon: "error",
            text: error,
          })
        }
      })
    },
    async UpdateCompany () {
      await this.form.patch('/api/admin/settings/update_company_info')
      .then(response => {
        this.status = response.data.status
        let messageText = ''
        messageText = this.$t('update success')
        var self = this
        // Create toast
        var toastSuccess = self.$f7.toast.create({
          text: messageText,
          position: 'center',
          closeTimeout: 2000,
        })
        toastSuccess.open()
        this.$f7router.back()
      })
      .catch(error => {
        if(error.response.status == 422){
          if (error.response.data.errors) {
          } else {
            if (error.response.data.message) {
              swal({
                icon: "warning",
                text: error.response.data.message,
              });
            }
          }
        } else {
          swal({
            icon: "error",
            text: error,
          });
        }
      });
    },
    handleExceed(files, fileList) {
        // this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    handleRemove(file, fileList) {
      this.form.fileList = fileList
      axios.post('/api/admin/settings/certpdf_remove',{
        file:this.form.certpdf_ServerFileName
      }).then(response => {
        if (this.add_update_mode == '1') {
          this.form.certpdf_ServerFileName = '';
        } else {
          if (response.data.result == '1') {
            this.form.certpdf_ServerFileName = '';
          }
        }
      }).catch(error => {
        if (error.response.status == 422) {
          if (error.response.data.message){
            swal({
              icon: "warning",
              text: error.response.data.message,
            });
          }
        } else {
          swal({
            icon: "error",
            text: error,
          })
        }
      })
    },
    handleChange(file, fileList) {
      if (fileList[0].size > 500*1024) {
        fileList = []
        this.form.fileList = fileList
        var self = this;
        var toastSizeExceed = self.$f7.toast.create({
          text: '500kb以下のPDFファイルのみUPLOAD可',
          position: 'center',
          closeTimeout: 2000,
        })
        toastSizeExceed.open();
        return
      } else {
        this.form.fileList = fileList
      }
      // var $$ = this.Dom7;
      // var array = $$('input[name="file"]')
      // // var inputVal = $$('#myInput').val();
      let formData = new FormData()
      formData.append('file', fileList[0].raw)
      axios.post('/api/admin/settings/certpdf_upload', formData)
      .then(response => {
        this.form.certpdf_ServerFileName = response.data.path
      })
      .catch(error => {
        swal({
          icon: "error",
          text: error,
        })
      })
    },
  }
}
</script>
