<template>
  <f7-page>
    <f7-navbar :title="myTitle" :back-link="$t('back')"></f7-navbar>
    <f7-list inline-labels no-hairlines-md form class="">
        <!-- apk version no -->
        <f7-list-input
            :label="$t('version no')"
            type="text"
            :value="form.version_no"
            @input="form.version_no = $event.target.value"
            @change="item_change('version_no')"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('version_no') }}</span>
        </f7-list-input>
        <!-- content -->
        <f7-list-input
            :label="$t('content')"
            type="textarea"
            resizable
            :value="form.content"
            @input="form.content = $event.target.value"
            @change="item_change('content')"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('content') }}</span>
        </f7-list-input>
        <!-- APK FILE -->
        <f7-list-item>
            <el-upload ref="file"
                class="upload-demo"
                action=""
                :on-remove="handleRemove"
                :on-change="handleChange"
                :limit="1"
                :on-exceed="handleExceed"
                :file-list="form.fileList"
                :auto-upload="false">
                <el-button slot="trigger" size="small" type="primary">APKファイル</el-button>
                <div slot="tip" class="el-upload__tip">APKファイルのみUPLOAD可</div>
            </el-upload>
        </f7-list-item>
        <f7-list-button v-if="platformId=='ios'"
            type="submit"
            :loading="form.busy"
            v-on:click.stop.prevent="AddOrUpdateApkVersion">
            {{ $t('confirm') }}
        </f7-list-button>
        <f7-list-item v-else>
            <f7-col></f7-col>
            <f7-col>
              <f7-button large v-on:click.stop.prevent="AddOrUpdateApkVersion">
                {{ $t('confirm') }}
              </f7-button>
            </f7-col>
            <f7-col></f7-col>
        </f7-list-item>
    </f7-list>
  </f7-page>
</template>

<script>
import { Device }  from 'framework7/framework7-lite.esm.bundle.js';
import axios from 'axios'
import swal from "sweetalert"
import Form from 'vform'

export default {
  name: 'AddUpdateApkVersion',
  data() {
    return {
      myTitle: '',
      add_update_mode: '1',
      apk_version_id: null,
      form: new Form({
          id: '',
          version_no: '',
          content: '',
          apk_ServerFileName: '',
          fileList: [],
      }),
      platformId: 'ios',
    }
  },
  created() {
    let options = JSON.parse(this.$f7route.params.options)
    this.add_update_mode = options.add_update_mode
    if (this.add_update_mode == '2') {
      this.apk_version_id = options.apk_version_id
    }
    if (!Device.cordova) {
      this.platformId = 'ios'  //pcの場合iosとする
    } else {
      this.platformId = cordova.platformId //ios,android,osx
    }
  },
  mounted() {
    if (this.add_update_mode == '1') {
      this.myTitle = this.$t('add')
      this.resetForm()
    } else if (this.add_update_mode == '2') {
      this.myTitle = this.$t('update')
      this.get_apk_version_detail()
    }
  },
  methods: {
    item_change(item_name) {
      this.form.errors.clear(item_name)
    },
    async AddOrUpdateApkVersion() {
      let url = ''
      if (this.add_update_mode == '1') {
        url = '/api/system/add_apk_version'
      } else {
        url = '/api/system/update_apk_version'
      }
      await this.form.patch(url)
      .then(response => {
        this.status = response.data.status
        let messageText = ''
        if (this.add_update_mode == '1') {
          messageText = this.$t('add success')
        } else {
          messageText = this.$t('update success')
        }
        var self = this
        // Create toast
        var toastSuccess = self.$f7.toast.create({
          text: messageText,
          position: 'center',
          closeTimeout: 2000,
        })
        toastSuccess.open()
        this.$f7router.navigate('/system/')
      })
      .catch(error => {
        if (error.response.status == 422) {
          if (error.response.data.errors) {
          } else {
            if (error.response.data.message) {
              swal({
                icon: "warning",
                text: error.response.data.message,
              });
            }
          }
        } else {
          swal({
            icon: "error",
            text: error,
          });
        }
      });
    },
    get_apk_version_detail() {
      const options = {
        params: {
          id: this.apk_version_id,
        }
      }
      axios.get('/api/get_apk_version_detail', options)
      .then(response => {
        let item = response.data.apk_version
        this.form.keys().forEach(key => {
          this.form[key] = item[key]
        })
      })
      .catch(function (error) {
        swal({
          icon: "error",
          text: error,
        })
      })
    },
    resetForm() {
        this.form.clear()
        this.form.reset()
    },
    handleExceed(files, fileList) {
        // this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    handleRemove(file, fileList) {
      this.form.fileList = fileList
      axios.post('/api/system/apk_remove',{
        file:this.form.apk_ServerFileName
      }).then(response => {
        if (this.add_update_mode == '1') {
          this.form.apk_ServerFileName = '';
        } else {
          if (response.data.result == '1') {
            this.form.apk_ServerFileName = '';
          }
        }
      }).catch(error => {
        if (error.response.status == 422) {
          if (error.response.data.message){
            swal({
              icon: "warning",
              text: error.response.data.message,
            });
          }
        } else {
          swal({
            icon: "error",
            text: error,
          })
        }
      })
    },
    handleChange(file, fileList) {
      this.form.fileList = fileList
      let formData = new FormData()
      formData.append('file', fileList[0].raw)
      axios.post('/api/system/apk_upload', formData)
      .then(response => {
        this.form.apk_ServerFileName = response.data.path
      })
      .catch(error => {
        swal({
          icon: "error",
          text: error,
        })
      })
    },
  }
}
</script>
<style>
</style>
