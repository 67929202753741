<template>
  <f7-page>
    <f7-navbar>
        <f7-nav-left :back-link="$t('back')"></f7-nav-left>
        <f7-nav-title>{{ $t('query condition setting') }}</f7-nav-title>
        <f7-nav-right>
            <f7-link icon="icon-bar" @click="clearForm()">{{ $t('reset') }}</f7-link>
        </f7-nav-right>
    </f7-navbar>
    <f7-list inline-labels no-hairlines-md form class="">
        <!-- 管理No -->
        <f7-list-input
            :label="$t('management No')"
            type="text"
            :value="form.id"
            @input="form.id = $event.target.value"
            @change="item_change('id')"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('id') }}</span>
        </f7-list-input>
        <!-- スキル -->
        <f7-list-item :title="$t('skill')" smart-select :smart-select-params="{openIn: 'popup', searchbar: true, searchbarPlaceholder: ''}">
            <select name="skill_list" multiple v-model="form.skill_list" @change="item_change('skill_list')">
              <optgroup v-for="items in skill_list" :label="items.label">
                <option v-for="item in items.children" :value="item.value">{{ item.label }}</option>
              </optgroup>
            </select>
            <span style="color:#ff0000" v-if="form.errors.has('skill_list')" v-text="form.errors.get('skill_list')"></span>
            <div slot="after-title" v-show="form_show.skill_list">
              {{ form.skill_list.join(',').length>30? form.skill_list.join(',').substr(0,30)+'...' : form.skill_list.join(',')}}</div>
        </f7-list-item>
        <!-- 語学能力 -->
        <f7-list-item :title="$t('language skills')" smart-select :smart-select-params="{openIn: 'popup', searchbar: true, searchbarPlaceholder: ''}">
            <select name="foreign_language_list" multiple v-model="form.foreign_language_list" @change="item_change('foreign_language_list')">
              <optgroup v-for="items in foreign_language_list" :label="items.label">
                <option v-for="item in items.children" :value="item.value">{{ item.label }}</option>
              </optgroup>
            </select>
            <span style="color:#ff0000" v-if="form.errors.get('foreign_language_list')" v-text="form.errors.get('foreign_language_list')"></span>
            <div slot="after-title" v-show="form_show.foreign_language_list">
              {{ form.foreign_language_list.join(',').length>30? form.foreign_language_list.join(',').substr(0,30)+'...' : form.foreign_language_list.join(',')}}</div>
        </f7-list-item>
        <!-- 参画工程 -->
        <f7-list-item :title="$t('process')" smart-select>
            <select name="process_list" multiple v-model="form.process_list" @change="item_change('process_list')">
              <option v-for="item in process_list" :value="item.value">
                  {{ item.value }}
              </option>
            </select>
            <span style="color:#ff0000" v-if="form.errors.get('process_list')" v-text="form.errors.get('process_list')"></span>
            <div slot="after-title" v-show="form_show.process_list">
              {{ form.process_list.join(',').length>18? form.process_list.join(',').substr(0,18)+'...' : form.process_list.join(',')}}</div>
        </f7-list-item>
        <!-- 管理経験 -->
        <f7-list-item :title="$t('manage experience')" smart-select>
            <select name="position_list" multiple v-model="form.position_list" @change="item_change('position_list')">
              <option v-for="item in position_list" :value="item.value">
                  {{ item.value }}
              </option>
            </select>
            <span style="color:#ff0000" v-if="form.errors.get('position_list')" v-text="form.errors.get('position_list')"></span>
            <div slot="after-title" v-show="form_show.position_list">
              {{ form.position_list.join(',').length>30? form.position_list.join(',').substr(0,30)+'...' : form.position_list.join(',')}}</div>
        </f7-list-item>
        <!-- 入場日 -->
        <!-- <f7-list-input
            :label="$t('admission day')"
            type="datepicker"
            readonly
            :placeholder="$t('please select')"
            :value="available_day_array"
            @calendar:change="available_day_calendar_change"
            :calendar-params="{openIn: 'customModal', header: true, footer: true, dateFormat: 'yyyy/mm/dd'}"
            @change="item_change('available_day')"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('available_day') }}</span>
        </f7-list-input> -->
        <!-- 希望単価 -->
        <f7-list-input
            :label="$t('desired price')"
            type="text"
            :value="form.desired_price"
            pattern="[0-9]*"
            @input="form.desired_price = $event.target.value"
            @change="item_change('desired_price')"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('desired_price') }}</span>
        </f7-list-input>
        <!-- 所属会社 -->
        <f7-list-item :title="$t('company belongs')" smart-select :smart-select-params="{openIn: 'popup', searchbar: true, searchbarPlaceholder: '会社名検索'}">
            <select name="company_id" multiple v-model="form.company_id" @change="item_change('company_id')">
              <option v-for="item in company_list" :value="item.id">
                  {{ item.name }}
              </option>
            </select>
            <span style="color:#ff0000" v-if="form.errors.get('company_id')" v-text="form.errors.get('company_id')"></span>
            <div slot="after-title" v-show="form_show.company_id">
              {{ form.company_name.join(',').length>30? form.company_name.join(',').substr(0,30)+'...' : form.company_name.join(',')}}</div>
        </f7-list-item>
        <f7-list-button v-if="platformId=='ios'"
            type="submit"
            :loading="form.busy"
            v-on:click.stop.prevent="query_conditions_save_open()">
            {{ $t('save') }}
        </f7-list-button>
        <f7-list-item v-else>
            <f7-col></f7-col>
            <f7-col>
              <f7-button large v-on:click.stop.prevent="query_conditions_save_open()">
                {{ $t('save') }}
              </f7-button>
            </f7-col>
            <f7-col></f7-col>
        </f7-list-item>
    </f7-list>
  </f7-page>
</template>

<script>
import { Device }  from 'framework7/framework7-lite.esm.bundle.js';
import axios from 'axios'
import swal from "sweetalert"
import Form from 'vform'
import moment from 'moment';

export default {
  name: 'ProjectQuery',
  data() {
      return {
        foreign_language_list: [],
        skill_list: [],
        process_list: [],
        position_list: [],
        business_list: [],
        company_list: [],
        available_day_array: [],
        form: new Form ({
            id: '',
            foreign_language_list: [],
            skill_list: [],
            process_list: [],
            position_list: [],
            // available_day: '',
            desired_price: '',
            company_id: [],
            company_name: [],
        }),
        form_show: new Form ({
            id: true,
            foreign_language_list: true,
            skill_list: true,
            process_list: true,
            position_list: true,
            // available_day: true,
            desired_price: true,
            company_id: true,
        }),
        platformId: 'ios',
      }
  },
  created() {
    if (!Device.cordova) {
      this.platformId = 'ios'  //pcの場合iosとする
    } else {
      this.platformId = cordova.platformId //ios,android,osx
    }
  },
  mounted() {
    this.form.clear()
    this.form.reset()
    this.form_show.keys().forEach(key => {
      this.form_show[key] = false
    })
    this.get_skill_list()
    this.get_company_list()
    this.get_project_query_conditions_open()
  },
  methods: {
    clearForm() {
      this.form.clear()
      this.form.reset()
      this.form_show.keys().forEach(key => {
        this.form_show[key] = false
      })
      // this.available_day_array = []
      var $$ = this.Dom7
      // Set the value of every matched element
      $$('.item-after').empty()
    },
    // available_day_calendar_change(val) {
    //   console.log('available_day_calendar_change')
    //   console.log(val)
    //   if (val.length==0) {
    //     this.available_day_array = []
    //     this.form.available_day = ''
    //   } else {
    //     this.available_day_array = val
    //     this.form.available_day = moment(val[0]).format("YYYY/MM/DD")
    //   }
    // },
    item_change(item_name) {
      this.form.errors.clear(item_name)
      this.form_show[item_name] = false
    },
    async query_conditions_save_open() {
      let url = '/api/admin/project_open/query_conditions_save_open'
      await this.form.patch(url)
      .then(response => {
        var self = this
        var toastSuccess = self.$f7.toast.create({
          text: this.$t('condition setting success'),
          position: 'center',
          closeTimeout: 2000,
        })
        toastSuccess.open()
        // this.$f7router.back()
        this.$f7router.navigate('/projects_open/')
      })
      .catch(error => {
        if(error.response.status == 422){
          if (error.response.data.errors) {
          } else {
            if (error.response.data.message) {
              swal({
                icon: "warning",
                text: error.response.data.message,
              });
            }
          }
        } else {
          swal({
            icon: "error",
            text: error,
          });
        }
      })
    },
    async get_skill_list() {
      this.foreign_language_list = []
      this.skill_list = []
      this.process_list = []
      this.position_list = []
      this.business_list = []
      const { data } = await axios.get('/api/engineer/get_skill_list')
      this.foreign_language_list = data.foreign_language_list
      this.skill_list = data.skill_list
      this.process_list = data.process_list
      this.position_list = data.position_list
      this.business_list = data.business_list
    },
    async get_company_list() {
      this.company_list = []
      const { data } = await axios.get('/api/engineer/get_company_list')
      this.company_list = data.company_list
    },
    async get_project_query_conditions_open() {
      await axios.get('/api/admin/project_open/get_project_query_conditions_open')
      .then(response => {
        let item = response.data.project_query_conditions_open
        if (Object.keys(item).length > 0) {
          this.form.keys().forEach(key => {
            this.form[key] = item[key]
          })
          this.form_show.keys().forEach(key => {
            this.form_show[key] = true
          })
        } else {
          this.form_show.keys().forEach(key => {
            this.form_show[key] = false
          })
        }
        // if (this.form.available_day) {
        //   this.available_day_array = [moment(this.form.available_day)]
        // } else {
        //   this.available_day_array = []
        // }
      })
      .catch(function (error) {
        swal({
          icon: "error",
          text: error,
        })
      })
    },
  }
}
</script>
<style>
</style>
