<template>
  <f7-page>
    <f7-navbar :title="$t('newest version')" :back-link="$t('back')"></f7-navbar>
    <f7-list inline-labels no-hairlines-md form class="">
        <!-- 直前version -->
        <f7-list-input
            :label="$t('previous version')"
            type="text"
            :value="app_version_info"
            readonly>
        </f7-list-input>
        <!-- 最新version -->
        <f7-list-input
            :label="$t('newest version')"
            type="text"
            :value="form.version"
            @input="form.version = $event.target.value"
            @change="item_change('version')"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('version') }}</span>
        </f7-list-input>
        <f7-list-button v-if="platformId=='ios'"
            type="submit"
            :loading="form.busy"
            v-on:click.stop.prevent="Update">
            {{ $t('confirm') }}
        </f7-list-button>
        <f7-list-item v-else>
            <f7-col></f7-col>
            <f7-col>
              <f7-button large v-on:click.stop.prevent="Update">
                {{ $t('confirm') }}
              </f7-button>
            </f7-col>
            <f7-col></f7-col>
        </f7-list-item>
    </f7-list>
  </f7-page>
</template>

<script>
import { Device }  from 'framework7/framework7-lite.esm.bundle.js'
import axios from 'axios'
import swal from "sweetalert"
import Form from 'vform'

export default {
  name: 'VersionManage',
  data() {
    return {
      app_version_info: '',
      form: new Form({
          version: '',
      }),
      platformId: 'ios',
    }
  },
  created() {
    if (!Device.cordova) {
      this.platformId = 'ios'  //pcの場合iosとする
    } else {
      this.platformId = cordova.platformId //ios,android,osx
    }
  },
  mounted() {
    this.form.clear()
    this.form.reset()
    this.get_app_version_info()
  },
  methods: {
    async get_app_version_info() {
      await axios.get('/api/admin/setting/get_app_version_info')
      .then(response => {
        this.app_version_info = response.data.version.version
      })
      .catch(function (error) {
        if (error.response.status == 422) {
          if (error.response.data.message){
            swal({
              icon: "warning",
              text: error.response.data.message,
            })
          }
        } else {
          swal({
            icon: "error",
            text: error,
          })
        }
      })
    },
    item_change(item_name) {
      this.form.errors.clear(item_name)
    },
    async Update () {
      await this.form.patch('/api/system/update_app_version_info')
      .then(response => {
        this.status = response.data.status
        let messageText = ''
        messageText = this.$t('update success')
        var self = this
        // Create toast
        var toastSuccess = self.$f7.toast.create({
          text: messageText,
          position: 'center',
          closeTimeout: 2000,
        })
        toastSuccess.open()
        this.$f7router.back()
      })
      .catch(error => {
        if(error.response.status == 422){
          if (error.response.data.errors) {
          } else {
            if (error.response.data.message) {
              swal({
                icon: "warning",
                text: error.response.data.message,
              });
            }
          }
        } else {
          swal({
            icon: "error",
            text: error,
          });
        }
      });
    },
  }
}
</script>
