<template>
  <f7-page>
    <f7-navbar :title="$t('your password')" :back-link="$t('back')"></f7-navbar>
    <f7-list no-hairlines-md form class="">
        <!-- Password -->
        <f7-list-input
            :label="$t('new password')"
            type="password"
            :value="form.password"
            @input="form.password = $event.target.value"
            @change="item_change('password')"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('password') }}</span>
        </f7-list-input>
        <!-- Password Confirmation -->
        <f7-list-input
            :label="$t('confirm password')"
            type="password"
            :value="form.password_confirmation"
            @input="form.password_confirmation = $event.target.value"
            @change="item_change('password_confirmation')"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('password_confirmation') }}</span>
        </f7-list-input>
        <f7-list-button v-if="platformId=='ios'"
            type="submit"
            :loading="form.busy"
            v-on:click.stop.prevent="update">
            {{ $t('confirm') }}
        </f7-list-button>
        <f7-list-item v-else>
            <f7-col></f7-col>
            <f7-col>
              <f7-button large v-on:click.stop.prevent="update">
                {{ $t('confirm') }}
              </f7-button>
            </f7-col>
            <f7-col></f7-col>
        </f7-list-item>
    </f7-list>
  </f7-page>
</template>

<script>
import { Device }  from 'framework7/framework7-lite.esm.bundle.js'
import axios from 'axios'
import swal from "sweetalert"
import Form from 'vform'

export default {
  name: 'password',
  data() {
    return {
      form: new Form({
          password: '',
          password_confirmation: '',
      }),
      platformId: 'ios',
    }
  },
  mounted() {
    if (!Device.cordova) {
      this.platformId = 'ios'  //pcの場合iosとする
    } else {
      this.platformId = cordova.platformId //ios,android,osx
    }
  },
  methods: {
    item_change(item_name) {
      this.form.errors.clear(item_name)
    },
    async update () {
      await this.form.patch('/api/admin/settings/password')
      .then(response => {
        let messageText = ''
        messageText = this.$t('password updated')
        var self = this
        // Create toast
        var toastSuccess = self.$f7.toast.create({
          text: messageText,
          position: 'center',
          closeTimeout: 2000,
        })
        toastSuccess.open()
        this.$f7router.back()
      })
      .catch(error => {
        if(error.response.status == 422){
        } else {
          swal({
            icon: "error",
            text: error,
          });
        }
      });
    },
  }
}
</script>
<style>
</style>
