<template>
  <f7-page>
    <f7-navbar :title="myTitle" :back-link="$t('back')"></f7-navbar>
    <f7-list inline-labels no-hairlines-md form class="">
        <!-- account_id -->
        <f7-list-input
            :label="$t('account id')"
            type="text"
            :value="form.account_id"
            @input="form.account_id = $event.target.value"
            @change="item_change('account_id')"
            readonly>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('account_id') }}</span>
        </f7-list-input>
        <!-- account_name -->
        <f7-list-input
            :label="$t('worker name')"
            type="text"
            :value="form.account_name"
            @input="form.account_name = $event.target.value"
            @change="item_change('account_name')"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('account_name') }}</span>
        </f7-list-input>
        <!-- password -->
        <f7-list-input
            :label="$t('password')"
            type="password"
            :value="form.password"
            @input="form.password = $event.target.value"
            @change="item_change('password')"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('password') }}</span>
        </f7-list-input>
        <!-- Password Confirmation -->
        <f7-list-input
            :label="$t('confirm password')"
            type="password"
            :value="form.password_confirmation"
            @input="form.password_confirmation = $event.target.value"
            @change="item_change('password_confirmation')"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('password_confirmation') }}</span>
        </f7-list-input>
        <!-- belongs_role -->
        <f7-list-item
            :title="$t('belongs role')" smart-select :smart-select-params="{openIn: 'popover'}">
            <select name="role_id" v-model="form.role_id" @change="role_change()">
              <option v-for="item in role_list" :value="item.id">
                  {{ item.name }}
              </option>
            </select>
            <span style="color:#ff0000" v-if="form.errors.get('role_id')" v-text="form.errors.get('role_id')"></span>
            <div slot="after-title" v-show="form_show.role_id">
              {{ form.role_name }}</div>
        </f7-list-item>
        <f7-list-button v-if="platformId=='ios'"
            type="submit"
            :loading="form.busy"
            v-on:click.stop.prevent="AddOrUpdateAccount">
            {{ $t('confirm') }}
        </f7-list-button>
        <f7-list-item v-else>
            <f7-col></f7-col>
            <f7-col>
              <f7-button large v-on:click.stop.prevent="AddOrUpdateAccount">
                {{ $t('confirm') }}
              </f7-button>
            </f7-col>
            <f7-col></f7-col>
        </f7-list-item>
    </f7-list>
  </f7-page>
</template>

<script>
import { Device }  from 'framework7/framework7-lite.esm.bundle.js';
import axios from 'axios'
import swal from "sweetalert"
import Form from 'vform'

export default {
  name: 'AddUpdateAccount',
  data() {
      return {
        add_update_mode: '1',
        account_id: null,
        myTitle: '',
        role_list: [],
        form: new Form({
            id: '',
            account_id: '',
            account_name: '',
            password: '',
            password_confirmation: '',
            role_id: '',
            role_name: '',
        }),
        form_show: new Form({
            role_id: false,
        }),
        platformId: 'ios',
      }
  },
  created() {
    let options = JSON.parse(this.$f7route.params.options)
    this.add_update_mode = options.add_update_mode
    if (this.add_update_mode == '2') {
      this.account_id = options.account_id
      this.form_show.keys().forEach(key => {
        this.form_show[key] = true
      })
    }
    if (!Device.cordova) {
      this.platformId = 'ios'  //pcの場合iosとする
    } else {
      this.platformId = cordova.platformId //ios,android,osx
    }
  },
  mounted() {
    if (this.add_update_mode == '1') {
      this.myTitle = this.$t('account add')
      this.get_role_list()
      this.resetForm()
      this.form.account_id = this.create_account_id(1000000, 9999999)
    } else if (this.add_update_mode == '2') {
      this.myTitle = this.$t('account update')
      this.get_role_list()
      this.get_account()
    }
  },
  methods: {
    create_account_id(Min, Max) {
      var Range = Max - Min;
      var Rand = Math.random();
      var num = Min + Math.round(Rand * Range);//四捨五入
      return num;
    },
    role_change() {
      if (this.form.role_id == 10) {
        this.form.role_name = this.$t('sales director')
      } else if (this.form.role_id == 15) {
        this.form.role_name = this.$t('sales staff')
      }
      if (this.add_update_mode == '2') {
        this.form_show.role_id = false
      }
      this.form.errors.clear('role_id')
    },
    item_change(item_name) {
      this.form.errors.clear(item_name)
    },
    async AddOrUpdateAccount() {
      let url = ''
      if (this.add_update_mode == '1') {
        url = '/api/admin/add_account'
      } else {
        url = '/api/admin/update_account'
      }
      await this.form.patch(url)
      .then(response => {
        this.status = response.data.status
        //clear the form errors
        this.form.clear()
        this.form.reset()
        let messageText = ''
        if (this.add_update_mode == '1') {
          messageText = this.$t('add success')
        } else {
          messageText = this.$t('update success')
        }
        var self = this
        // Create toast
        var toastSuccess = self.$f7.toast.create({
          text: messageText,
          position: 'center',
          closeTimeout: 2000,
        })
        toastSuccess.open()
        //this.$f7router.back()
        //Redirect.
        this.$f7router.navigate('/company_setting/')
      })
      .catch(error => {
        if (error.response.status == 422) {
          if (error.response.data.errors) {
          } else {
            if (error.response.data.message) {
              swal({
                icon: "warning",
                text: error.response.data.message,
              });
            }
          }
        } else {
          swal({
            icon: "error",
            text: error,
          });
        }
      });
    },
    async get_role_list() {
      this.role_list = [];
      const { data } = await axios.get('/api/admin/get_role_list')
      this.role_list = data.role_list;
    },
    get_account() {
      const options = {
        params: {
          id: this.account_id,
        }
      }
      axios.get('/api/admin/get_account', options)
      .then(response => {
        let item = response.data.account
        this.form.keys().forEach(key => {
          this.form[key] = item[key]
        })
        this.form.password = ''
        this.form.password_confirmation = ''
      })
      .catch(function (error) {
        swal({
          icon: "error",
          text: error,
        })
      })
    },
    resetForm() {
        this.form.clear()
        this.form.reset()
    },
  }
}
</script>
<style>
</style>
