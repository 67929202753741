
import Index from '~/js/pages/index.vue';
import MobileTabHome from '~/js/pages/mobile_tab_home.vue';

import HomePage from '~/pages/home.vue';
import AboutPage from '~/pages/about.vue';
import FormPage from '~/pages/form.vue';
import CatalogPage from '~/pages/catalog.vue';
import ProductPage from '~/pages/product.vue';
import SettingsPage from '~/pages/settings.vue';
import DynamicRoutePage from '~/pages/dynamic-route.vue';
import RequestAndLoad from '~/pages/request-and-load.vue';
import NotFoundPage from '~/pages/404.vue';

import AdminLogin from '~/js/pages/auth/AdminLogin.vue';
import PasswordEmail from '~/js/pages/auth/password/email.vue';
import Register from '~/js/pages/auth/register/register.vue';
import Registered from '~/js/pages/auth/register/registered.vue';

import Manage from '~/js/pages/manage/index.vue';
import AddUpdateEngineer from '~/js/pages/manage/AddUpdateEngineer.vue';
import UploadEngineerResume from '~/js/pages/manage/UploadEngineerResume.vue';
import DetailEngineer from '~/js/pages/manage/DetailEngineer.vue';
import AddUpdateProject from '~/js/pages/manage/AddUpdateProject.vue';
import DetailProject from '~/js/pages/manage/DetailProject.vue';
import AddItem from '~/js/pages/manage/add_item.vue';

import EngineersOpen from '~/js/pages/engineers_open/index.vue';
import DetailEngineerOpen from '~/js/pages/engineers_open/DetailEngineer.vue';
import EngineerQueryConditionOpen from '~/js/pages/engineers_open/engineer_query_condition.vue';
import ProjectsOpen from '~/js/pages/projects_open/index.vue';
import DetailProjectOpen from '~/js/pages/projects_open/DetailProject.vue';
import ProjectQueryConditionOpen from '~/js/pages/projects_open/project_query_condition.vue';
import ComplaintProjectOpen from '~/js/pages/projects_open/ComplaintProject.vue';

import CompanySetting from '~/js/pages/settings/index.vue';
import UpdateCompanyInfo from '~/js/pages/settings/UpdateCompany.vue';
import SettingsProfile from '~/js/pages/settings/profile.vue';
import SettingsPassword from '~/js/pages/settings/password.vue';
import AccountInfo from '~/js/pages/settings/account_info.vue';
import AddUpdateAccount from '~/js/pages/settings/AddUpdateAccount.vue';
import Contact from '~/js/pages/settings/Contact.vue';
import DetailNotice from '~/js/pages/settings/DetailNotice.vue';

import System from '~/js/pages/system/index.vue';
import CompanyRuleViolation from '~/js/pages/system/CompanyRuleViolation.vue';
import CompanyCertPdf from '~/js/pages/system/CompanyCertPdf.vue';
import DetailCompanyAccount from '~/js/pages/system/DetailCompanyAccount.vue';
import ContactUpdate from '~/js/pages/system/ContactUpdate.vue';
import VersionManage from '~/js/pages/system/VersionManage.vue';
import DetailComplaintProject from '~/js/pages/system/DetailComplaintProject.vue';
import ModifySkillDevLang from '~/js/pages/system/ModifySkillDevLang.vue';
import ModifySkillTool from '~/js/pages/system/ModifySkillTool.vue';
import ModifySkillDb from '~/js/pages/system/ModifySkillDb.vue';
import ModifySkillOs from '~/js/pages/system/ModifySkillOs.vue';
import ModifySkillOther from '~/js/pages/system/ModifySkillOther.vue';
import ModifyBusiness from '~/js/pages/system/ModifyBusiness.vue';
import DetailNoticeSystem from '~/js/pages/system/DetailNotice.vue';
import AddUpdateNotice from '~/js/pages/system/AddUpdateNotice.vue';
import AddUpdateApkVersion from '~/js/pages/system/AddUpdateApkVersion.vue';

import ServiceTerms from '~/js/pages/manual/ServiceTerms.vue';
import PrivacyPolicy from '~/js/pages/manual/PrivacyPolicy.vue';

import PanelLeftPage from '~/js/pages/panel/panel-left.vue';

import store from '~/js/store'

// const routeComponentMap = {
//   engineers: Manage,
// }
// const checkAuth = (to, from, resolve, reject) => {
//    if (!store.getters['auth/check']) {
//      resolve({component: AdminLogin})
//    } else {
//      resolve({component: routeComponentMap[to.name]})
//      // resolve(to)
//    }
// }
export default [
  { path: '/index/', component: Index, name: 'index'},
  { path: '/mobile_tab_home/', component: MobileTabHome, name: 'mobile_tab_home',
        options: {
          history: true,
          pushState: false,
          transition: 'f7-cover',
        }
  },

  { path: '/serviceterms/', component: ServiceTerms, name: 'serviceterms',
        options: {
          history: true,
          pushState: false,
          transition: 'f7-cover',
        }
  },
  { path: '/privacypolicy/', component: PrivacyPolicy, name: 'privacypolicy',
        options: {
          history: true,
          pushState: false,
          transition: 'f7-cover',
        }
  },

  { path: '/add_account/:options', component: AddUpdateAccount, name: 'add_account',
        options: {
          history: true,
          pushState: false,
          transition: 'f7-cover',
        }
  },
  { path: '/update_account/:options', component: AddUpdateAccount, name: 'update_account',
        options: {
          history: true,
          pushState: false,
          transition: 'f7-cover',
        }
  },
  { path: '/contact/', component: Contact, name: 'contact',
        options: {
          history: true,
          pushState: false,
          transition: 'f7-cover',
        }
  },
  { path: '/detail_notice/:options', component: DetailNotice, name: 'detail_notice',
        options: {
          history: true,
          pushState: false,
          transition: 'f7-cover',
        }
  },

  { path: '/account_info/', component: AccountInfo, name: 'account_info',
        options: {
          history: true,
          pushState: false,
          transition: 'f7-cover',
        }
  },
  { path: '/company_setting/', component: CompanySetting, name: 'company_setting',
        options: {
          history: true,
          pushState: false,
          transition: 'f7-cover',
        }
  },
  { path: '/update_company_info/', component: UpdateCompanyInfo, name: 'update_company_info',
        options: {
          history: true,
          pushState: false,
          transition: 'f7-cover',
        }
  },
  { path: '/admin_email_change/', component: SettingsProfile, name: 'admin_email_change',
        options: {
          history: true,
          pushState: false,
          transition: 'f7-cover',
        }
  },
  { path: '/admin_password_change/', component: SettingsPassword, name: 'admin_password_change',
        options: {
          history: true,
          pushState: false,
          transition: 'f7-cover',
        }
  },

  { path: '/system/', component: System, name: 'system',
        options: {
          history: true,
          pushState: false,
          transition: 'f7-cover',
        }
  },
  { path: '/company_rule_violation/:options', component: CompanyRuleViolation, name: 'company_rule_violation',
        options: {
          history: true,
          pushState: false,
          transition: 'f7-cover',
        }
  },
  { path: '/company_cert_pdf/:options', component: CompanyCertPdf, name: 'company_cert_pdf',
        options: {
          history: true,
          pushState: false,
          transition: 'f7-cover',
        }
  },
  { path: '/detail_company_account/:id/', component: DetailCompanyAccount, name: 'detail_company_account',
        options: {
          history: true,
          pushState: false,
          transition: 'f7-cover',
        }
  },
  { path: '/contact_update/', component: ContactUpdate, name: 'contact_update',
        options: {
          history: true,
          pushState: false,
          transition: 'f7-cover',
        }
  },
  { path: '/version_manage/', component: VersionManage, name: 'version_manage',
        options: {
          history: true,
          pushState: false,
          transition: 'f7-cover',
        }
  },
  { path: '/detail_complaint_project/:complaint_id/:project_id', component: DetailComplaintProject, name: 'detail_complaint_project',
        options: {
          history: true,
          pushState: false,
          transition: 'f7-cover',
        }
  },
  { path: '/modify_skill_dev_lang/', component: ModifySkillDevLang, name: 'modify_skill_dev_lang',
        options: {
          history: true,
          pushState: false,
          transition: 'f7-cover',
        }
  },
  { path: '/modify_skill_tool/', component: ModifySkillTool, name: 'modify_skill_tool',
        options: {
          history: true,
          pushState: false,
          transition: 'f7-cover',
        }
  },
  { path: '/modify_skill_db/', component: ModifySkillDb, name: 'modify_skill_db',
        options: {
          history: true,
          pushState: false,
          transition: 'f7-cover',
        }
  },
  { path: '/modify_skill_os/', component: ModifySkillOs, name: 'modify_skill_os',
        options: {
          history: true,
          pushState: false,
          transition: 'f7-cover',
        }
  },
  { path: '/modify_skill_other/', component: ModifySkillOther, name: 'modify_skill_other',
        options: {
          history: true,
          pushState: false,
          transition: 'f7-cover',
        }
  },
  { path: '/modify_business/', component: ModifyBusiness, name: 'modify_business',
        options: {
          history: true,
          pushState: false,
          transition: 'f7-cover',
        }
  },
  { path: '/detail_notice_system/:id/', component: DetailNoticeSystem, name: 'detail_notice_system',
        options: {
          history: true,
          pushState: false,
          transition: 'f7-cover',
        }
  },
  { path: '/add_notice/:options', component: AddUpdateNotice, name: 'add_notice',
        options: {
          history: true,
          pushState: false,
          transition: 'f7-cover',
        }
  },
  { path: '/update_notice/:options', component: AddUpdateNotice, name: 'update_notice',
        options: {
          history: true,
          pushState: false,
          transition: 'f7-cover',
        }
  },
  { path: '/add_apk_version/:options', component: AddUpdateApkVersion, name: 'add_apk_version',
        options: {
          history: true,
          pushState: false,
          transition: 'f7-cover',
        }
  },
  { path: '/update_apk_version/:options', component: AddUpdateApkVersion, name: 'update_apk_version',
        options: {
          history: true,
          pushState: false,
          transition: 'f7-cover',
        }
  },

  { path: '/admin/login/', component: AdminLogin, name: 'admin_login'},
  { path: '/admin/register/', component: Register, name: 'admin_register',
        options: {
          history: true,
          pushState: false,
          transition: 'f7-cover',
        }
  },
  { path: '/admin/registered/', component: Registered, name: 'admin_registered',
        options: {
          history: true,
          pushState: false,
          transition: 'f7-cover',
        }
  },

  { path: '/admin/password/request/', component: PasswordEmail, name: 'admin_password_request',
        options: {
          history: true,
          pushState: false,
          transition: 'f7-cover',
        }
  },

  { path: '/add_item/', component: AddItem, name: 'add_item',
        options: {
          history: true,
          pushState: false,
          transition: 'f7-cover',
        }
  },

  { path: '/projects_open/', component: ProjectsOpen, name: 'projects_open',
        options: {
          history: true,
          pushState: false,
          transition: 'f7-cover',
        }
  },
  { path: '/detail_project_open/:id/', component: DetailProjectOpen, name: 'detail_project_open',
        options: {
          history: true,
          pushState: false,
          transition: 'f7-cover',
        }
  },
  { path: '/project_query_condition/', component: ProjectQueryConditionOpen, name: 'project_query_condition',
        options: {
          history: true,
          pushState: false,
          transition: 'f7-cover',
        }
  },
  { path: '/complaint_project/:options', component: ComplaintProjectOpen, name: 'complaint_project',
        options: {
          history: true,
          pushState: false,
          transition: 'f7-cover',
        }
  },
  { path: '/add_project/:options', component: AddUpdateProject, name: 'add_project',
        options: {
          history: true,
          pushState: false,
          transition: 'f7-cover',
        }
  },
  { path: '/update_project/:options', component: AddUpdateProject, name: 'update_project',
        options: {
          history: true,
          pushState: false,
          transition: 'f7-cover',
        }
  },
  { path: '/detail_project/:id/', component: DetailProject, name: 'detail_project',
        options: {
          history: true,
          pushState: false,
          transition: 'f7-cover',
        }
  },

  // { path: '/engineers/', name: 'engineers', async: checkAuth }, OK
  { path: '/engineers_open/', component: EngineersOpen, name: 'engineers_open',
        options: {
          history: true,
          pushState: false,
          transition: 'f7-cover',
        }
  },
  { path: '/detail_engineer_open/:id/', component: DetailEngineerOpen, name: 'detail_engineer_open',
        options: {
          history: true,
          pushState: false,
          transition: 'f7-cover',
        }
  },
  { path: '/engineer_query_condition/', component: EngineerQueryConditionOpen, name: 'engineer_query_condition',
        options: {
          history: true,
          pushState: false,
          transition: 'f7-cover',
        }
  },
  { path: '/manage/', component: Manage, name: 'manage',
        options: {
          history: true,
          pushState: false,
          transition: 'f7-cover',
        }
  },
  { path: '/add_engineer/:options', component: AddUpdateEngineer, name: 'add_engineer',
        options: {
          history: true,
          pushState: false,
          transition: 'f7-cover',
        }
  },
  { path: '/update_engineer/:options', component: AddUpdateEngineer, name: 'update_engineer',
        options: {
          history: true,
          pushState: false,
          transition: 'f7-cover',
        }
  },
  { path: '/detail_engineer/:id/', component: DetailEngineer, name: 'detail_engineer',
        options: {
          history: true,
          pushState: false,
          transition: 'f7-cover',
        }
  },
  { path: '/upload_engineer_resume/:options', component: UploadEngineerResume, name: 'upload_engineer_resume',
        options: {
          history: true,
          pushState: false,
          transition: 'f7-cover',
        }
  },
  { path: '/panel-left/', component: PanelLeftPage,
        options: {
          history: true,
          pushState: false,
          transition: 'f7-cover',
        }
  },

  { path: '/f7home/', component: HomePage, },
  { path: '/about/', component: AboutPage, },
  { path: '/form/', component: FormPage, },
  { path: '/catalog/', component: CatalogPage, },
  { path: '/product/:id/', component: ProductPage, },
  { path: '/settings/', component: SettingsPage, },

  { path: '/dynamic-route/blog/:blogId/post/:postId/', component: DynamicRoutePage, },
  { path: '(.*)', component: NotFoundPage, }

]
