<template>
  <f7-page>
    <f7-navbar :back-link="$t('back')">
      <f7-nav-title>スキル管理</f7-nav-title>
      <f7-nav-right>
        <f7-link class="searchbar-enable" data-searchbar=".searchbar-skill-db" icon-ios="f7:search" icon-md="material:search" icon-aurora="f7:search"></f7-link>
      </f7-nav-right>
      <f7-searchbar
        class="searchbar-skill-db"
        expandable
        search-container=".search-skill-db"
        search-in=".item-title"
        :disable-button="!$theme.aurora"
        :disable-button-text="$t('cancel')"
        :placeholder="$t('search')"
        :clear-button="true"
      ></f7-searchbar>
    </f7-navbar>

    <f7-list inset inline-labels no-hairlines-md form class="">
        <!-- add_item -->
        <f7-list-input
            :label="$t('database')"
            type="text"
            :value="form.add_item"
            @input="form.add_item = $event.target.value"
            @change="item_change('add_item')"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('add_item') }}</span>
        </f7-list-input>
        <f7-list-button v-if="platformId=='ios'"
            type="submit"
            :loading="form.busy"
            v-on:click.stop.prevent="add_master_item">
            {{ $t('add') }}
        </f7-list-button>
        <f7-list-item v-else>
            <f7-col></f7-col>
            <f7-col>
              <f7-button large v-on:click.stop.prevent="add_master_item">
                {{ $t('add') }}
              </f7-button>
            </f7-col>
            <f7-col></f7-col>
        </f7-list-item>
    </f7-list>

    <f7-block-title>追加済一覧</f7-block-title>
    <f7-list inset class="searchbar-not-found">
      <f7-list-item :title="$t('Nothing found')"></f7-list-item>
    </f7-list>
    <f7-list inset class="search-skill-db searchbar-found">
      <f7-list-item v-for="(item,index) in skill_db"
        swipeout
        :title="item.label"
        :key="item.value">
        <f7-swipeout-actions right>
          <f7-swipeout-button
            color="red"
            v-on:click.stop.prevent="handleDelete(index,item)">{{ $t('delete') }}
          </f7-swipeout-button>
        </f7-swipeout-actions>
      </f7-list-item>
    </f7-list>

  </f7-page>
</template>

<script>
import { Device }  from 'framework7/framework7-lite.esm.bundle.js'
import axios from 'axios'
import swal from "sweetalert"
import Form from 'vform'
import moment from 'moment'

export default {
  name: 'ModifySkillDb',
  data() {
    return {
      skill_list: [],
      skill_db: [],
      form: new Form({
          add_item: '',
          add_item_type: '',
      }),
      platformId: 'ios',
    }
  },
  created() {
    if (!Device.cordova) {
      this.platformId = 'ios'  //pcの場合iosとする
    } else {
      this.platformId = cordova.platformId //ios,android,osx
    }
  },
  mounted() {
    this.form.clear()
    this.form.reset()
    this.get_skill_list()
  },
  methods: {
    async get_skill_list() {
      this.skill_list = []
      const { data } = await axios.get('/api/engineer/get_skill_list')
      this.skill_list = data.skill_list
      this.skill_db = this.skill_list[2].children
    },
    item_change(item_name) {
      this.form.errors.clear(item_name)
    },
    handleDelete(index,item) {
      let url = '/api/system/delete_master_item?del_item_type=skill_db&del_label=' + item.label
      swal({
        title: this.$t('confirm delete'),
        dangerMode: true,
        buttons: [this.$t('cancel'), this.$t('delete')],
      }).then((value) => {
        if (value) {
          axios.get(url)
          .then(response => {
            this.skill_db.splice(index, 1)
            let　messageText = this.$t('delete success')
            var self = this
            // Create toast
            var toastSuccess = self.$f7.toast.create({
              text: messageText,
              position: 'center',
              closeTimeout: 2000,
            })
            toastSuccess.open()
          })
          .catch(error => {
            if (error.response.status == 422) {
              if (error.response.data.message){
                swal({
                  icon: "warning",
                  text: error.response.data.message,
                });
              }
            } else {
              swal({
                icon: "error",
                text: error,
              })
            }
          })
        }
      })
    },
    async add_master_item () {
      this.form.add_item_type = 'skill_db'
      await this.form.patch('/api/system/add_master_item')
      .then(response => {
        let messageText = ''
        messageText = this.$t('add success')
        var self = this
        // Create toast
        var toastSuccess = self.$f7.toast.create({
          text: messageText,
          position: 'center',
          closeTimeout: 2000,
        })
        toastSuccess.open()
        this.$f7router.back()
      })
      .catch(error => {
        if(error.response.status == 422){
          if (error.response.data.errors) {
          } else {
            if (error.response.data.message) {
              swal({
                icon: "warning",
                text: error.response.data.message,
              });
            }
          }
        } else {
          swal({
            icon: "error",
            text: error,
          });
        }
      });
    },
  }
}
</script>
