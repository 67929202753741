<template>
  <f7-page>
    <f7-navbar :back-link="$t('back')" :title="form.name_sex_age"></f7-navbar>
    <f7-list no-hairlines-md form class="">
        <!-- 管理No -->
        <f7-list-input
            :label="$t('management No')"
            type="text"
            :value="form.id"
            readonly>
        </f7-list-input>
        <!-- copy description-->
        <f7-list-button v-if="platformId=='ios'"
            :title="$t('copy description')"
            v-on:click.stop.prevent="write2clipboard">
        </f7-list-button>
        <f7-list-item v-else>
            <f7-col></f7-col>
            <f7-col>
              <f7-button large v-on:click.stop.prevent="write2clipboard">
                {{ $t('copy description') }}
              </f7-button>
            </f7-col>
            <f7-col></f7-col>
        </f7-list-item>
        <!-- 説明文 -->
        <f7-list-input
            outline
            :label="$t('proposal description')"
            type="textarea"
            resizable
            :value="proposal_desc"
            readonly>
        </f7-list-input>
        <!-- スキル -->
        <f7-list-input
            :label="$t('skill')"
            type="textarea" resizable
            :value="form.skill"
            readonly>
        </f7-list-input>
        <!-- 語学能力 -->
        <f7-list-input
            :label="$t('language skills')"
            type="textarea" resizable
            :value="form.foreign_language"
            readonly>
        </f7-list-input>
        <!-- 参画工程 -->
        <f7-list-input
            :label="$t('process')"
            type="textarea" resizable
            :value="form.participation_process"
            readonly>
        </f7-list-input>
        <!-- 経験年数 -->
        <f7-list-input
            :label="$t('experience years')"
            type="text"
            :value="form.experience_years"
            readonly>
        </f7-list-input>
        <!-- 業務経験 -->
        <f7-list-input
            :label="$t('business experience')"
            type="textarea" resizable
            :value="form.business_experience"
            readonly>
        </f7-list-input>
        <!-- 管理経験 -->
        <f7-list-input
            :label="$t('manage experience')"
            type="textarea" resizable
            :value="form.manage_experience"
            readonly>
        </f7-list-input>
        <!-- 最寄りの駅 -->
        <f7-list-input
            :label="$t('nearest station')"
            type="text"
            :value="form.nearest_station"
            readonly>
        </f7-list-input>
        <!-- 稼働可能日 -->
        <f7-list-input
            :label="$t('available day')"
            type="text"
            :value="form.available_day"
            readonly>
        </f7-list-input>
        <!-- 仕入単価 -->
        <f7-list-input
            :label="$t('purchase price')"
            type="text"
            :value="form.purchase_price"
            readonly>
        </f7-list-input>
        <!-- 希望単価 -->
        <f7-list-input
            :label="$t('desired price')"
            type="text"
            :value="form.desired_price"
            readonly>
        </f7-list-input>
        <!-- 客先会社 -->
        <f7-list-input
            :label="$t('customer company')"
            type="text"
            :value="form.customer_company_name"
            readonly>
        </f7-list-input>
        <!-- 客先会社担当 -->
        <f7-list-input
            :label="$t('customer')"
            type="text"
            :value="form.customer_name"
            readonly>
        </f7-list-input>
        <!-- 個人電話 -->
        <f7-list-input
            :label="$t('tel no')"
            type="text"
            :value="form.mobile_telno"
            readonly>
        </f7-list-input>
        <!-- コメント -->
        <f7-list-input
            :label="$t('comment1')"
            type="textarea" resizable
            :value="form.comment1"
            readonly>
        </f7-list-input>
        <f7-list-input
            :label="$t('comment2')"
            type="textarea" resizable
            :value="form.comment2"
            readonly>
        </f7-list-input>
        <f7-list-input
            :label="$t('comment3')"
            type="textarea" resizable
            :value="form.comment3"
            readonly>
        </f7-list-input>
        <!-- wechat_no -->
        <f7-list-input
            :label="$t('wechat no')"
            type="text"
            :value="form.wechat_no"
            readonly>
        </f7-list-input>
        <!-- 登録更新日時 -->
        <f7-list-input
            :label="$t('updated at')"
            type="text"
            :value="form.updated_at"
            readonly>
        </f7-list-input>
        <!-- 更新者氏名 -->
        <f7-list-input
            :label="$t('updater name')"
            type="text"
            :value="form.admins_name"
            readonly>
        </f7-list-input>

        <f7-list-button v-if="form.resume_ServerFileName && platformId=='ios'"
            v-on:click.stop.preven="handleSkillDownload">
            {{ $t('resume download') }}
        </f7-list-button>
        <f7-list-item v-else-if="form.resume_ServerFileName && platformId!='ios'">
            <f7-col></f7-col>
            <f7-col>
              <f7-button large v-on:click.stop.preven="handleSkillDownload">
                {{ $t('resume download') }}
              </f7-button>
            </f7-col>
            <f7-col></f7-col>
        </f7-list-item>

        <f7-list-button color="gray" v-else-if="!form.resume_ServerFileName && platformId=='ios'">
            {{ $t('resume download') }}
        </f7-list-button>
        <f7-list-item color="gray" v-else-if="!form.resume_ServerFileName && platformId!='ios'">
            <f7-col></f7-col>
            <f7-col>
              <f7-button large>
                {{ $t('resume download') }}
              </f7-button>
            </f7-col>
            <f7-col></f7-col>
        </f7-list-item>

        <!-- <f7-list-button v-on:click.stop.preven="UpdateEngineer">
            {{ $t('edit') }}
        </f7-list-button> -->
        <f7-list-button v-if="platformId=='ios'"
            v-on:click.stop.preven="$f7router.back()">
            {{ $t('back') }}
        </f7-list-button>
        <f7-list-item v-else>
            <f7-col></f7-col>
            <f7-col>
              <f7-button large v-on:click.stop.preven="$f7router.back()">
                {{ $t('back') }}
              </f7-button>
            </f7-col>
            <f7-col></f7-col>
        </f7-list-item>
    </f7-list>
  </f7-page>
</template>

<script>
import { Device }  from 'framework7/framework7-lite.esm.bundle.js'
import axios from 'axios'
import swal from "sweetalert"
import Form from 'vform'
import moment from 'moment'
import * as FileSaver from 'file-saver'
// import from 'cordova-plugin-file'

export default {
  name: 'DetailEngineer',
  data() {
    return {
      engineer_id: null,
      form: new Form({
          id: '',
          name: '',
          gender: '',
          birthday: '',
          name_sex_age: '',
          age: '25',
          foreign_language: '',
          skill: '',
          participation_process: '',
          experience_years: '',
          business_experience: '',
          manage_experience: '',
          participation_process: '',
          nearest_station: '',
          available_day: '',
          availability: '',
          purchase_price: '',
          desired_price: '',
          customer_company_name: '',
          customer_name: '',
          mobile_telno: '',
          wechat_no: '',
          comment1: '',
          comment2: '',
          comment3: '',
          comment4: '',
          updated_at: '',
          admins_name: '',
          imagePreviewConfig: '',
          resume_ServerFileName: '',
          resume_file_name: '',
      }),
      proposal_desc: '',
      platformId: 'ios',
    };
  },
  created() {
    this.engineer_id = this.$f7route.params.id
    this.get_engineer()
  },
  mounted() {
    if (!Device.cordova) {
      this.platformId = 'ios'  //pcの場合iosとする
    } else {
      this.platformId = cordova.platformId //ios,android,osx
    }
  },
  methods: {
    write2clipboard() {
      if (!Device.cordova) {
          navigator.clipboard.writeText(this.proposal_desc).catch((e) => {
            console.error(e)
          })
      } else {
          cordova.plugins.clipboard.copy(this.proposal_desc);
      }
      var toastSuccess = this.$f7.toast.create({
        text: this.$t('proposal description')+'をコピーしました',
        position: 'center',
        closeTimeout: 1000,
      })
      toastSuccess.open()
    },
    set_desc_msg() {
      this.proposal_desc = ''
      if (this.form.name_sex_age) {
        this.proposal_desc = this.proposal_desc + '【氏　　名】' + this.form.name_sex_age + "\n"
      }
      if (this.form.skill) {
        this.proposal_desc = this.proposal_desc + '【スキル】' + this.form.skill + "\n"
      }
      if (this.form.foreign_language) {
        this.proposal_desc = this.proposal_desc + '【語学能力】' + this.form.foreign_language + "\n"
      }
      if (this.form.experience_years) {
        this.proposal_desc = this.proposal_desc + '【経験年数】' + this.form.experience_years + "\n"
      }
      if (this.form.business_experience) {
        this.proposal_desc = this.proposal_desc + '【業務経験】' + this.form.business_experience + "\n"
      }
      if (this.form.participation_process) {
        this.proposal_desc = this.proposal_desc + '【参画工程】' + this.form.participation_process + "\n"
      }
      if (this.form.manage_experience) {
        this.proposal_desc = this.proposal_desc + '【管理経験】' + this.form.manage_experience + "\n"
      }
      if (this.form.nearest_station) {
        this.proposal_desc = this.proposal_desc + '【最寄り駅】' + this.form.nearest_station + "\n"
      }
      if (this.form.availability) {
        this.proposal_desc = this.proposal_desc + '【稼動日】' + this.form.availability + "\n"
      }
      if (this.form.desired_price) {
        this.proposal_desc = this.proposal_desc + '【希望単金】' + this.form.desired_price + "\n"
      }
      if (this.form.comment1) {
        this.proposal_desc = this.proposal_desc + this.form.comment1 + "\n"
      }
      if (this.form.comment2) {
        this.proposal_desc = this.proposal_desc + this.form.comment2 + "\n"
      }
      if (this.form.comment3) {
        this.proposal_desc = this.proposal_desc + this.form.comment3 + "\n"
      }
    },
    get_engineer() {
      const options = {
        params: {
          id: this.engineer_id,
        }
      };
      axios.get('/api/admin/engineer/get_engineer', options)
      .then(response => {
        let item = response.data.engineer
        this.form.keys().forEach(key => {
          this.form[key] = item[key]
        })
        this.set_desc_msg()
      })
      .catch(function (error) {
        swal({
          icon: "error",
          text: error,
        })
      })
    },
    writeFile(fileEntry, dataObj, mimeType, cordova_platform) {
      // Create a FileWriter object for our FileEntry.
      fileEntry.createWriter(function (fileWriter) {
          fileWriter.onwriteend = function() {
              // console.log("Successful file write...");
              // if (cordova_platform == 'osx') {
              //   var url = fileEntry.toURL();
              //   cordova.plugins.fileOpener2.open(url, mimeType, {
              //     error: function error(err) {
              //       console.error(err);
              //       // alert("Unable to download");
              //     },
              //     success: function success() {
              //       console.log("success with opening the file");
              //     }
              //   });
              // }
          };
          fileWriter.onerror = function (e) {
              console.log("Failed file write: " + e.toString());
          };
          if (!dataObj) {
              // console.log("dataObj is null");
              dataObj = new Blob(['some file data'], { type: 'text/plain' });
          }
          fileWriter.write(dataObj);
      });
    },
    handleSkillDownload() {
      axios.get('/api/admin/engineer/skill_download?id='+this.engineer_id,{
          responseType:'blob',
          dataType:'binary',
      })
      .then(response => {
        var mimeType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-16le"
        let blob = new Blob([response.data], {
          type: mimeType
        })
        if (!Device.cordova) {
          FileSaver.saveAs(blob, this.form.resume_file_name);
        } else {
          //https://esstudio.site/2019/02/16/downloading-saving-and-opening-files-with-cordova.html
          if (cordova.platformId == 'android') {
            window.resolveLocalFileSystemURL(
              cordova.file.externalApplicationStorageDirectory,
              (dir) => {
                dir.getFile(this.form.resume_file_name, {create: true},
                  (fileEntry) => {
                    // console.log('File creation successfull!');
                    // console.log("fileEntry is file?" + fileEntry.isFile.toString());
                    this.writeFile(fileEntry, blob, mimeType, 'android');
                  },
                  function(error) {
                    console.log("ERROR: " + error.code);
                  }
                );
              }
            );
          } else if (cordova.platformId == 'osx') {
            window.resolveLocalFileSystemURL(
              cordova.file.documentsDirectory,
              (dir) => {
                dir.getFile(this.form.resume_file_name, {create: true},
                  (fileEntry) => {
                    // console.log('File creation successfull!');
                    // console.log("fileEntry is file?" + fileEntry.isFile.toString());
                    this.writeFile(fileEntry, blob, mimeType, 'osx');
                  },
                  function(error) {
                    console.log("ERROR: " + error.code);
                  }
                );
              }
            );
          } else {
            window.requestFileSystem(
              LocalFileSystem.PERSISTENT,
              0,
              (fileSystem) => {
                // console.log('file system open: ' + fileSystem.name);
                fileSystem.root.getFile(this.form.resume_file_name, {create: true, exclusive: false},
                  (fileEntry) => {
                    // console.log('File creation successfull!');
                    // console.log("fileEntry is file?" + fileEntry.isFile.toString());
                    this.writeFile(fileEntry, blob, mimeType, 'ios');
                  },
                  function(error) {
                    console.log("ERROR: " + error.code);
                  }
                );
              }
            );
          }
        }
        // const app = this.$f7;
        // app.dialog.alert(this.form.resume_file_name+' download成功');
        var self = this;
        var toastSuccess = self.$f7.toast.create({
          text: this.form.resume_file_name+' download成功',
          position: 'center',
          closeTimeout: 2000,
        });
        toastSuccess.open();

        // window.requestFileSystem(
        //   LocalFileSystem.PERSISTENT,
        //   0,
        //   (fileSystem) => {
        //       let directoryEntry = fileSystem.root;
        //       var directoryReader = directoryEntry.createReader();
        //       directoryReader.readEntries((fileEntries) => {
        //         console.log('fileEntries.length', fileEntries,  fileEntries.length);
        //       });
        //   }
        // );
        // window.requestFileSystem(window.TEMPORARY, 5 * 1024 * 1024, function (fs) {
        //     console.log('file system open: ' + fs.name);
        //     // createFile(fs.root, "newTempFile.txt", false);
        //     fs.root.getFile('newTempFile.txt', {create: true, exclusive: true}, function(fileEntry) {
        //        console.log('File creation successfull!')
        //     }, function(error) { console.log("ERROR: " + error.code) } );
        //
        // }, onErrorLoadFs);

        // saveAs(new Blob([response.data]), this.form.resume_file_name)
        // const url = window.URL.createObjectURL(new Blob([response.data]))
        // const link = document.createElement('a');
        // link.href = url;
        //
        // // link.download = this.form.resume_file_name;
        // // link.click();
        // // window.URL.revokeObjectURL(url);
        //
        // link.setAttribute('target', '_self')
        // link.setAttribute('download', this.form.resume_file_name)
        // // document.body.appendChild(link)
        // link.click()
        // // link.revokeObjectURL()

        // var $$ = this.Dom7
        // const myurl = window.URL.createObjectURL(new Blob([response.data]))
        // const mylink = document.createElement('a')
        // $$(mylink).attr({
        //   href: myurl,
        //   target: '_blank',
        //   download: this.form.resume_file_name
        // })
        // $$(mylink).click()
        //
        // // link.classList.add('external');
        // // link.href = url
        // // link.download = this.form.resume_file_name
        // // link.click()
      })
    },
    UpdateEngineer() {
      let options = { add_update_mode: '2', engineer_id: this.form.id }
      this.$f7router.navigate('/update_engineer/' + JSON.stringify(options))
    },
  }
}
</script>
<style>
</style>
