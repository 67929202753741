<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link href="/admin/login/">{{ $t('back') }}</f7-link>
      </f7-nav-left>
      <f7-nav-title>{{ $t('register') }}</f7-nav-title>
    </f7-navbar>
    <f7-card>
      <f7-card-header class="no-border" valign="bottom">
        {{ $t('temp member reg finished') }}
      </f7-card-header>
      <f7-card-content>
        <p>{{ $t('temp member reg finished text1') }}</p>
        <p>{{ $t('temp member reg finished text2') }}</p>
        <p>{{ $t('temp member reg finished text3') }}</p>
      </f7-card-content>
    </f7-card>
  </f7-page>
</template>

<script>
export default {
  middleware: 'guest',
  metaInfo () {
    return { title: this.$t('register') }
  },
}
</script>
