<template>
  <f7-page>
    <f7-navbar :title="$t('company account manage')" :back-link="$t('back')"></f7-navbar>
    <f7-list inline-labels no-hairlines-md form class="">
        <!-- company_name -->
        <f7-list-input
            :label="$t('company name')"
            type="text"
            :value="form.company_name"
            readonly>
        </f7-list-input>
        <!-- 会社印鑑証明 -->
        <f7-list-item>
          <span>会社印鑑証明</span>
          <f7-toggle
            :checked="form.cert_flag"
            @toggle:change="toggle_change()"
            color="green">
          </f7-toggle>
        </f7-list-item>

        <f7-list-button v-if="form.certpdf_downloadFileName && platformId=='ios'"
            v-on:click.stop.preven="handleCertPdfDownload">
            {{ $t('company cert download') }}
        </f7-list-button>
        <f7-list-item v-else-if="form.certpdf_downloadFileName && platformId!='ios'">
            <f7-col></f7-col>
            <f7-col>
              <f7-button large v-on:click.stop.preven="handleCertPdfDownload">
                {{ $t('company cert download') }}
              </f7-button>
            </f7-col>
            <f7-col></f7-col>
        </f7-list-item>

        <f7-list-button color="gray" v-else-if="!form.certpdf_downloadFileName && platformId=='ios'">
            {{ $t('company cert download') }}
        </f7-list-button>
        <f7-list-item color="gray" v-else-if="!form.certpdf_downloadFileName && platformId!='ios'">
            <f7-col></f7-col>
            <f7-col>
              <f7-button large>
                {{ $t('company cert download') }}
              </f7-button>
            </f7-col>
            <f7-col></f7-col>
        </f7-list-item>

        <f7-list-button v-if="platformId=='ios'"
            type="submit"
            :loading="form.busy"
            v-on:click.stop.prevent="CompanyCertPdf">
            {{ $t('confirm') }}
        </f7-list-button>
        <f7-list-item v-else>
            <f7-col></f7-col>
            <f7-col>
              <f7-button large v-on:click.stop.prevent="CompanyCertPdf">
                {{ $t('confirm') }}
              </f7-button>
            </f7-col>
            <f7-col></f7-col>
        </f7-list-item>
    </f7-list>
  </f7-page>
</template>

<script>
import { Device }  from 'framework7/framework7-lite.esm.bundle.js';
import axios from 'axios'
import swal from "sweetalert"
import Form from 'vform'
import * as FileSaver from 'file-saver'

export default {
  name: 'CompanyRuleViolation',
  data() {
    return {
      form: new Form({
          id: '',
          company_id: '',
          company_name: '',
          cert_flag: false,
          certpdf_downloadFileName: '',
      }),
      platformId: 'ios',
    }
  },
  created() {
    let options = JSON.parse(this.$f7route.params.options)
    this.form.id = options.id
    this.form.company_id = options.company_id
    this.form.company_name = options.company_name
    this.form.certpdf_downloadFileName = options.certpdf_downloadFileName
    if (options.cert_flag == 1) {
      this.form.cert_flag = true
    } else {
      this.form.cert_flag = false
    }
  },
  mounted() {
    if (!Device.cordova) {
      this.platformId = 'ios'  //pcの場合iosとする
    } else {
      this.platformId = cordova.platformId //ios,android,osx
    }
  },
  methods: {
    toggle_change(event) {
      this.form.cert_flag = !this.form.cert_flag
    },
    async CompanyCertPdf () {
      await this.form.patch('/api/system/company_cert_pdf')
      .then(response => {
        this.status = response.data.status
        let messageText = ''
        messageText = this.$t('update success')
        var self = this
        // Create toast
        var toastSuccess = self.$f7.toast.create({
          text: messageText,
          position: 'center',
          closeTimeout: 2000,
        })
        toastSuccess.open()
        // this.$f7router.back()
        this.$f7router.navigate('/system/')
      })
      .catch(error => {
        if(error.response.status == 422){
          if (error.response.data.errors) {
          } else {
            if (error.response.data.message) {
              swal({
                icon: "warning",
                text: error.response.data.message,
              });
            }
          }
        } else {
          swal({
            icon: "error",
            text: error,
          });
        }
      });
    },
    writeFile(fileEntry, dataObj) {
      // Create a FileWriter object for our FileEntry.
      fileEntry.createWriter(function (fileWriter) {
          fileWriter.onwriteend = function() {
              // console.log("Successful file write...");
          };
          fileWriter.onerror = function (e) {
              console.log("Failed file write: " + e.toString());
          };
          if (!dataObj) {
              // console.log("dataObj is null");
              dataObj = new Blob(['some file data'], { type: 'text/plain' });
          }
          fileWriter.write(dataObj);
      });
    },
    async handleCertPdfDownload() {
      axios.get('/api/system/cert_pdf_download?company_id='+this.form.company_id,{
          responseType:'blob',
          dataType:'binary',
      })
      .then(response => {
        let blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-16le"
        })
        if (!Device.cordova) {
          FileSaver.saveAs(blob, this.form.certpdf_downloadFileName);
        } else {
          window.requestFileSystem(
            LocalFileSystem.PERSISTENT,
            0,
            (fileSystem) => {
              // console.log('file system open: ' + fileSystem.name);
              fileSystem.root.getFile(this.form.certpdf_downloadFileName, {create: true, exclusive: false},
                (fileEntry) => {
                  // console.log('File creation successfull!');
                  // console.log("fileEntry is file?" + fileEntry.isFile.toString());
                  this.writeFile(fileEntry, blob);
                },
                function(error) {
                  console.log("ERROR: " + error.code);
                }
              );
            }
          );
        }
        // const app = this.$f7;
        // app.dialog.alert(this.form.resume_file_name+' download成功');
        var self = this;
        var toastSuccess = self.$f7.toast.create({
          text: this.form.certpdf_downloadFileName+' download成功',
          position: 'center',
          closeTimeout: 2000,
        });
        toastSuccess.open();
        // const url = window.URL.createObjectURL(new Blob([response.data]))
        // const link = document.createElement('a');
        // link.href = url;
        // link.download = this.form.certpdf_downloadFileName;
        // link.click();
        // window.URL.revokeObjectURL(url);
      })
    },
  }
}
</script>
