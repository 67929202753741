<template>
  <f7-page :page-content="false">
    <!-- <f7-navbar :title="$t('login')" :back-link="$t('Back')"></f7-navbar> -->
    <f7-navbar :title="$t('login')"></f7-navbar>

    <f7-toolbar tabbar position="top">
      <f7-link tab-link="#admin_login" v-bind:class="[(login_tab=='admin_login')? 'tab-link-active':'']" @click="handleTabClick('admin_login')">{{ $t('admin login') }}</f7-link>
      <f7-link tab-link="#worker_login" v-bind:class="[(login_tab=='worker_login')? 'tab-link-active':'']" @click="handleTabClick('worker_login')">{{ $t('worker login') }}</f7-link>
    </f7-toolbar>

    <f7-tabs animated>
        <f7-tab id="admin_login" class="page-content" v-if="login_tab=='admin_login'">
          <!-- <f7-block> -->
            <!-- <f7-login-screen-title>{{ $t('admin login') }}</f7-login-screen-title> -->
            <f7-list no-hairlines-md form ref="form" form-store-data>
              <f7-list-input
                  ref="myInput"
                  :label="$t('admin email')"
                  type="email"
                  :value="form.email"
                  @input="form.email = $event.target.value"
                  clear-button>
                  <span slot="info" style="color:#ff0000">{{ form.errors.get('email') }}</span>
              </f7-list-input>
              <f7-list-input
                  :label="$t('password')"
                  type="password"
                  :value="form.password"
                  @input="form.password = $event.target.value"
                  clear-button>
                  <span slot="info" style="color:#ff0000">{{ form.errors.get('password') }}</span>
              </f7-list-input>
              <!-- <has-error :form="form" field="password"/> -->
              <!-- Remember Me -->
              <f7-list-item checkbox
                  :title="$t('remember me')"
                  name="remember"
                  :checked="form.remember"
                  @change="handleChecked">
              </f7-list-item>
              <f7-list-button v-if="platformId=='ios'"
                  type="submit"
                  :loading="form.busy"
                  v-on:click.stop.prevent="login()">
                  {{ $t('login') }}
              </f7-list-button>
              <f7-list-item v-else>
                  <f7-col></f7-col>
                  <f7-col>
                    <f7-button large v-on:click.stop.prevent="login()">
                      {{ $t('login') }}
                    </f7-button>
                  </f7-col>
                  <f7-col></f7-col>
              </f7-list-item>
              <!-- <f7-list-button v-show="cordova" type="submit" @click="remember()">{{ $t('recent login info') }}</f7-list-button> -->
              <f7-list-button v-if="platformId=='ios'"
                  link="/admin/password/request/" :title="$t('forgot password')">
              </f7-list-button>
              <f7-list-item v-else>
                  <f7-col></f7-col>
                  <f7-col>
                    <f7-button large href="/admin/password/request/" :text="$t('forgot password')"></f7-button>
                  </f7-col>
                  <f7-col></f7-col>
              </f7-list-item>
              <!-- <f7-block-footer>
                Some text about login information.<br>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </f7-block-footer> -->
            </f7-list>
            <f7-block-title>会員登録</f7-block-title>
            <f7-list no-hairlines-md>
              <f7-list-button v-if="platformId=='ios'"
                  link="/admin/register/" :title="$t('register')">
              </f7-list-button>
              <f7-list-item v-else>
                <f7-col></f7-col>
                <f7-col>
                  <f7-button large href="/admin/register/" :text="$t('register')"></f7-button>
                </f7-col>
                <f7-col></f7-col>
              </f7-list-item>
            </f7-list>
            <f7-block-footer>
                サポート:support@newways.co.jp
            </f7-block-footer>
          <!-- </f7-block> -->
        </f7-tab>
        <f7-tab id="worker_login" class="page-content" v-else>
            <f7-list no-hairlines-md form ref="form2" form-store-data>
              <f7-list-input
                  floating-label
                  ref="myInput2"
                  :label="$t('worker id')"
                  type="text"
                  :value="form.account_id"
                  @input="form.account_id = $event.target.value"
                  clear-button>
                  <span slot="info" style="color:#ff0000">{{ form.errors.get('account_id') }}</span>
              </f7-list-input>
              <f7-list-input
                  floating-label
                  :label="$t('password')"
                  type="password"
                  :value="form.password"
                  @input="form.password = $event.target.value"
                  clear-button>
                  <span slot="info" style="color:#ff0000">{{ form.errors.get('password') }}</span>
              </f7-list-input>
              <!-- <has-error :form="form" field="password"/> -->
              <!-- Remember Me -->
              <f7-list-item checkbox
                :title="$t('remember me')"
                name="remember"
                :checked="form.remember"
                @change="handleChecked">
              ></f7-list-item>
              <f7-list-button v-if="platformId=='ios'"
                  type="submit" v-on:click.stop.prevent="login()">
                  {{ $t('login') }}
              </f7-list-button>
              <f7-list-item v-else>
                  <f7-col></f7-col>
                  <f7-col>
                    <f7-button large v-on:click.stop.prevent="login()">
                      {{ $t('login') }}
                    </f7-button>
                  </f7-col>
                  <f7-col></f7-col>
              </f7-list-item>
              <!-- <f7-list-button v-show="cordova" type="submit" @click="remember()">{{ $t('recent login info') }}</f7-list-button> -->
              <!-- <f7-block-footer>
                Some text about login information.<br>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </f7-block-footer> -->
            </f7-list>
        </f7-tab>
    </f7-tabs>
  </f7-page>
</template>

<script>
import { Device }  from 'framework7/framework7-lite.esm.bundle.js'
import Form from 'vform'
import swal from "sweetalert"
import moment from 'moment'
import Cookies from 'js-cookie'
// const Base64 = require('js-base64').Base64
import {Base64} from 'js-base64'

export default {
  middleware: 'guest',
  metaInfo () {
    return { title: this.$t('login') }
  },
  data() {
    return {
      login_tab: 'admin_login',
      // cordova: 'true',
      form: new Form({
        email: '',
        account_id: '',
        password: '',
        remember: false
      }),
      platformId: 'ios',
    }
  },
  mounted() {
    // this.form.clear()
    // this.form.reset()
    this.form.remember = false
    // if (Device.cordova) {
    //   this.cordova = true
    // } else {
    //   this.cordova = false
    // }
    if (!Device.cordova) {
      let remember = Cookies.get('remember')
      if (typeof remember !== 'undefined') {
        this.form.remember = true
      }
      if (this.form.remember == true) {
        let tab = Cookies.get('login_tab')
        if (typeof tab !== 'undefined') {
          this.login_tab = Cookies.get('login_tab')
        }
        let email = Cookies.get('email')
        if (typeof email !== 'undefined') {
          this.form.email = Cookies.get('email')
        }
        let account_id = Cookies.get('account_id')
        if (typeof account_id !== 'undefined') {
          this.form.account_id = Cookies.get('account_id')
        }
        let password = Cookies.get('password')
        if (typeof password !== 'undefined') {
          this.form.password = Base64.decode(password)
        }
      }
      this.platformId = 'ios'  //pcの場合iosとする
    } else {
      this.get_data_from_internal_storage('loginfo.json')
      .then(json_data => {
        //console.log('get_data=' + json_data)
        var obj_data = JSON.parse(json_data)
        if (obj_data.remember) {
          this.login_tab = obj_data.login_tab
          this.form.email = obj_data.email
          this.form.account_id = obj_data.account_id
          this.form.password = Base64.decode(obj_data.password)
          this.form.remember = obj_data.remember
        }
      })
      this.platformId = cordova.platformId //ios,android,osx
    }
  },
  methods:{
    // async remember() {
    //   this.get_data_from_internal_storage('loginfo.json')
    //   .then(json_data => {
    //     console.log('get_data=' + json_data)
    //     var obj_data = JSON.parse(json_data)
    //     if (obj_data.remember) {
    //       this.form.clear()
    //       this.form.reset()
    //       this.form.email = obj_data.email
    //       this.form.account_id = obj_data.account_id
    //       this.form.password = Base64.decode(obj_data.password)
    //       this.form.remember = obj_data.remember
    //     }
    //   })
    // },
    // https://www.atmarkit.co.jp/ait/articles/1605/23/news032_4.html
    // https://reon777.com/2019/10/11/internal-storage/
    async get_data_from_internal_storage(file_name) {
      //console.log('get_data_from_internal_storage')
      var options = {
        exclusive: false,
        create: false
      }
      const fileSystem = await new Promise(function(resolve, reject) {
        window.resolveLocalFileSystemURL(
          cordova.file.dataDirectory,
          resolve,
          reject
        )
      })
      const fileEntry = await new Promise(function(resolve, reject) {
        fileSystem.getFile(file_name, options, resolve, reject)
      })
      return new Promise(resolve => {
        fileEntry.file(
          function(file) {
            var reader = new FileReader()
            reader.onloadend = function() {
              //console.log('Successful file read: ' + this.result)
              //console.log(fileEntry.fullPath + ': ' + this.result)
              resolve(this.result)
            }
            reader.readAsText(file)
          },
          function(error) {
            console.log('readFile エラーが発生', error.code)
          }
        )
      })
    },
    saveLoginfo(loginfoFileName) {
      // 設定データをJSONフォーマットの文字列に変換
      var formData = {
          login_tab: this.login_tab,
          email: this.form.email,
          account_id: this.form.account_id,
          password: Base64.encode(this.form.password),
          remember: this.form.remember
      }
      var jsonData = JSON.stringify(formData);
      // dataDirectoryフォルダのDirectoryEntryオブジェクトを取得
      window.resolveLocalFileSystemURL(cordova.file.dataDirectory,
        // （第2引数）成功したら呼び出される関数
        function success1(directoryEntry) {
          // console.log("saveLoginfo():resolveLocalFileSystemURI Success: "
          //             + directoryEntry.nativeURL);
          // ここにDirectoryEntryオブジェクトを使ったコードを書いていく
          // loginfoFileNameファイルを取得（存在しないときは作成）
          directoryEntry.getFile(loginfoFileName, { create: true },
            // （getFileの第3引数）成功したら呼び出される関数
            function success2(fileEntry) {
              //console.log("saveLoginfo():directoryEntry.getFile Success: " + fileEntry.nativeURL);
              // ここにFileEntryオブジェクトを使ったコードを書いていく
              // FileWriterオブジェクトを作成
              fileEntry.createWriter(
                // （第1引数）成功したら呼び出される関数
                function success3(fileWriter) {
                  //console.log("saveLoginfo():fileEntry.createWriter Success: " + fileWriter.localURL);
                  // ここにFileWriterオブジェクトを使ったコードを書いていく
                  // データ書き込み後のハンドラーをセット
                  fileWriter.onwriteend = function (e) {
                    //console.log('Write of file "' + loginfoFileName + '" completed.');
                  };
                  // データ書き込み失敗時のハンドラーをセット
                  fileWriter.onerror = function (e) {
                    //console.log('Write failed: ' + e.toString());
                  };
                  // データを書き出し
                  fileWriter.write(jsonData);
                },
                // （第2引数）失敗したら呼び出される関数
                function fail(error) {
                  console.log("saveLoginfo():fileEntry.createWriter Error: " + error.code);
                }
              );
            },
            // （getFileの第4引数）失敗したら呼び出される関数
            function fail(error) {
              console.log("saveLoginfo():directoryEntry.getFile Error: " + error.code);
            }
          );
        },
        // （第3引数）失敗したら呼び出される関数
        function fail(error) {
          console.log("saveLoginfo():resolveLocalFileSystemURI Error: " + error.code);
        }
      );
    },
    handleChecked(event) {
      if (event.target.checked) {
        this.form.remember = true
      } else {
        this.form.remember = false
      }
    },
    handleTabClick(login_tab) {
      if (this.login_tab != login_tab) {
        //this.form.remember = false
        this.form.password = ''
        this.form.errors.clear('password')
        this.login_tab = login_tab
      }
    },
    async login() {
      const self = this
      const app = self.$f7
      const router = self.$f7router
      // console.log([...this.$refs.form.$el]);
      // app.dialog.alert(`Email: ${self.form.email}<br>Password: ${self.form.password}`, () => {
      //   router.back();
      // });
      let url = ''
      if (this.login_tab == 'admin_login') {
        url = '/api/admin/login'
      } else {
        url = '/api/admin/login/worker'
      }
      await this.form.post(url)
      .then(response => {
        let data = response.data
        // Save the token.
        this.$store.dispatch('auth/saveToken', {
          token: data.token,
          remember: this.form.remember
        })
        // Fetch the user.
        this.$store.dispatch('auth/fetchUser')
        .then(response => {
          if (!Device.cordova) {
            if (this.form.remember) {
              Cookies.set('login_tab', this.login_tab, { expires: 365 })
              Cookies.set('email', this.form.email, { expires: 365 })
              Cookies.set('account_id', this.form.account_id, { expires: 365 })
              Cookies.set('password', Base64.encode(this.form.password), { expires: 365 })
              Cookies.set('remember', this.form.remember, { expires: 365 })
            } else {
              Cookies.remove('login_tab')
              Cookies.remove('email')
              Cookies.remove('account_id')
              Cookies.remove('password')
              Cookies.remove('remember')
            }
          } else {
            this.saveLoginfo('loginfo.json')
          }
          // this.form.clear()
          // this.form.reset()

          // Redirect home.
          //this.$f7router.navigate('/mobile_tab_home/')
          // this.$f7router.back()
          // this.$f7.views.main.router.load({url: '/mobile_tab_home/'})
          // this.$f7.views.main.router.navigate('/mobile_tab_home/')
          // this.$router.push({ name: 'admin.mobile_tab_home' })
        })
        .catch(error => {
          swal({
            icon: "error",
            text: error,
          })
        })
      })
      .catch(error => {
        if (error.response.status == 422) {
          if (error.response.data.errors) {
            // if (this.form.errors.has('email')) {
            // console.log(error.response.data.errors)
            // console.log(this.form.errors)
          } else {
            if (error.response.data.message) {
              swal({
                icon: "warning",
                text: error.response.data.message,
              })
            }
          }
        } else {
          swal({
            icon: "error",
            text: error,
          })
        }
      })
    },
  }
}
</script>
