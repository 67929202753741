<template>
  <f7-page>
    <f7-navbar :back-link="$t('back')" :title="form.company_name"></f7-navbar>
    <f7-list inline-labels no-hairlines-md form class="">
        <!-- 管理者Id -->
        <f7-list-input
            :label="$t('management No')"
            type="text"
            :value="form.id"
            readonly>
        </f7-list-input>
        <!-- 管理者email-->
        <f7-list-input
            :label="$t('admin email')"
            type="text"
            :value="form.email"
            readonly>
        </f7-list-input>
        <!-- 管理者氏名 -->
        <f7-list-input
            :label="$t('manager name')"
            type="text"
            :value="form.name"
            readonly>
        </f7-list-input>
        <!-- 会社Id -->
        <f7-list-input
            :label="$t('company id')"
            type="text"
            :value="form.company_id"
            readonly>
        </f7-list-input>
        <!-- 会社名前 -->
        <f7-list-input
            :label="$t('company name')"
            type="text"
            :value="form.company_name"
            readonly>
        </f7-list-input>
        <!-- メモ -->
        <f7-list-input
            :label="$t('memo')"
            type="textarea"
            resizable
            :value="form.memo"
            readonly>
        </f7-list-input>
        <!-- zip -->
        <f7-list-input
            :label="$t('zip code')"
            type="text"
            :value="form.zip"
            readonly>
        </f7-list-input>
        <!-- 都道府県 -->
        <f7-list-input
            :label="$t('prefecture')"
            type="text"
            :value="form.pref_id"
            readonly>
        </f7-list-input>
        <!-- 住所１ -->
        <f7-list-input
            :label="$t('address1')"
            type="text"
            :value="form.address1"
            readonly>
        </f7-list-input>
        <!-- 住所２ -->
        <f7-list-input
            :label="$t('address2')"
            type="text"
            :value="form.address2"
            readonly>
        </f7-list-input>
        <!-- website -->
        <f7-list-input
            :label="$t('website')"
            type="text"
            :value="form.website"
            readonly>
        </f7-list-input>
        <!-- mobile_telno -->
        <f7-list-input
            :label="$t('mobile_telno')"
            type="text"
            :value="form.mobile_telno"
            readonly>
        </f7-list-input>
        <!-- tel_no -->
        <f7-list-input
            :label="$t('tel_no')"
            type="text"
            :value="form.tel_no"
            readonly>
        </f7-list-input>
        <!-- cert_file_json -->
        <f7-list-input
            label="認証ファイル"
            type="text"
            :value="form.cert_file_json"
            readonly>
        </f7-list-input>
        <!-- cert_flag -->
        <f7-list-input
            label="会社認証"
            type="text"
            :value="form.cert_flag? '会社印鑑証明OK' : '会社印鑑証明無'"
            readonly>
        </f7-list-input>
        <!-- rule_violation_flag -->
        <f7-list-input
            label="利用状態"
            type="text"
            :value="form.rule_violation_flag? '違約暫停中 '+form.rule_violation_datefrom : '正常使用中'"
            readonly>
        </f7-list-input>
        <!-- 合計チャージ金額 -->
        <f7-list-input
            label="合計チャージ金額"
            type="text"
            :value="form.total_charge_amount"
            readonly>
        </f7-list-input>
        <!-- 合計払い戻す金額 -->
        <f7-list-input
            label="合計払い戻す金額"
            type="text"
            :value="form.total_refund_amount"
            readonly>
        </f7-list-input>
        <!-- 合計消費金額 -->
        <f7-list-input
            label="合計消費金額"
            type="text"
            :value="form.total_consumption_amount"
            readonly>
        </f7-list-input>
        <!-- 合計残高金額 -->
        <f7-list-input
            label="合計残高金額"
            type="text"
            :value="form.total_balance_amount"
            readonly>
        </f7-list-input>
        <!-- 作成日 -->
        <f7-list-input
            :label="$t('created time')"
            type="text"
            :value="form.created_at"
            readonly>
        </f7-list-input>
        <!-- 登録日 -->
        <f7-list-input
            :label="$t('updated at')"
            type="text"
            :value="form.updated_at"
            readonly>
        </f7-list-input>

        <f7-list-button v-if="form.cert_file_json && platformId=='ios'"
            v-on:click.stop.preven="handleCertPdfDownload">
            {{ $t('company cert download') }}
        </f7-list-button>
        <f7-list-item v-else-if="form.cert_file_json && platformId!='ios'">
            <f7-col></f7-col>
            <f7-col>
              <f7-button large v-on:click.stop.preven="handleCertPdfDownload">
                {{ $t('company cert download') }}
              </f7-button>
            </f7-col>
            <f7-col></f7-col>
        </f7-list-item>

        <f7-list-button color="gray" v-else-if="!form.cert_file_json && platformId=='ios'">
            {{ $t('company cert download') }}
        </f7-list-button>
        <f7-list-item color="gray" v-else-if="!form.cert_file_json && platformId!='ios'">
            <f7-col></f7-col>
            <f7-col>
              <f7-button large>
                {{ $t('company cert download') }}
              </f7-button>
            </f7-col>
            <f7-col></f7-col>
        </f7-list-item>

        <f7-list-button v-if="platformId=='ios'"
            v-on:click.stop.prevent="$f7router.back()">
            {{ $t('back') }}
        </f7-list-button>
        <f7-list-item v-else>
            <f7-col></f7-col>
            <f7-col>
              <f7-button large v-on:click.stop.prevent="$f7router.back()">
                {{ $t('back') }}
              </f7-button>
            </f7-col>
            <f7-col></f7-col>
        </f7-list-item>
    </f7-list>
  </f7-page>
</template>

<script>
import { Device }  from 'framework7/framework7-lite.esm.bundle.js';
import axios from 'axios'
import swal from "sweetalert"
import moment from 'moment';
import Form from 'vform'
import * as FileSaver from 'file-saver'

export default {
  name: 'DetailCompanyAccount',
  data() {
    return {
      admin_id: null,
      form: new Form(),
      platformId: 'ios',
    };
  },
  created() {
    if (!Device.cordova) {
      this.platformId = 'ios'  //pcの場合iosとする
    } else {
      this.platformId = cordova.platformId //ios,android,osx
    }
  },
  mounted() {
    this.admin_id = this.$f7route.params.id
    this.get_company_account()
  },
  methods: {
    async get_company_account() {
      const options = {
        params: {
          id: this.admin_id,
        }
      };
      axios.get('/api/system/get_company_account', options)
      .then(response => {
        let item = response.data.company_account
        this.form = item
      })
      .catch(function (error) {
        swal({
          icon: "error",
          text: error,
        })
      })
    },
    writeFile(fileEntry, dataObj) {
      // Create a FileWriter object for our FileEntry.
      fileEntry.createWriter(function (fileWriter) {
          fileWriter.onwriteend = function() {
              // console.log("Successful file write...");
          };
          fileWriter.onerror = function (e) {
              console.log("Failed file write: " + e.toString());
          };
          if (!dataObj) {
              // console.log("dataObj is null");
              dataObj = new Blob(['some file data'], { type: 'text/plain' });
          }
          fileWriter.write(dataObj);
      });
    },
    async handleCertPdfDownload() {
      axios.get('/api/system/cert_pdf_download?company_id='+this.form.company_id,{
          responseType:'blob',
          dataType:'binary',
      })
      .then(response => {
        let blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-16le"
        })
        if (!Device.cordova) {
          FileSaver.saveAs(blob, this.form.certpdf_downloadFileName);
        } else {
          window.requestFileSystem(
            LocalFileSystem.PERSISTENT,
            0,
            (fileSystem) => {
              // console.log('file system open: ' + fileSystem.name);
              fileSystem.root.getFile(this.form.certpdf_downloadFileName, {create: true, exclusive: false},
                (fileEntry) => {
                  // console.log('File creation successfull!');
                  // console.log("fileEntry is file?" + fileEntry.isFile.toString());
                  this.writeFile(fileEntry, blob);
                },
                function(error) {
                  console.log("ERROR: " + error.code);
                }
              );
            }
          );
        }
        // const app = this.$f7;
        // app.dialog.alert(this.form.resume_file_name+' download成功');
        var self = this;
        var toastSuccess = self.$f7.toast.create({
          text: this.form.certpdf_downloadFileName+' download成功',
          position: 'center',
          closeTimeout: 2000,
        });
        toastSuccess.open();
        // const url = window.URL.createObjectURL(new Blob([response.data]))
        // const link = document.createElement('a');
        // link.href = url;
        // link.download = this.form.certpdf_downloadFileName;
        // link.click();
        // window.URL.revokeObjectURL(url);
      })
    },
  }
}
</script>
