<template>
  <f7-page>
    <f7-navbar :title="$t('contact')" :back-link="$t('back')"></f7-navbar>
    <f7-list inset>
      <f7-list-item v-for="(item,index) in contact_items_wechat_id"
        :title="'Wechat id '+(index+1)"
        :after="item"
        :key="item">
      </f7-list-item>
      <f7-list-item v-for="(item,index) in contact_items_email"
        :title="'Email '+(index+1)"
        :after="item"
        :key="item">
      </f7-list-item>
      <f7-block-footer>
        上記のwechat_idまたはEmailをお使い、お問い合わせくださいませ。
      </f7-block-footer>
    </f7-list>
        
</f7-page>
</template>

<script>
import axios from 'axios'
import swal from "sweetalert"

export default {
  name: 'Contact',
  data() {
      return {
        contact_items_wechat_id: [],
        contact_items_email: [],
      }
  },
  mounted() {
    this.get_contact_info()
  },
  methods: {
    async get_contact_info() {
      await axios.get('/api/admin/setting/get_contact_info')
      .then(response => {
        this.contact_items_wechat_id = response.data.support_wechat_id
        this.contact_items_email = response.data.support_email
      })
      .catch(function (error) {
        if (error.response.status == 422) {
          if (error.response.data.message){
            swal({
              icon: "warning",
              text: error.response.data.message,
            })
          }
        } else {
          swal({
            icon: "error",
            text: error,
          })
        }
      })
    },
  }
}
</script>
