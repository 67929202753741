<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left :back-link="$t('back')" back-link-url="/admin/login/" back-link-force>
        <!-- <f7-link href="/admin/login/"></f7-link> -->
      </f7-nav-left>
      <f7-nav-title>{{ $t('register') }}</f7-nav-title>
    </f7-navbar>
    <f7-list no-hairlines-md form>
      <f7-list-input
          :label="$t('admin email')"
          type="email"
          :value="form.email"
          @input="form.email = $event.target.value"
          @change="item_change('email')"
          clear-button>
          <span slot="info" style="color:#ff0000">{{ form.errors.get('email') }}</span>
      </f7-list-input>
      <!-- Password -->
      <f7-list-input
          :label="$t('new password')"
          type="password"
          :value="form.password"
          @input="form.password = $event.target.value"
          @change="item_change('password')"
          clear-button>
          <span slot="info" style="color:#ff0000">{{ form.errors.get('password') }}</span>
      </f7-list-input>
      <!-- Password Confirmation -->
      <f7-list-input
          :label="$t('confirm password')"
          type="password"
          :value="form.password_confirmation"
          @input="form.password_confirmation = $event.target.value"
          @change="item_change('password_confirmation')"
          clear-button>
          <span slot="info" style="color:#ff0000">{{ form.errors.get('password_confirmation') }}</span>
      </f7-list-input>
      <f7-list-button v-if="platformId=='ios'"
          type="submit"
          :loading="form.busy"
          v-on:click.stop.prevent="register()">
          {{ $t('agree to the terms of service and register') }}
      </f7-list-button>
      <f7-list-item v-else>
          <f7-col></f7-col>
          <f7-col>
            <f7-button large v-on:click.stop.prevent="register()">
              {{ $t('agree to the terms of service and register') }}
            </f7-button>
          </f7-col>
          <f7-col></f7-col>
      </f7-list-item>
      <f7-list-item
          swipeout
          link="/serviceterms/"
          :title="$t('service terms')">
      </f7-list-item>
      <f7-list-item
          swipeout
          link="/privacypolicy/"
          :title="$t('privacy policy')">
      </f7-list-item>
    </f7-list>
  </f7-page>

</template>

<script>
import { Device }  from 'framework7/framework7-lite.esm.bundle.js';
import Form from 'vform'

export default {
  middleware: 'guest',
  metaInfo () {
    return { title: this.$t('register') }
  },
  data: () => ({
    status: '',
    form: new Form({
      name: '',
      email: '',
      password: '',
      password_confirmation: ''
    }),
    platformId: 'ios',
  }),
  mounted() {
    if (!Device.cordova) {
      this.platformId = 'ios'  //pcの場合iosとする
    } else {
      this.platformId = cordova.platformId //ios,android,osx
    }
  },
  methods: {
    item_change(item_name) {
      this.form.errors.clear(item_name)
    },
    async register () {
      // Register the user.
      await this.form.post('/api/admin/register_mobile')
      .then(response => {
        this.status = response.data.status
        this.form.clear()
        this.form.reset()
        this.$f7router.navigate('/admin/registered/')
      })
      .catch(error => {
        if (error.response.status == 422) {
          if (error.response.data.errors) {
          } else {
            if (error.response.data.message) {
              swal({
                icon: "warning",
                text: error.response.data.message,
              });
            }
          }
        } else {
          swal({
            icon: "error",
            text: error,
          })
        }
      })
    },
  }
}
</script>
