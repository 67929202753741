<template>
  <f7-page>
    <!-- <f7-navbar :back-link="$t('back')" :title="$t('project')"></f7-navbar> -->
    <f7-navbar>
      <f7-nav-left :back-link="$t('back')"></f7-nav-left>
      <f7-nav-title>{{ $t('project') }}</f7-nav-title>
      <f7-nav-right>
        <f7-link v-on:click.stop.prevent="handleShare"
          icon-ios="f7:square_arrow_up" icon-aurora="f7:square_arrow_up" icon-md="material:share">
        </f7-link>
      </f7-nav-right>
    </f7-navbar>
    <div class="job">
      <div class="job-banner">
        <h1 class="name">
          <!-- <span v-if="favoriteFlag" class="f7-icons favorite" v-on:click.stop.prevent="handleShare">heart_fill</span>
          <span v-else class="f7-icons favorite" v-on:click.stop.prevent="handleShare">heart</span> -->
          <!-- <span class="favorite" v-on:click.stop.prevent="handleShare">
            <f7-icon
              ios="f7:square_arrow_up" aurora="f7:square_arrow_up" md="material:share">
            </f7-icon>
          </span> -->
          <p class="job-title">{{form.project_name}}</p>
        </h1>
        <p style="font-size:12px;padding-bottom: 5px;">
          {{form.available_day}}
          <!-- <span class="vline">|</span>
          {{form.experience_years}} 年
          <span class="vline">|</span>
          {{form.desired_price}} 万 -->
          <span class="vline">|</span>
          {{form.nearest_station}} 駅
        </p>
        <div v-if="!form.skill==''" class="job-tags">
          <span class="skill">{{form.skill}}</span>
        </div>
        <div v-if="!form.foreign_language==''" class="job-tags">
          <span class="skill">{{form.foreign_language}}</span>
        </div>
        <div class="time">{{ $t('updated at') }}：{{form.updated_at}}</div>
      </div>
      <div class="job-detail">
        <h3>{{ $t('req description') }}</h3>
        <div class="text">{{form.req_desc}}</div>
      </div>
      <div class="job-author">
        <div class="name">{{form.company_name}}</div>
        <div style="display: inline-block;">
          <p class="gray">営業wechat:{{form.wechat_no}}</p>
        </div>
        <div v-if="!form.wechat_no==''" style="display: inline-block;vertical-align:middle">
          <!-- <i class="f7-icons size-16" @click="write2clipboard_wechat">doc_on_clipboard</i> -->
          <span v-on:click="write2clipboard_wechat">
            <f7-icon class="size-16"
              ios="f7:square_on_square" aurora="f7:square_on_square" md="material:content_copy">
            </f7-icon>
          </span>
        </div>
      </div>
    </div>
    <!-- <f7-list no-hairlines-md form class="">
        管理No
        <f7-list-input
            :label="$t('management No')"
            type="text"
            :value="form.id"
            readonly>
        </f7-list-input>
        登録日
        <f7-list-input
            :label="$t('updated at')"
            type="text"
            :value="form.updated_at"
            readonly>
        </f7-list-input>
        案件説明詳細
        <f7-list-input
            outline
            :label="$t('req description')"
            type="textarea"
            resizable
            :value="form.req_desc"
            readonly>
        </f7-list-input>
        スキル
        <f7-list-input
            :label="$t('skill')"
            type="textarea" resizable
            :value="form.skill"
            readonly>
        </f7-list-input>
        語学能力
        <f7-list-input
            :label="$t('language skills')"
            type="textarea" resizable
            :value="form.foreign_language"
            readonly>
        </f7-list-input>
        最寄りの駅
        <f7-list-input
            :label="$t('nearest station')"
            type="text"
            :value="form.nearest_station"
            readonly>
        </f7-list-input>
        稼働可能日
        <f7-list-input
            :label="$t('available day')"
            type="text"
            :value="form.available_day"
            readonly>
        </f7-list-input>
        参画工程
        <f7-list-input
            :label="$t('process')"
            type="textarea" resizable
            :value="form.participation_process"
            readonly>
        </f7-list-input>
        管理経験
        <f7-list-input
            :label="$t('manage experience')"
            type="text"
            :value="form.manage_experience"
            readonly>
        </f7-list-input>
        経験年数
        <f7-list-input
            :label="$t('experience years')"
            type="text"
            :value="form.experience_years"
            readonly>
        </f7-list-input>
        希望単価
        <f7-list-input
            :label="$t('desired price')"
            type="text"
            :value="form.desired_price"
            readonly>
        </f7-list-input>
        所属会社
        <f7-list-input
            :label="$t('company belongs')"
            type="text"
            :value="form.company_name"
            readonly>
        </f7-list-input>
        wechat
        <f7-list-input
            :label="$t('wechat no')"
            type="text"
            :value="form.wechat_no"
            readonly>
        </f7-list-input>
        コメント
        <f7-list-input
            :label="$t('comment')"
            type="text"
            :value="form.comment"
            readonly>
        </f7-list-input>
        <f7-list-button v-if="platformId=='ios'"
            v-on:click.stop.prevent="$f7router.back()">
            {{ $t('back') }}
        </f7-list-button>
        <f7-list-item v-else>
            <f7-col></f7-col>
            <f7-col>
              <f7-button large v-on:click.stop.prevent="$f7router.back()">
                {{ $t('back') }}
              </f7-button>
            </f7-col>
            <f7-col></f7-col>
        </f7-list-item>
    </f7-list> -->

  </f7-page>
</template>

<script>
import { Device }  from 'framework7/framework7-lite.esm.bundle.js';
import axios from 'axios'
import swal from "sweetalert"
import moment from 'moment';

export default {
  name: 'DetailProject',
  data() {
    return {
      project_id: null,
      form: {},
      platformId: 'ios',
      // favoriteFlag: false,
    };
  },
  created() {
    if (!Device.cordova) {
      this.platformId = 'ios'  //pcの場合iosとする
    } else {
      this.platformId = cordova.platformId //ios,android,osx
    }
  },
  mounted() {
    this.project_id = this.$f7route.params.id
    this.get_project()
  },
  methods: {
    handleShare() {
      // this.$f7.dialog.alert("wechat share","")
      Wechat.share({
        message:{
          title: this.form.project_name,
          description: this.form.req_desc,
          thumb: "www/static/icons/favicon.png",
          media: {
            type: Wechat.Type.WEBPAGE,
            webpageUrl: "https://mobile.newways.cloud/#!/detail_project_open/"+this.form.id+"/"
          }
        },
        scene: Wechat.Scene.SESSION
      }, function () {
        // alert("Success");
      }, function (reason) {
        alert("Failed: " + reason);
      });
    },
    write2clipboard_wechat() {
      if (!Device.cordova) {
        navigator.clipboard.writeText(this.form.wechat_no).catch((e) => {
          console.error(e)
        })
      } else {
        cordova.plugins.clipboard.copy(this.form.wechat_no);
      }
      var toastSuccess = this.$f7.toast.create({
        text: 'WechatIdをコピーしました',
        position: 'center',
        closeTimeout: 1000,
      })
      toastSuccess.open()
    },
    get_project() {
      const options = {
        params: {
          id: this.project_id,
        }
      };
      axios.get('/api/admin/project_open/get_project', options)
      .then(response => {
        let item = response.data.project
        this.form = item
        // this.form.keys().forEach(key => {
        //   this.form[key] = item[key]
        // })
      })
      .catch(error => {
        if(error.response.status == 422){
          if (error.response.data.errors) {
          } else {
            if (error.response.data.message) {
              swal({
                icon: "warning",
                text: error.response.data.message,
              })
            }
          }
        } else {
          swal({
            icon: "error",
            text: error,
          });
        }
      })
    },
  }
}
</script>
<style>
</style>
