<template>
  <f7-page class="grid-demo" ptr :ptr-mousewheel="true" @ptr:refresh="loadMore"
      infinite
      :infinite-distance="50"
      :infinite-preloader="showPreloader"
      @infinite="loadMore">
    <f7-navbar>
      <f7-nav-title>{{ $t('system manage') }}</f7-nav-title>
      <f7-nav-right>
        <f7-link class="searchbar-enable" data-searchbar=".searchbar-system" icon-ios="f7:search" icon-md="material:search" icon-aurora="f7:search"></f7-link>
      </f7-nav-right>
      <f7-searchbar
        class="searchbar-system"
        expandable
        search-container=".search-list-system"
        search-in=".item-header, .item-title, .item-subtitle, .item-text, .item-footer, .item-after"
        :disable-button="!$theme.aurora"
        :disable-button-text="$t('cancel')"
        :placeholder="$t('search')"
        :clear-button="true"
      ></f7-searchbar>
    </f7-navbar>
    <f7-list class="searchbar-not-found">
      <f7-list-item :title="$t('Nothing found')"></f7-list-item>
    </f7-list>

    <f7-block-title>会社アカウント管理</f7-block-title>
    <f7-list accordion-list>
      <f7-list-item accordion-item title="会社印鑑証明未確認" :badge="cert_none_items.length" badge-color="orange">
        <f7-accordion-content>
          <f7-list media-list class="search-list-system searchbar-found">
            <f7-list-item v-for="(item,index) in cert_none_items"
              swipeout
              :link="'/detail_company_account/'+item.id+'/'"
              :header="item.email+' email認証状態:'+item.email_verified"
              :title="item.name"
              :subtitle="item.pref_id==null? item.company_name : item.company_name+' '+item.pref_id"
              :text="item.rule_violation_flag? '違約暫停中 '+item.rule_violation_datefrom : '正常使用中'"
              :footer="'管理者Id:'+item.id+',会社Id:'+item.company_id"
              :after="item.cert_file_json? '会社印鑑証明確認待ち' : '会社印鑑証明無'"
              :key="item.id">
              <f7-swipeout-actions right>
                <f7-swipeout-button @click="more(index,item); $refs.cert_none_items.open()">...</f7-swipeout-button>
                <f7-swipeout-button color="orange" @click="handleRuleViolation(index,item)">違約処理</f7-swipeout-button>
                <f7-swipeout-button color="green"  @click="handleCertPdf(index,item)">印鑑証明管理</f7-swipeout-button>
              </f7-swipeout-actions>
            </f7-list-item>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>
      <f7-list-item accordion-item title="違約暫停中" :badge="rule_violation_items.length" badge-color="red">
        <f7-accordion-content>
          <f7-list media-list class="search-list-system searchbar-found">
            <f7-list-item v-for="(item,index) in rule_violation_items"
              swipeout
              :link="'/detail_company_account/'+item.id+'/'"
              :header="item.email+' email認証状態:'+item.email_verified"
              :title="item.name"
              :subtitle="item.pref_id==null? item.company_name : item.company_name+' '+item.pref_id"
              :text="item.rule_violation_flag? '違約暫停中 '+item.rule_violation_datefrom : '正常使用中'"
              :footer="'管理者Id:'+item.id+',会社Id:'+item.company_id"
              :after="item.cert_flag? '会社印鑑証明OK' : '会社印鑑証明無'"
              :key="item.id">
              <f7-swipeout-actions right>
                <f7-swipeout-button @click="more(index,item); $refs.rule_violation_items.open()">...</f7-swipeout-button>
                <f7-swipeout-button color="orange" @click="handleRuleViolation(index,item)">違約処理</f7-swipeout-button>
                <f7-swipeout-button color="green"  @click="handleCertPdf(index,item)">印鑑証明管理</f7-swipeout-button>
              </f7-swipeout-actions>
            </f7-list-item>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>
      <f7-list-item accordion-item title="会社印鑑証明OK、正常使用中" :badge="normal_items.length" badge-color="green">
        <f7-accordion-content>
          <f7-list media-list class="search-list-system searchbar-found">
            <f7-list-item v-for="(item,index) in normal_items"
              swipeout
              :link="'/detail_company_account/'+item.id+'/'"
              :header="item.email+' email認証状態:'+item.email_verified"
              :title="item.name"
              :subtitle="item.pref_id==null? item.company_name : item.company_name+' '+item.pref_id"
              :text="item.rule_violation_flag? '違約暫停中 '+item.rule_violation_datefrom : '正常使用中'"
              :footer="'管理者Id:'+item.id+',会社Id:'+item.company_id"
              :after="item.cert_flag? '会社印鑑証明OK' : '会社印鑑証明無'"
              :key="item.id">
              <f7-swipeout-actions right>
                <f7-swipeout-button @click="more(index,item); $refs.normal_items.open()">...</f7-swipeout-button>
                <f7-swipeout-button color="orange" @click="handleRuleViolation(index,item)">違約処理</f7-swipeout-button>
                <f7-swipeout-button color="green"  @click="handleCertPdf(index,item)">印鑑証明管理</f7-swipeout-button>
              </f7-swipeout-actions>
            </f7-list-item>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>
    </f7-list>

    <f7-actions ref="cert_none_items" id="cert_none_items">
      <f7-actions-group>
        <f7-actions-button color="red" @click="handleDelete(1)">
            アカウント削除
        </f7-actions-button>
        <f7-actions-button>
            {{ $t('cancel') }}
        </f7-actions-button>
      </f7-actions-group>
    </f7-actions>
    <f7-actions ref="rule_violation_items" id="rule_violation_items">
      <f7-actions-group>
        <f7-actions-button color="red" @click="handleDelete(2)">
            アカウント削除
        </f7-actions-button>
        <f7-actions-button>
            {{ $t('cancel') }}
        </f7-actions-button>
      </f7-actions-group>
    </f7-actions>
    <f7-actions ref="normal_items" id="normal_items">
      <f7-actions-group>
        <f7-actions-button color="red" @click="handleDelete(3)">
            アカウント削除
        </f7-actions-button>
        <f7-actions-button>
            {{ $t('cancel') }}
        </f7-actions-button>
      </f7-actions-group>
    </f7-actions>

    <f7-list>
      <f7-list-item
        swipeout
        link="/contact_update/"
        :title="$t('contact info setting')">
      </f7-list-item>
    </f7-list>

    <f7-list>
      <f7-list-item
        swipeout
        link="/version_manage/"
        :title="$t('newest version setting')">
      </f7-list-item>
    </f7-list>

    <f7-list accordion-list>
      <f7-list-item accordion-item title="通報管理" :badge="complaint_project_items.length" badge-color="red">
        <f7-accordion-content>
          <f7-list media-list class="search-list-system searchbar-found">
            <f7-list-item v-for="(item,index) in complaint_project_items"
              swipeout
              :link="'/detail_complaint_project/'+item.id+'/'+item.project_id+'/'"
              :header="'通報される案件id:'+item.project_id"
              :title="'通報される案件:'+item.project_name"
              :subtitle="'通報される会社: '+item.reported_company_name+' 会社id:'+item.reported_company_id"
              :text="'通報する会社: '+item.report_company_name+' 会社id:'+item.report_company_id+' '+item.report_admin_name"
              :footer="'通報管理id:'+item.id"
              :after="item.updated_at"
              :key="item.id">
            </f7-list-item>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>
    </f7-list>

    <f7-list accordion-list>
      <f7-list-item accordion-item title="スキル管理">
        <f7-accordion-content>
          <f7-list>
            <f7-list-item
              link="/modify_skill_dev_lang/"
              title="開発言語">
            </f7-list-item>
            <f7-list-item
              link="/modify_skill_tool/"
              title="ツール・フレームワーク">
            </f7-list-item>
            <f7-list-item
              link="/modify_skill_db/"
              title="データベース">
            </f7-list-item>
            <f7-list-item
              link="/modify_skill_os/"
              title="OS">
            </f7-list-item>
            <f7-list-item
              link="/modify_skill_other/"
              title="その他">
            </f7-list-item>
            <f7-list-item
              link="/modify_business/"
              title="業務経験">
            </f7-list-item>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>
    </f7-list>

    <f7-list accordion-list>
      <f7-list-item accordion-item :title="$t('notice manage')" :badge="notice_items.length" badge-color="orange">
        <f7-accordion-content>
          <f7-list media-list>
            <f7-list-item v-for="(item,index) in notice_items"
              swipeout
              :link="'/detail_notice_system/'+item.id+'/'"
              :header="'登録日時:'+item.updated_at"
              :title="item.subject"
              :text="item.content"
              :after="item.send_flag? $t('sent') : $t('not sent')"
              :key="item.id">
              <f7-swipeout-actions right>
                <f7-swipeout-button v-if="item.send_flag==false"
                    @click="more_notice(index,item); $refs.actionsOneGroup_notice.open()">
                    ...
                </f7-swipeout-button>
                <f7-swipeout-button v-if="item.send_flag==false"
                    color="green"
                    @click="UpdateNotice(index,item)">
                    {{ $t('edit') }}
                </f7-swipeout-button>
              </f7-swipeout-actions>
            </f7-list-item>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>
      <f7-list-button color="orange" :title="$t('add notice')"
          v-on:click.stop.prevent="AddNotice()">
      </f7-list-button>
    </f7-list>

    <f7-actions ref="actionsOneGroup_notice" id="actionsOneGroup_notice">
      <f7-actions-group>
        <f7-actions-button
            color="orange"
            @click="SendNotice()">
            {{ $t('send') }}
        </f7-actions-button>
        <f7-actions-button
            color="red"
            @click="DeleteNotice()">
            {{ $t('delete') }}
        </f7-actions-button>
        <f7-actions-button>
            {{ $t('cancel') }}
        </f7-actions-button>
      </f7-actions-group>
    </f7-actions>

    <!-- androidバージョン追加管理
    <f7-list accordion-list>
      <f7-list-item accordion-item :title="$t('android version manage')" :badge="apk_version_manage_items.length" badge-color="orange">
        <f7-accordion-content>
          <f7-list media-list>
            <f7-list-item v-for="(item,index) in apk_version_manage_items"
              swipeout
              :header="'登録日時:'+item.updated_at"
              :title="item.version_no"
              :text="item.content"
              :after="item.apk_ServerFileName? 'UPLOAD済': '未UPLOAD'"
              :key="index">
              <f7-swipeout-actions right>
                <f7-swipeout-button
                    color="green"
                    @click="UpdateApkVersion(index,item)">
                    {{ $t('edit') }}
                </f7-swipeout-button>
              </f7-swipeout-actions>
            </f7-list-item>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>
      <f7-list-button color="orange" :title="$t('add')"
          v-on:click.stop.prevent="AddApkVersion()">
      </f7-list-button>
    </f7-list> -->

  </f7-page>
</template>

<script>
import axios from 'axios'
import swal from "sweetalert";
import { mapGetters } from 'vuex'

export default {
  metaInfo() {
    return { title: this.$t('system manage') }
  },
  data() {
    return {
      allowInfinite: true,
      showPreloader: true,
      curr_item: {
        item: null,
        index: null
      },
      cert_none_items: [],
      rule_violation_items: [],
      normal_items: [],
      pagination: {
        per_page: 50,    // 毎頁件数 固定
        pager_count: 3,  // 表示頁数 固定
        current_page: 1, // カレント頁 変わる
        total: 0,        // 総件数
      },
      complaint_project_items: [],
      notice_items: [],
      curr_notice_item: {
        item: null,
        index: null
      },
      // apk_version_manage_items: [],
    }
  },
  mounted() {
    this.init()
    this.loadMore()
  },
  computed: mapGetters({
    added_admin_id: 'pushed/added_admin_id',
    added_complaint_id: 'pushed/added_complaint_id',
  }),
  watch: {
    added_admin_id: function (newVal, oldVal) {
      this.get_company_account_list(newVal)
    },
    added_complaint_id: function (newVal, oldVal) {
      this.get_complaint_list_mobile(newVal)
    },
  },
  methods: {
    init() {
      this.pagination.current_page = 0
      this.pagination.total = 0
      this.cert_none_items = [],
      this.rule_violation_items = []
      this.normal_items = []
      this.curr_item.item = null
      this.curr_item.index = null
      this.allowInfinite = true
      this.showPreloader = true
      this.complaint_project_items = []
      this.notice_items = []
      // this.apk_version_manage_items = []
    },
    loadMore(done) {
      if (done) {
        // console.log("!!!pull refresh!!!")
        this.init()
        this.showPreloader = false
      }
      // const self = this;
      if (!this.allowInfinite) return;
      this.allowInfinite = false;
      setTimeout(() => {
        this.pagination.current_page = this.pagination.current_page + 1
        this.get_company_account_list(null)
        this.get_complaint_list_mobile(null)
        this.get_notice_list_mobile()
        // this.get_newest_5_apk_version()
        this.allowInfinite = true;
        if (done) {
          done()
        }
      }, 1000);
    },
    async get_company_account_list(admin_id) {
      const options = {
        params: {
          paginate: this.pagination.per_page,
          page: this.pagination.current_page,
          pushed_admin_id: admin_id
        }
      };
      await axios.get('/api/system/get_company_account_list', options)
      .then(response => {
        if (!admin_id) {
          let data = response.data.list.data
          this.pagination.total = response.data.list.total
          this.pagination.current_page = response.data.list.current_page
          for (var i = 0; i < data.length; i++) {
            var item = data[i]
            if (item.cert_flag==0 || item.cert_flag==null) {
              this.cert_none_items.push(item)
            } else if (item.rule_violation_flag==1) {
              this.rule_violation_items.push(item)
            } else {
              this.normal_items.push(item)
            }
          }
          if (this.cert_none_items.length+this.rule_violation_items.length+this.normal_items.length >= this.pagination.total) {
            this.showPreloader = false
            this.allowInfinite = false
          }
        } else {
          let data = response.data.list
          for (var i = 0; i < data.length; i++) {
            var item = data[i]
            if (item.cert_flag==0 || item.cert_flag==null) {
              this.cert_none_items.unshift(item)
            } else if (data.rule_violation_flag==1) {
              this.rule_violation_items.unshift(item)
            } else {
              this.normal_items.unshift(item)
            }
          }
        }
      })
      .catch(function (error) {
        swal({
          icon: "error",
          text: error,
        })
      })
    },
    async get_complaint_list_mobile(complaint_id) {
      const options = {
        params: {
          pushed_complaint_id: complaint_id
        }
      }
      await axios.get('/api/system/get_complaint_list_mobile', options)
      .then(response => {
        let data = response.data.list
        if (!complaint_id) {
          this.complaint_project_items.push(...data)
        } else {
          this.complaint_project_items.unshift(...data)
        }
      })
      .catch(function (error) {
        swal({
          icon: "error",
          text: error,
        })
      })
    },
    // async get_newest_5_apk_version() {
    //   await axios.get('/api/get_newest_5_apk_version')
    //   .then(response => {
    //     let data = response.data.apk_version_manage_items
    //       this.apk_version_manage_items.push(...data)
    //   })
    //   .catch(function (error) {
    //     swal({
    //       icon: "error",
    //       text: error,
    //     })
    //   })
    // },
    async get_notice_list_mobile() {
      await axios.get('/api/system/get_notice_list_mobile')
      .then(response => {
        let data = response.data.list
          this.notice_items = []
          this.notice_items.push(...data)
      })
      .catch(function (error) {
        swal({
          icon: "error",
          text: error,
        })
      })
    },
    more_notice(index, item) {
      this.curr_notice_item.item = item
      this.curr_notice_item.index = index
    },
    more(index, item) {
      this.curr_item.item = item
      this.curr_item.index = index
    },
    handleDelete(items) {
      let url = '/api/system/delete_company_account?id=' + this.curr_item.item.id
      swal({
        title: this.$t('confirm delete'),
        dangerMode: true,
        buttons: [this.$t('cancel'), this.$t('delete')],
      }).then((value) => {
        if (value) {
          axios.get(url)
          .then(response => {
            if (items==1) {
              this.cert_none_items.splice(this.curr_item.index, 1)
            }
            if (items==2) {
              this.rule_violation_items.splice(this.curr_item.index, 1)
            }
            if (items==3) {
              this.normal_items.splice(this.curr_item.index, 1)
            }
            this.curr_item.item = null
            this.curr_item.index = null
          })
          .catch(error => {
            if (error.response.status == 422) {
              if (error.response.data.message){
                swal({
                  icon: "warning",
                  text: error.response.data.message,
                });
              }
            } else {
              swal({
                icon: "error",
                text: error,
              })
            }
          })
        }
      })
    },
    handleCertPdf(index,item) {
      // this.$f7router.navigate("/update_engineer/", {props: { add_update_mode: '2', current_row: item}})
      let options = { id: item.id, company_id: item.company_id, company_name: item.company_name, cert_flag: item.cert_flag, certpdf_downloadFileName: item.certpdf_downloadFileName }
      this.$f7router.navigate('/company_cert_pdf/' + JSON.stringify(options))
    },
    handleRuleViolation(index,item) {
      // this.$f7router.navigate("/update_engineer/", {props: { add_update_mode: '2', current_row: item}})
      let options = { id: item.id, company_id: item.company_id, company_name: item.company_name, rule_violation_flag: item.rule_violation_flag }
      this.$f7router.navigate('/company_rule_violation/' + JSON.stringify(options))
    },
    UpdateApkVersion(index,item) {
      let options = { add_update_mode: '2', apk_version_id: item.id }
      this.$f7router.navigate('/update_apk_version/' + JSON.stringify(options))
    },
    AddApkVersion() {
      let options = { add_update_mode: '1' }
      this.$f7router.navigate('/add_apk_version/' + JSON.stringify(options))
    },
    UpdateNotice(index,item) {
      let options = { add_update_mode: '2', notice_id: item.id }
      this.$f7router.navigate('/update_notice/' + JSON.stringify(options))
    },
    AddNotice() {
      let options = { add_update_mode: '1' }
      this.$f7router.navigate('/add_notice/' + JSON.stringify(options))
    },
    SendNotice() {
      let url = '/api/system/send_notice_mobile?id=' + this.curr_notice_item.item.id
      swal({
        title: this.$t('confirm send'),
        dangerMode: true,
        buttons: [this.$t('cancel'), this.$t('send')],
      }).then((value) => {
        if (value) {
          axios.get(url)
          .then(response => {
            //this.notice_items.splice(this.curr_notice_item.index, 1)
            this.notice_items[this.curr_notice_item.index].send_flag = true
            this.curr_notice_item.item = null
            this.curr_notice_item.index = null
            var self = this
            var toastSuccess = self.$f7.toast.create({
                text: this.$t('send success'),
                position: 'center',
                closeTimeout: 2000,
            });
            toastSuccess.open()
          })
          .catch(error => {
            if (error.response.status == 422) {
              if (error.response.data.message){
                swal({
                  icon: "warning",
                  text: error.response.data.message,
                })
              }
            } else {
              swal({
                icon: "error",
                text: error,
              })
            }
          })
        }
      })
    },
    DeleteNotice() {
      let url = '/api/system/delete_notice_mobile?id=' + this.curr_notice_item.item.id
      swal({
        title: this.$t('confirm delete'),
        dangerMode: true,
        buttons: [this.$t('cancel'), this.$t('delete')],
      }).then((value) => {
        if (value) {
          axios.get(url)
          .then(response => {
            this.notice_items.splice(this.curr_notice_item.index, 1)
            this.curr_notice_item.item = null
            this.curr_notice_item.index = null
          })
          .catch(error => {
            if (error.response.status == 422) {
              if (error.response.data.message){
                swal({
                  icon: "warning",
                  text: error.response.data.message,
                });
              }
            } else {
              swal({
                icon: "error",
                text: error,
              })
            }
          })
        }
      })
    },
  }
}
</script>
