<template>
  <f7-page class="grid-demo"
      infinite
      :infinite-distance="50"
      :infinite-preloader="showPreloader"
      @infinite="loadMore">
    <f7-navbar>
      <f7-nav-left>
        <f7-link popover-open=".popover-menu-project">
          <f7-icon ios="f7:bars" aurora="f7:bars" md="material:menu"></f7-icon>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>{{ $t('project') }}</f7-nav-title>
      <f7-nav-right>
        <f7-link class="searchbar-enable" data-searchbar=".searchbar-project-open" icon-ios="f7:search" icon-md="material:search" icon-aurora="f7:search"></f7-link>
      </f7-nav-right>
      <f7-searchbar
        class="searchbar-project-open"
        expandable
        search-container=".search-list-project-open"
        search-in=".item-header, .item-title, .item-subtitle, .item-text, .item-footer"
        :disable-button="!$theme.aurora"
        :disable-button-text="$t('cancel')"
        :placeholder="$t('search')"
        :clear-button="true"
      ></f7-searchbar>
      <f7-subnavbar>
        <f7-segmented raised>
          <f7-button outline small v-on:click.stop.prevent="search">{{ $t('search') }}</f7-button>
          <MonthSwitch/>
          <SortSwitch/>
        </f7-segmented>
      </f7-subnavbar>
    </f7-navbar>
    <f7-list class="searchbar-not-found">
      <f7-list-item :title="$t('Nothing found')"></f7-list-item>
    </f7-list>

    <f7-list media-list class="search-list-project-open searchbar-found">
      <f7-list-item v-for="(item,index) in items"
        swipeout
        :link="'/detail_project_open/'+item.id+'/'"
        :header="item.updated_at"
        :title="item.project_name"
        :subtitle="item.skill"
        :text="item.req_desc"
        :footer="item.company_name+' 管理No:'+item.id"
        :after="item.available_day"
        :key="index">
        <f7-swipeout-actions left>
          <f7-swipeout-button
            color="orange"
            v-on:click.stop.prevent="handleComplaint(index,item)">{{ $t('complaint') }}
          </f7-swipeout-button>
        </f7-swipeout-actions>
        <f7-swipeout-actions right>
          <f7-swipeout-button
            color="green"
            v-on:click.stop.prevent="handleFavorites(index,item)">{{ $t('favorites') }}
          </f7-swipeout-button>
          <f7-swipeout-button v-if="user && user_role_id==1"
            color="red"
            v-on:click.stop.prevent="handleDelete(index,item)">{{ $t('delete') }}
          </f7-swipeout-button>
        </f7-swipeout-actions>
      </f7-list-item>
    </f7-list>

    <!-- top left menu  -->
    <f7-popover ref="popoverMenuProject" class="popover-menu-project">
      <f7-list>
        <f7-list-item v-on:click.stop.prevent="QueryCondition()" :title="$t('query condition setting')"></f7-list-item>
      </f7-list>
    </f7-popover>

    <f7-card v-if="company.cert_flag==0">
      <f7-card-content>
        <p>会社印鑑証明書は未確認のため、案件情報は20件まで表示します</p>
      </f7-card-content>
    </f7-card>

  </f7-page>
</template>

<script>
import axios from 'axios'
import swal from "sweetalert"
import { mapGetters } from 'vuex'
import MonthSwitch from '~/js/components/MonthSwitch_project_open'
import SortSwitch from '~/js/components/SortSwitch_project_open';

export default {
  components: {
    MonthSwitch,
    SortSwitch,
  },
  metaInfo() {
    return { title: this.$t('project') }
  },
  data() {
    return {
      allowInfinite: true,
      showPreloader: true,
      curr_item: {
        item: null,
        index: null
      },
      items: [],
      pagination: {
        per_page: 20,    // 每页件数 固定
        pager_count: 3,  // 显示页数 固定
        current_page: 1, // 当前页 変わる
        total: 0,        // 总条目数
      },
      current_month: {
        value: '0',
        label: '',
      },
      sort_current: {
        value: '1',
        label: '登録時間 ↑',
      },
      user: null,
      user_role_id: null,
      company: null,
    }
  },
  created() {
    this.user = this.$store.state.auth.user
    this.company = this.user.company
    this.user_role_id = this.$store.state.auth.role_id
  },
  mounted() {
    const project_open_sort = this.$store.state.sortSwitch.project_open_sort
    if (project_open_sort) {
      this.sort_current = project_open_sort
    }
    const current_month_stored = this.$store.state.monthSwitch.current_month_project_open
    if (current_month_stored) {
      this.current_month = current_month_stored
    }
    this.init()
    this.loadMore()
  },
  destoryed(){
    window.clearTimeout(this.$options.refreshTimeout)
    this.$options.refreshTimeout = null;
  },
  computed: mapGetters({
    current_month_stored: 'monthSwitch/current_month_project_open',
    project_open_sort: 'sortSwitch/project_open_sort',
    added_project_id: 'pushed/added_project_id',
    updated_company: 'pushed/updated_company',
  }),
  watch: {
    // 如果 `current_month` 发生改变，这个函数就会运行
    current_month_stored: function (newCurrentMonth, oldCurrentMonth) {
      this.current_month = newCurrentMonth
      this.init()
      this.loadMore()
    },
    project_open_sort: function (newVal, oldVal) {
      this.sort_current = newVal
      this.init()
      this.loadMore()
    },
    added_project_id: function (newVal, oldVal) {
      if (this.company.cert_flag == 0) { return }
      this.getProjectList(newVal)
    },
    updated_company: function (newVal, oldVal) {
      this.company = newVal
    },
  },
  methods: {
    init() {
      this.pagination.current_page = 0
      this.pagination.total = 0
      this.items = []
      this.curr_item.item = null
      this.curr_item.index = null
      this.allowInfinite = true
      this.showPreloader = true
      // this.clearTimer()
    },
    // clearTimer() {
    //   if (this.$options.refreshTimeout) {
    //     window.clearTimeout(this.$options.refreshTimeout)
    //     this.$options.refreshTimeout = null
    //   }
    // },
    loadMore() {
      const self = this;
      if (!self.allowInfinite) return;
      self.allowInfinite = false;
      setTimeout(() => {
        // 最大2000件まで
        if (self.items != null) {
          if (self.items.length >= 2000) {
            self.showPreloader = false;
            self.allowInfinite = false;
            return;
          }
        }
        this.pagination.current_page = this.pagination.current_page + 1
        this.getProjectList(null)
        self.allowInfinite = true
      }, 1000);
    },
    search() {
      this.init()
      this.loadMore()
    },
    getProjectList(project_id) {
      const options = {
        params: {
          paginate: this.pagination.per_page,
          page: this.pagination.current_page,
          current_month: this.current_month.value,
          current_sort: this.sort_current.value,
          pushed_project_id: project_id
        }
      }
      axios.get('/api/admin/project_open/get_project_list_open', options)
      .then(response => {
        if (!project_id) {
          let data = response.data.list.data
          this.pagination.total = response.data.list.total
          this.pagination.current_page = response.data.list.current_page
          this.items.push(...data)
          // console.log(this.pagination.current_page)
          // console.log(this.pagination.total)
          // console.log(this.items.length)
          if (this.items.length >= this.pagination.total) {
            this.showPreloader = false
            this.allowInfinite = false
          }
          if (this.company.cert_flag == 0) {
            this.allowInfinite = false
            this.showPreloader = false
          }
        } else {
          let data = response.data.list
          if (this.items.length > 3000) {
            this.items.shift()
          }
          this.items.push(...data)
        }
        // this.$options.refreshTimeout = setTimeout(()=>{
        //   this.loadMore()
        // }, 3000)
      })
      .catch(function (error) {
        swal({
          icon: "error",
          text: error,
        })
      })
    },
    more(index, item) {
      // const self = this;
      // self.actions.open();
      this.curr_item.item = item
      this.curr_item.index = index
    },
    QueryCondition() {
      this.$refs.popoverMenuProject.close()
      this.$f7router.navigate('/project_query_condition/')
    },
    handleComplaint(index,item) {
      let options = { project_id: item.id }
      this.$f7router.navigate('/complaint_project/' + JSON.stringify(options))
    },
    handleFavorites(index,item) {
      axios.get('/api/admin/project_open/project_favorites?id=' + item.id)
      .then(response => {
        let added_favorites_id = response.data.added_favorites_id
        if (added_favorites_id) {
          this.$store.dispatch('transfered/add_favorites_id', { added_favorites_id: added_favorites_id })
        }
        let messageText = this.$t('add success')
        var self = this
        var toastSuccess = self.$f7.toast.create({
          text: messageText,
          position: 'center',
          closeTimeout: 2000,
        })
        toastSuccess.open()
      })
      .catch(error => {
        if (error.response.status == 422) {
          if (error.response.data.message){
            swal({
              icon: "warning",
              text: error.response.data.message,
            });
          }
        } else {
          swal({
            icon: "error",
            text: error,
          })
        }
      })
    },
    handleDelete(index,item) {
      let url = ''
      url = '/api/admin/project/delete_project_mobile?id=' + item.id
      swal({
        title: this.$t('confirm delete'),
        dangerMode: true,
        buttons: [this.$t('cancel'), this.$t('delete')],
      }).then((value) => {
        if (value) {
          axios.get(url)
          .then(response => {
            //削除
            this.items.splice(index, 1)
            this.curr_item.item = null
            this.curr_item.index = null
            // this.init()
            // this.loadMore()
          })
          .catch(error => {
            if (error.response.status == 422) {
              if (error.response.data.message){
                swal({
                  icon: "warning",
                  text: error.response.data.message,
                });
              }
            } else {
              swal({
                icon: "error",
                text: error,
              })
            }
          })
        }
      })
    },
  }
}
</script>
<style>
</style>
