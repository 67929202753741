<template>
  <f7-app :params="f7params" color-theme="orange">
    <!-- Left panel with cover effect-->
    <f7-panel left cover theme-dark resizable>
      <f7-view url="/panel-left/"></f7-view>
    </f7-panel>
    <!-- Right panel with reveal effect-->
    <f7-panel right reveal theme-dark resizable>
      <f7-view url="/panel-right/" links-view=".view-main"></f7-view>
    </f7-panel>

    <f7-views tabs class="safe-areas" v-if="user && user_role_id>1">
      <f7-toolbar tabbar labels :position="isBottom ? 'bottom' : 'top'">
        <f7-link tab-link="#project" tab-link-active
            icon-ios="f7:building_2_fill" icon-aurora="f7:building_2_fill" icon-md="material:business"
            :text="$t('project')">
        </f7-link>
        <f7-link tab-link="#engineer"
            icon-ios="f7:person_2_fill" icon-aurora="f7:person_2_fill" icon-md="material:person"
            :text="$t('engineer')">
        </f7-link>
        <!-- icon-ios="f7:plus_circle_fill" icon-aurora="f7:plus_circle_fill" icon-md="material:add_circle" -->
        <f7-link tab-link="#manage"
            icon-ios="f7:arrow_up_arrow_down_circle_fill" icon-aurora="f7:arrow_up_arrow_down_circle_fill" icon-md="material:swap_vertical_circle"
            :text="$t('manage')">
        </f7-link>
        <f7-link tab-link="#company"
            icon-ios="f7:house_fill" icon-aurora="f7:house_fill" icon-md="material:home"
            :text="$t('own company')">
        </f7-link>
      </f7-toolbar>
      <f7-view id="project" tab main tab-active
          url="/projects_open/">
      </f7-view>
      <f7-view id="engineer" tab
          url="/engineers_open/">
      </f7-view>
      <f7-view id="manage" tab
          url="/manage/">
      </f7-view>
      <f7-view id="company" tab
          url="/company_setting/">
      </f7-view>
      <!-- <f7-tabs animated>
        <f7-view id="project" tab main tab-active
            url="/projects_open/"
            :animate="false"
            :ios-dynamic-navbar="false"
            :push-state="false">
        </f7-view>
        <f7-view id="engineer" tab
            url="/engineers_open/"
            :animate="false"
            :ios-dynamic-navbar="false"
            :push-state="false">
        </f7-view>
        <f7-view id="manage" tab
            url="/manage/"
            :animate="false"
            :ios-dynamic-navbar="false"
            :push-state="false">
        </f7-view>
        <f7-view id="company" tab
            url="/company_setting/"
            :animate="false"
            :ios-dynamic-navbar="false"
            :push-state="false">
        </f7-view>
      </f7-tabs> -->
    </f7-views>

    <f7-view v-if="!user & deviceready">
      <AdminLogin/>
    </f7-view>
    <!-- <f7-view id="main-view" url="/index/" main class="safe-areas"></f7-view> -->

    <f7-views tabs class="safe-areas" v-if="user && user_role_id==1">
      <f7-toolbar tabbar labels :position="isBottom ? 'bottom' : 'top'">
        <f7-link tab-link="#project" tab-link-active
            icon-ios="f7:building_2_fill" icon-aurora="f7:building_2_fill" icon-md="material:business"
            :text="$t('project')">
        </f7-link>
        <f7-link tab-link="#engineer"
            icon-ios="f7:person_2_fill" icon-aurora="f7:person_2_fill" icon-md="material:person"
            :text="$t('engineer')">
        </f7-link>
        <!-- icon-ios="f7:plus_circle_fill" icon-aurora="f7:plus_circle_fill" icon-md="material:add_circle" -->
        <f7-link tab-link="#manage"
            icon-ios="f7:arrow_up_arrow_down_circle_fill" icon-aurora="f7:arrow_up_arrow_down_circle_fill" icon-md="material:swap_vertical_circle"
            :text="$t('manage')">
        </f7-link>
        <f7-link tab-link="#company"
            icon-ios="f7:house_fill" icon-aurora="f7:house_fill" icon-md="material:home"
            :text="$t('own company')">
        </f7-link>
        <f7-link tab-link="#system"
            icon-ios="f7:device_desktop" icon-aurora="f7:device_desktop" icon-md="material:desktop_mac"
            :text="$t('system manage')">
        </f7-link>
      </f7-toolbar>
      <f7-view id="project" tab main tab-active
          url="/projects_open/">
      </f7-view>
      <f7-view id="engineer" tab
          url="/engineers_open/">
      </f7-view>
      <f7-view id="manage" tab
          url="/manage/">
      </f7-view>
      <f7-view id="company" tab
          url="/company_setting/">
      </f7-view>
      <f7-view id="system" tab
          url="/system/">
      </f7-view>
    </f7-views>

  </f7-app>
</template>
<script>
import { Device }  from 'framework7/framework7-lite.esm.bundle.js'
import cordovaApp from '~/js/cordova-app.js'
import { mapGetters } from 'vuex'
// import routes from '../js/routes.js';
import routes from '~/js/router/routes.js'
import AdminLogin from '~/js/pages/auth/AdminLogin.vue'
import axios from 'axios'
import swal from "sweetalert"

export default {
  components: {
    AdminLogin
  },
  data() {
    return {
      deviceready: false,
      isBottom: true,
      // Framework7 Parameters
      f7params: {
        id: 'cloud.newways.ios', // App bundle ID
        name: 'newProject', // App name
        theme: 'auto', // Automatic theme detection
        version: 'v1.1.0',

        // App root data
        data: function () {
          return {
            // Demo products for Catalog section
            products: [
              {
                id: '1',
                title: 'Apple iPhone 8',
                description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisi tempora similique reiciendis, error nesciunt vero, blanditiis pariatur dolor, minima sed sapiente rerum, dolorem corrupti hic modi praesentium unde saepe perspiciatis.'
              },
              {
                id: '2',
                title: 'Apple iPhone 8 Plus',
                description: 'Velit odit autem modi saepe ratione totam minus, aperiam, labore quia provident temporibus quasi est ut aliquid blanditiis beatae suscipit odio vel! Nostrum porro sunt sint eveniet maiores, dolorem itaque!'
              },
              {
                id: '3',
                title: 'Apple iPhone X',
                description: 'Expedita sequi perferendis quod illum pariatur aliquam, alias laboriosam! Vero blanditiis placeat, mollitia necessitatibus reprehenderit. Labore dolores amet quos, accusamus earum asperiores officiis assumenda optio architecto quia neque, quae eum.'
              },
            ]
          };
        },

        // App routes
        routes: routes,

        // Register service worker
        serviceWorker: Device.cordova ? {} : {
          path: '/service-worker.js',
        },
        // Input settings
        input: {
          scrollIntoViewOnFocus: Device.cordova && !Device.electron,
          scrollIntoViewCentered: Device.cordova && !Device.electron,
        },
        // Cordova Statusbar settings
        statusbar: {
          iosOverlaysWebView: true,
          androidOverlaysWebView: false,
          // iosTextColor: 'black',
          // androidTextColor: 'black',
        },
        popup: {
          closeOnEscape: true,
        },
        sheet: {
          closeOnEscape: true,
        },
        popover: {
          closeOnEscape: true,
        },
        actions: {
          closeOnEscape: true,
        },
      },
    }
  },
  computed: mapGetters({
    user: 'auth/user',
    user_role_id: 'auth/role_id'
  }),
  metaInfo () {
    //const { appName } = window.config
    return {
      //title: appName,
      //titleTemplate: `%s · ${appName}`
      title: 'newProject'
    }
  },
  // created() {
  //   // const current_month = this.$store.state.monthManage.current_month;
  //   if (this.$store.getters['auth/check']) {
  //     this.user = this.$store.getters['auth/user']
  //     this.user_role_id = this.$store.getters['auth/role_id']
  //   }
  // },
  mounted() {
    this.$f7ready((f7) => {
      // Init cordova APIs (see cordova-app.js)
      if (Device.cordova) {
        cordovaApp.init(f7);
        this.deviceready = true
        // document.addEventListener("offline", this.onOffline, false);
        if (cordova.platformId == 'ios' || cordova.platformId == 'android') {
          // window.setTimeout(() => {
            document.addEventListener('deviceready', function() {
              document.addEventListener("offline", function() {
                //console.log("offlineofflineofflineofflineofflineoffline")
                // alert("インターネット接続不能");
                f7.dialog.alert("インターネット接続不能");
              }, false);
              if (cordova.platformId == 'ios') {
                document.addEventListener("online", function() {
                  //console.log("onlineonlineonlineonlineonlineonlineonline")
                  var toastOnline = f7.toast.create({
                    text: 'インターネット接続正常',
                    position: 'center',
                    closeTimeout: 1000,
                  })
                  toastOnline.open()
                }, false)
              };
              universalLinks.subscribe('launchedAppFromLink', function(eventData) {
                console.log('Did launch app from the link: ' + eventData.url)
              });
            }, false)

            // https://docs.monaca.io/ja/tutorials/firebase/
            // https://developers.connectycube.com/cordova/push-notifications
            // https://www.npmjs.com/package/cordova-plugin-firebasex
            // https://qiita.com/uhooi/items/1134b0f784028412cb83
            // https://r17n.page/2020/01/21/vue-cordova-firebase-push-notification/#cordova-plugin-firebasex-%E3%81%AE%E8%BF%BD%E5%8A%A0
            // https://github.com/dpa99c/cordova-plugin-firebasex/issues/326
            // https://kimagureneet.hatenablog.com/entry/2017/12/21/164732
            // https://cordova.apache.org/announcements/2020/08/04/cordova-cli-release-10.0.0.html
            // https://cordova.apache.org/docs/en/10.x/guide/platforms/android/index.html
            // /usr/libexec/java_home -V | grep jdk
            // https://stackoverflow.com/questions/64968851/could-not-find-tools-jar-please-check-that-library-internet-plug-ins-javaapple
            if (cordova.platformId == 'ios') {
              FirebasePlugin.grantPermission(hasPermission => {
                console.log("PushNotifications Permission was " + (hasPermission ? "granted" : "denied"))
              });
              // ios device token
              FirebasePlugin.onApnsTokenReceived(apnsToken => {
                if (apnsToken) {
                  this.sendRegDetails(apnsToken, cordova.platformId)
                }
                }, error => {
                  console.error(error)
              })
              // Get notified when the user opens a notification
              FirebasePlugin.onMessageReceived(message => {
                // if (message.messageType === "notification") {
                if (message.messageType === "data") {
                  // if(message.tap){
                  //   console.log("Tapped in " + message.tap);
                  // }
                  var data_type = message.data.data_type
                  if (typeof data_type !== 'undefined') {
                    this.store_pushed_data(message.data.data_type,message.data.data_content_1,message.data.data_content_2)
                  }
                }
                }, error => {
                  console.error(error)
              })
            };
            if (cordova.platformId == 'android') {
              FirebasePlugin.getToken(token => {
                // save this server-side and use it to push notifications to this device
                if (token) {
                  this.sendRegDetails(token, cordova.platformId)
                }
                }, error => {
                  console.error(error)
              });
              // Get notified when a token is refreshed
              FirebasePlugin.onTokenRefresh(token => {
                // save this server-side and use it to push notifications to this device
                if (token) {
                  this.sendRegDetails(token, cordova.platformId)
                }
                }, error => {
                  console.error(error)
              });
              // Get notified when the user opens a notification
              FirebasePlugin.onMessageReceived(message => {
                // if (message.messageType === "notification") {
                if (message.messageType === "data") {
                  // if(message.tap){
                  //   console.log("Tapped in " + message.tap);
                  // }
                  var data_type = message.data_type
                  if (typeof data_type !== 'undefined') {
                    this.store_pushed_data(message.data_type,message.data_content_1,message.data_content_2)
                  }
                }
                }, error => {
                  console.error(error)
              });
            };
            // // cordova plugin add cordova-plugin-apns-push
            // if (cordova.platformId == 'ios') {
            //   // cordova plugin add cordova-plugin-apns-push
            //   var push = window['APNSPushNotification'].init({
            //       'ios': {
            //           'sound': 'false',
            //           'alert': 'false',
            //           'badge': 'false',
            //           "clearBadge": "false",
            //           "content-available": "true"
            //       },
            //   })
            //   push.on('registration', (data) => {
            //       // Device ID is available in data.registrationId
            //       // Pass the registration to your backend,
            //       // so that it knows where to send notifications.
            //       console.log('Push notifications registration')
            //       console.log(data);
            //       console.log(data.registrationId)
            //       this.sendRegDetails(data.registrationId, cordova.platformId)
            //   })
            //   // push.on('registration', this.sendRegDetails(data))
            //   push.on('notification', (data) => {
            //       // This callback is triggered when a notification arrives.
            //       // All custom keys are available in data.additionalData.
            //       console.log('Notification received. my notification is:')
            //       console.log(data);
            //       // data.message,
            //       // data.title,
            //       // data.count,
            //       // data.sound,
            //       // data.image,
            //       // data.additionalData
            //       if (data.additionalData.data.data_type == "project") {
            //         // add project_id.
            //         this.$store.dispatch('pushed/addProject', {
            //           added_project_id: data.additionalData.data.data_content
            //         })
            //       }
            //       // call finish to let the OS know we are done
            //       push.finish(function() {
            //         console.log("processing of push data is finished");
            //     	}, function() {
            //         console.log("something went wrong with push.finish for ID = " + data.additionalData.data.data_content)
            //       }, data.additionalData.data.data_content)
            //   })
            //   push.on('error', (e) => {
            //       // Triggered when an error occures.
            //       console.error('Push notifications error', e);
            //   })
            // }
          // }, 1000)
          if (cordova.platformId == 'android') {
              // StatusBar.overlaysWebView(true);
              // StatusBar.styleDefault();
              //StatusBar.backgroundColorByHexString('#fff');
              StatusBar.overlaysWebView(false);
              StatusBar.backgroundColorByHexString('#33000000');
          }
          // if (cordova.platformId == 'ios') {
          //   StatusBar.overlaysWebView(true);
          //   StatusBar.styleBlackOpaque();
          //   StatusBar.backgroundColorByHexString('#000000');
          // }
        }
      } else {
        this.deviceready = true
      }
      // Call F7 APIs here
    });
    // window.setTimeout(() => {
    //   if (typeof cordova === 'undefined') {
    //     console.log('on PC')
    //     this.deviceready = true
    //   } else {
    //     console.log('on スマートフォン')
    //     document.addEventListener('deviceready', this.onDeviceReady, false)
    //   }
    // }, 100);
    // this.sendRegDetails("4a862a9f7a67dd0e007d3bcc84629feb5e8f9639702f7fcfb9e0c2d71140dabe", 'ios')
    // this.connectChannel_project()
    // this.connectChannel_notice()
    // this.connectChannel_engineer()
    // this.connectChannel_company()
    // this.connectChannel_company_upd()
    // this.connectChannel_complaint()
    // this.connectChannel_version_up()
  },
  methods: {
    sendRegDetails(device_id, platform_id) {
      // const options = {
      //   params: {
      //     device_id: device_id,
      //     platform_id: platform_id
      //   }
      // }
      // let params = new URLSearchParams();
      // let formData = new FormData()
      // formData.append('device_id', device_id)
      // formData.append('platform_id', platform_id)
      const payload = {
          device_id: device_id,
          platform_id: platform_id
      }
      axios.post('/api/notice/add_mobile_tok', payload)
      .then(response => {
      })
      .catch(error => {
          swal({
            icon: "error",
            text: error,
          })
      })
    },
    onDeviceReady() {
      this.deviceready = true
    },
    store_pushed_data(data_type, data_content_1, data_content_2) {
      if (data_type == "notice") {
        this.$store.dispatch('pushed/addNotice', {
          added_notice_id: data_content_1
        })
      }
      if (data_type == "project") {
        this.$store.dispatch('pushed/addProject', {
          added_project_id: data_content_1
        })
      }
      if (data_type == "engineer") {
        this.$store.dispatch('pushed/addEngineer', {
          added_engineer_id: data_content_1
        })
      }
      if (data_type == "add_company") {
        // add company_id.
        this.$store.dispatch('pushed/addCompany', {
          added_admin_id: data_content_1,
          added_company_id: data_content_2
        })
      }
      if (data_type == "upd_company") {
        // system管理者よりcompanyのcert_flagとrule_violation_flagを更新時、情報を随時に
        let meuser = this.$store.state.auth.user
        if (meuser.company_id == data_content_1.id) {
          this.$store.dispatch('pushed/updateCompany', {
            updated_company: data_content_1
          })
        }
      }
      if (data_type == "complaint") {
        this.$store.dispatch('pushed/addComplaint', {
          added_complaint_id: data_content_1
        })
      }
      if (data_type == "version") {
        // system管理者よりVersionを更新時
        this.$store.dispatch('pushed/versionUp', {
          app_version_newest: data_content_1
        })
      }
    },
    // connectChannel_notice() {
    //   Echo.channel("notice-channel").listen("NoticeAdded", e => {
    //     // add notice_id.
    //     this.$store.dispatch('pushed/addNotice', {
    //       added_notice_id: e.notice_id
    //     })
    //   })
    // },
    // connectChannel_project() {
    //   // Echo.private("project-channel").listen("ProjectAdded", e => {
    //   Echo.channel("project-channel").listen("ProjectAdded", e => {
    //     // add project_id.
    //     this.$store.dispatch('pushed/addProject', {
    //       added_project_id: e.project_id
    //     })
    //   })
    // },
    // connectChannel_engineer() {
    //   Echo.channel("engineer-channel").listen("EngineerAdded", e => {
    //     // add engineer_id.
    //     this.$store.dispatch('pushed/addEngineer', {
    //       added_engineer_id: e.engineer_id
    //     })
    //   })
    // },
    // connectChannel_company() {
    //   Echo.channel("company-channel").listen("CompanyAdded", e => {
    //     // add company_id.
    //     this.$store.dispatch('pushed/addCompany', {
    //       added_admin_id: e.admin_id,
    //       added_company_id: e.company_id
    //     })
    //   })
    // },
    // connectChannel_company_upd() {
    //   Echo.channel("company-upd-channel").listen("CompanyUpdated", e => {
    //     // system管理者よりcompanyのcert_flagとrule_violation_flagを更新時、情報を随時に
    //     let meuser = this.$store.state.auth.user
    //     if (meuser.company_id == e.company.id) {
    //       this.$store.dispatch('pushed/updateCompany', {
    //         updated_company: e.company
    //       })
    //     }
    //   })
    // },
    // connectChannel_complaint() {
    //   Echo.channel("complaint-channel").listen("ComplaintAdded", e => {
    //     this.$store.dispatch('pushed/addComplaint', {
    //       added_complaint_id: e.complaint_id
    //     })
    //   })
    // },
    // connectChannel_version_up() {
    //   Echo.channel("version-up-channel").listen("VersionUp", e => {
    //     // system管理者よりVersionを更新時
    //     this.$store.dispatch('pushed/versionUp', {
    //       app_version_newest: e.app_version_newest
    //     })
    //   })
    // },
  }
}
</script>
