<template>
  <f7-page name="home">
    <!-- <f7-navbar>
      <f7-nav-left>
        <f7-link icon-if-ios="f7:menu" icon-if-md="material:menu" panel-open="left"></f7-link>
      </f7-nav-left>
      <f7-nav-title>newProject</f7-nav-title>
      <f7-nav-right>
        <f7-link icon-if-ios="f7:menu" icon-if-md="material:menu" panel-open="right"></f7-link>
      </f7-nav-right>
    </f7-navbar> -->

    <!-- <MobileTabHome/> -->

    <!-- Page content-->
    <MobileTabHome v-if="user" />

    <!-- Login Screen -->
    <!-- <AdminLogin v-else /> -->
    <!-- <f7-view v-show="!user"> -->

    <f7-view v-else>
      <AdminLogin/>
    </f7-view>

  </f7-page>
</template>

<script>
  // import store from '~/js/store'
  import { mapGetters } from 'vuex'
  import AdminLogin from '~/js/pages/auth/AdminLogin.vue';
  import MobileTabHome from '~/js/pages/mobile_tab_home.vue'
  export default {
    name: 'index',
    metaInfo () {
      return { title: this.$t('home') }
    },
    components: {
        AdminLogin,
        MobileTabHome
    },
    // data() {
    //   return {
    //     user: null,
    //   };
    // },
    // created() {
    //   // const current_month = this.$store.state.monthManage.current_month;
    //   if (this.$store.getters['auth/check']) {
    //     this.user = this.$store.getters['auth/user']
    //   }
    // },
    computed: mapGetters({
      user: 'auth/user',
      user_role_id: 'auth/role_id'
    }),
  }
</script>
