<template>
  <f7-page>
    <f7-navbar :title="myTitle" :back-link="$t('back')"></f7-navbar>
    <!-- <f7-navbar back-link="<">
      <f7-nav-title>{{ myTitle }}</f7-nav-title>
      <f7-nav-right>
        <f7-link @click="resetForm()">{{ $t('reset') }}></f7-link>
      </f7-nav-right>
    </f7-navbar> -->
    <!-- <f7-list no-hairlines-md form class=""> -->
    <f7-list inline-labels no-hairlines-md form class="">
        <!-- 氏名 -->
        <f7-list-input
            :label="$t('name')"
            type="text"
            :value="form.name"
            @input="form.name = $event.target.value"
            @change="item_change('name')"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('name') }}</span>
        </f7-list-input>
        <!-- 性別 -->
        <f7-list-item :title="$t('gender')" smart-select :smart-select-params="{openIn: 'popover'}">
            <select name="gender" v-model="form.gender" @change="item_change('gender')">
              <option v-for="item in gender_list" :value="item.value">
                  {{ item.value }}
              </option>
            </select>
            <span style="color:#ff0000" v-if="form.errors.has('gender')" v-text="form.errors.get('gender')"></span>
            <div slot="after-title" v-show="form_show.gender">{{ form.gender }}</div>
        </f7-list-item>
        <!-- 生年月日 -->
        <f7-list-input
            :label="$t('birthday')"
            type="datepicker"
            readonly
            :placeholder="$t('please select')"
            :value="form.birthday_array"
            @calendar:change="birthday_calendar_change"
            :calendar-params="{openIn: 'customModal', header: true, footer: true, dateFormat: 'yyyy-mm-dd'}"
            @change="item_change('birthday')"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('birthday') }}</span>
        </f7-list-input>
        <!-- @input="form.birthday = $event.target.value" -->
        <!-- <f7-block-title>{{ $t('birthday') }}</f7-block-title>
        <div class="list no-hairlines-md">
          <ul>
            <li>
              <div class="item-content item-input">
                <div class="item-inner">
                  <div class="item-input-wrap">
                    <input type="text" :placeholder="$t('please select')" readonly="readonly"
                      @change="form.birthday = $event.target.value"
                      :value="form.birthday"
                      id="birthday_calendar"/>
                  </div>
                </div>
              </div>
              <span style="padding-left:20px;color:#ff0000">{{ form.errors.get('birthday') }}</span>
            </li>
          </ul>
        </div> -->
        <!-- 年齢 -->
        <f7-list-input
            :label="$t('age')"
            type="text"
            :value="form.age"
            pattern="[0-9]*"
            @input="form.age = $event.target.value"
            @change="item_change('age')"
            clear-button>
        </f7-list-input>
        <!-- スキル -->
        <f7-list-item :title="$t('skill')" smart-select :smart-select-params="{openIn: 'popup', searchbar: true, searchbarPlaceholder: ''}">
            <select name="skill_list" multiple v-model="form.skill_list_f7" @change="item_change('skill_list_f7')">
              <optgroup v-for="items in skill_list" :label="items.label">
                <option v-for="item in items.children" :value="item.value">{{ item.label }}</option>
              </optgroup>
            </select>
            <span style="color:#ff0000" v-if="form.errors.has('skill_list')" v-text="form.errors.get('skill_list')"></span>
            <div slot="after-title" v-show="form_show.skill_list_f7">
              {{ form.skill_list_f7.join(',').length>30? form.skill_list_f7.join(',').substr(0,30)+'...' : form.skill_list_f7.join(',')}}</div>
        </f7-list-item>
        <!-- 語学能力 -->
        <f7-list-item :title="$t('language skills')" smart-select :smart-select-params="{openIn: 'popup', searchbar: true, searchbarPlaceholder: ''}">
            <select name="foreign_language_list" multiple v-model="form.foreign_language_list_f7" @change="item_change('foreign_language_list_f7')">
              <optgroup v-for="items in foreign_language_list" :label="items.label">
                <option v-for="item in items.children" :value="item.value">{{ item.label }}</option>
              </optgroup>
            </select>
            <span style="color:#ff0000" v-if="form.errors.get('foreign_language_list')" v-text="form.errors.get('foreign_language_list')"></span>
            <div slot="after-title" v-show="form_show.foreign_language_list_f7">
              {{ form.foreign_language_list_f7.join(',').length>30? form.foreign_language_list_f7.join(',').substr(0,30)+'...' : form.foreign_language_list_f7.join(',')}}</div>
        </f7-list-item>
        <!-- 参画工程 -->
        <f7-list-item :title="$t('process')" smart-select>
            <select name="process_list" multiple v-model="form.process_list" @change="item_change('process_list')">
              <option v-for="item in process_list" :value="item.value">
                  {{ item.value }}
              </option>
            </select>
            <span style="color:#ff0000" v-if="form.errors.get('process_list')" v-text="form.errors.get('process_list')"></span>
            <div slot="after-title" v-show="form_show.process_list">
              {{ form.process_list.join(',').length>18? form.process_list.join(',').substr(0,18)+'...' : form.process_list.join(',')}}</div>
        </f7-list-item>
        <!-- 経験年数 -->
        <f7-list-input
            :label="$t('experience years')"
            type="text"
            :value="form.experience_years"
            pattern="[0-9]*"
            @input="form.experience_years = $event.target.value"
            @change="item_change('experience_years')"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('experience_years') }}</span>
        </f7-list-input>
        <!-- 業務経験 -->
        <f7-list-item :title="$t('business experience')" smart-select>
            <select name="business_list" multiple v-model="form.business_list" @change="item_change('business_list')">
              <option v-for="item in business_list" :value="item.value">
                  {{ item.value }}
              </option>
            </select>
            <span style="color:#ff0000" v-if="form.errors.get('business_list')" v-text="form.errors.get('business_list')"></span>
            <div slot="after-title" v-show="form_show.business_list">
              {{ form.business_list.join(',').length>30? form.business_list.join(',').substr(0,30)+'...' : form.business_list.join(',')}}</div>
        </f7-list-item>
        <!-- 管理経験 -->
        <f7-list-item :title="$t('manage experience')" smart-select>
            <select name="position_list" multiple v-model="form.position_list" @change="item_change('position_list')">
              <option v-for="item in position_list" :value="item.value">
                  {{ item.value }}
              </option>
            </select>
            <span style="color:#ff0000" v-if="form.errors.get('position_list')" v-text="form.errors.get('position_list')"></span>
            <div slot="after-title" v-show="form_show.position_list">
              {{ form.position_list.join(',').length>30? form.position_list.join(',').substr(0,30)+'...' : form.position_list.join(',')}}</div>
        </f7-list-item>
        <!-- 最寄りの駅 -->
        <f7-list-input
            :label="$t('nearest station')"
            type="text"
            :value="form.nearest_station"
            @input="form.nearest_station = $event.target.value"
            @change="item_change('nearest_station')"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('nearest_station') }}</span>
        </f7-list-input>
        <!-- 稼働可能日 -->
        <f7-list-input
            :label="$t('available day')"
            type="datepicker"
            readonly
            :placeholder="$t('please select')"
            :value="form.available_day_array"
            @calendar:change="available_day_calendar_change"
            :calendar-params="{openIn: 'customModal', header: true, footer: true, dateFormat: 'yyyy/mm/dd'}"
            @change="item_change('available_day')"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('available_day') }}</span>
        </f7-list-input>
        <!-- <f7-block-title>{{ $t('available day') }}</f7-block-title>
        <div class="list no-hairlines-md">
          <ul>
            <li>
              <div class="item-content item-input">
                <div class="item-inner">
                  <div class="item-input-wrap">
                    <input type="text" :placeholder="$t('please select')" readonly="readonly"
                      @change="form.available_day = $event.target.value"
                      :value="form.available_day"
                      id="available_day_calendar"/>
                  </div>
                </div>
              </div>
              <span style="padding-left:20px;color:#ff0000">{{ form.errors.get('available_day') }}</span>
            </li>
          </ul>
        </div> -->
        <!-- 仕入単価 -->
        <f7-list-input
            :label="$t('purchase price')"
            type="text"
            :value="form.purchase_price"
            pattern="[0-9]*"
            @input="form.purchase_price = $event.target.value"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('purchase_price') }}</span>
        </f7-list-input>
        <!-- 希望単価 -->
        <f7-list-input
            :label="$t('desired price')"
            type="text"
            :value="form.desired_price"
            pattern="[0-9]*"
            @input="form.desired_price = $event.target.value"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('desired_price') }}</span>
        </f7-list-input>
        <!-- 客先会社 -->
        <!-- <f7-list-item :title="$t('customer company')" smart-select :smart-select-params="{openIn: 'popup', searchbar: true, searchbarPlaceholder: ''}">
            <select name="customer_company_id" v-model="form.customer_company_id" @change="item_change('customer_company_id')">>
              <optgroup v-for="items in customer_company_123_list" :label="items.label">
                <option v-for="item in items.children" :value="item.value">{{ item.label }}</option>
              </optgroup>
            </select>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('customer_company_id') }}</span>
            <div slot="after-title" v-show="form_show.customer_company_id">{{ form.customer_company_id }}</div>
        </f7-list-item> -->
        <!-- 客先会社担当 -->
        <!-- <f7-list-item :title="$t('customer')" smart-select :smart-select-params="{openIn: 'popover'}">
            <select name="customer_id" v-model="form.customer_id">
              <option v-for="item in customer_list" :value="item.id">
                  {{ item.name }}
              </option>
            </select>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('customer_id') }}</span>
            <div slot="after-title" v-show="form_show.customer_id">{{ form.customer_id }}</div>
        </f7-list-item> -->
        <!-- 個人電話 -->
        <f7-list-input
            :label="$t('tel no')"
            type="text"
            :value="form.mobile_telno"
            @input="form.mobile_telno = $event.target.value"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('mobile_telno') }}</span>
        </f7-list-input>
        <!-- コメント１ -->
        <f7-list-input
            :label="$t('comment1')"
            type="textarea"
            :value="form.comment1"
            @input="form.comment1 = $event.target.value"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('comment1') }}</span>
        </f7-list-input>
        <f7-list-input
            :label="$t('comment2')"
            type="textarea"
            :value="form.comment2"
            @input="form.comment2 = $event.target.value"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('comment2') }}</span>
        </f7-list-input>
        <f7-list-input
            :label="$t('comment3')"
            type="textarea"
            :value="form.comment3"
            @input="form.comment3 = $event.target.value"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('comment3') }}</span>
        </f7-list-input>
        <f7-list-item>
            <el-upload ref="file"
                class="upload-demo"
                action=""
                :on-remove="handleRemove"
                :on-change="handleChange"
                :limit="1"
                :on-exceed="handleExceed"
                :file-list="form.fileList"
                :auto-upload="false">
                <el-button slot="trigger" size="small" type="primary">{{ $t('select file') }}</el-button>
                <div slot="tip" class="el-upload__tip">500kb以下のEXCELファイルのみUPLOAD可</div>
            </el-upload>
        </f7-list-item>
        <!-- wechat_no -->
        <f7-list-input
            :label="$t('wechat no')"
            type="text"
            :value="form.wechat_no"
            @input="form.wechat_no = $event.target.value"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('wechat_no') }}</span>
        </f7-list-input>
        <f7-list-button v-if="platformId=='ios'"
            type="submit"
            :loading="form.busy"
            v-on:click.stop.prevent="AddOrUpdateEngineer">
            {{ $t('confirm') }}
        </f7-list-button>
        <f7-list-item v-else>
            <f7-col></f7-col>
            <f7-col>
              <f7-button large v-on:click.stop.prevent="AddOrUpdateEngineer">
                {{ $t('confirm') }}
              </f7-button>
            </f7-col>
            <f7-col></f7-col>
        </f7-list-item>
    </f7-list>
  </f7-page>
</template>

<script>
import { Device }  from 'framework7/framework7-lite.esm.bundle.js';
import axios from 'axios'
import swal from "sweetalert"
import Form from 'vform'
import moment from 'moment';
import { mapGetters } from 'vuex'

export default {
  name: 'AddUpdateEngineer',
  data() {
    return {
      add_update_mode: '1',
      engineer_id: null,
      myTitle: '',
      gender_list: [{
          value: '男',
          label: '男'
      }, {
          value: '女',
          label: '女'
      }],
      foreign_language_list: [],
      skill_list: [],
      process_list: [],
      position_list: [],
      business_list: [],
      customer_company_123_list: [],
      customer_list: [],
      form: new Form({
          id: '',
          name: '',
          gender: '',
          birthday: '',
          birthday_array: [],
          age: '25',
          foreign_language_list_f7: [],
          foreign_language_list: [],
          skill_list_f7: [],
          skill_list: [],
          process_list: [],
          experience_years: '3',
          business_list: [],
          position_list: [],
          nearest_station: '',
          available_day: '',
          available_day_array: [],
          purchase_price: '40',
          desired_price: '45',
          customer_company_id: '',
          customer_id: '',
          mobile_telno: '',
          wechat_no: '',
          comment1: '',
          comment2: '',
          comment3: '',
          imagePreviewConfig: '',
          fileList: [],
          resume_ServerFileName: '',
      }),
      form_show: new Form({
          name: false,
          gender: false,
          foreign_language_list_f7: false,
          skill_list_f7: false,
          process_list: false,
          experience_years: false,
          business_list: false,
          position_list: false,
          customer_company_id: false,
          customer_id: false,
      }),
      platformId: 'ios',
    }
  },
  // watch: {
  //   'form.age': function (age) {
  //     this.calc_birthday()
  //   },
  // },
  // destory() {
  //   const self = this;
  //   self.calendarDefault1.destroy();
  //   self.calendarDefault2.destroy();
  // },
  computed: mapGetters({
    user: 'auth/user',
  }),
  created() {
    let options = JSON.parse(this.$f7route.params.options)
    this.add_update_mode = options.add_update_mode
    if (this.add_update_mode == '2') {
      this.engineer_id = options.engineer_id
      this.form_show.keys().forEach(key => {
        this.form_show[key] = true
      })
    }
    if (!Device.cordova) {
      this.platformId = 'ios'  //pcの場合iosとする
    } else {
      this.platformId = cordova.platformId //ios,android,osx
    }
  },
  mounted() {
    // const self = this;
    // const app = self.$f7;
    // const $ = self.$$;
    // var birthday = new Date();
    // if (this.form.birthday == '') {
    //   birthday = new Date();
    // } else {
    //   birthday = new Date(this.form.birthday);
    // }
    // self.calendarDefault1 = app.calendar.create({
    //   inputEl: '#birthday_calendar',
    //   openIn: 'customModal',
    //   header: true,
    //   footer: true,
    //   value: [
    //     birthday,
    //   ],
    // })
    // self.calendarDefault2 = app.calendar.create({
    //   inputEl: '#available_day_calendar',
    //   openIn: 'customModal',
    //   header: true,
    //   footer: true,
    // })
    if (this.add_update_mode == '1') {
      this.myTitle = this.$t('engineer add')
      //clear the form errors
      this.form.clear()
      this.form.reset()
      this.form.wechat_no = this.user.wechat
      this.get_skill_list()
      // this.get_customer_company_123_list()
      // this.resetForm()
      this.calc_birthday()
    } else if (this.add_update_mode == '2') {
      this.myTitle = this.$t('engineer update')
      this.get_engineer()
      // console.log(this.form);
      this.get_skill_list()
      // this.get_customer_company_123_list()
    }
  },
  methods: {
    birthday_calendar_change(val) {
      if (val.length==0) {
        this.form.birthday_array = []
        this.form.birthday = ''
      } else {
        this.form.birthday_array = val
        this.form.birthday = moment(val[0]).format("YYYY-MM-DD")
      }
    },
    available_day_calendar_change(val) {
      if (val.length==0) {
        this.form.available_day_array = []
        this.form.available_day = ''
      } else {
        this.form.available_day_array = val
        this.form.available_day = moment(val[0]).format("YYYY/MM/DD")
      }
    },
    calc_birthday() {
      var now_date = moment()
      now_date.subtract(this.form.age, "years")
      // var birthday = now_date.format('YYYY-MM-DD')
      // this.form.birthday = birthday
      this.form.birthday_array = [now_date]
    },
    item_change(item_name) {
      if (item_name=='customer_company_id') {
        this.get_customer_list();
        this.form.customer_id = ''
      }
      if (item_name=='age') {
        this.calc_birthday()
      }
      if (item_name=='skill_list_f7') {
        this.form.errors.clear('skill_list')
      } else if (item_name=='foreign_language_list_f7') {
        this.form.errors.clear('foreign_language_list')
      } else {
        this.form.errors.clear(item_name)
      }
      // this.$refs[item_name].clearValidate() // 清除验证
      if (this.add_update_mode == '2') {
        this.form_show[item_name] = false
      }
    },
    customFormatter(date) {
      return moment(date).format('YYYY/MM/DD');
    },
    async AddOrUpdateEngineer () {
      let url = ''
      if (this.add_update_mode == '1') {
        url = '/api/admin/engineer/add_engineer'
      } else {
        url = '/api/admin/engineer/update_engineer'
      }
      this.form.skill_list = [];
      for (var i=0; i<this.form.skill_list_f7.length; i++) {
        for (var j=0; j<this.skill_list.length; j++) {
          for (var k=0; k<this.skill_list[j].children.length; k++) {
            if (this.skill_list[j].children[k].value == this.form.skill_list_f7[i]) {
              this.form.skill_list.push([this.skill_list[j].value,this.form.skill_list_f7[i]])
              break;
            }
          }
        }
      }
      this.form.foreign_language_list = [];
      for (var i=0; i<this.form.foreign_language_list_f7.length; i++) {
        for (var j=0; j<this.foreign_language_list.length; j++) {
          for (var k=0; k<this.foreign_language_list[j].children.length; k++) {
            if (this.foreign_language_list[j].children[k].value == this.form.foreign_language_list_f7[i]) {
              this.form.foreign_language_list.push([this.foreign_language_list[j].value,this.form.foreign_language_list_f7[i]])
              break;
            }
          }
        }
      }
      // const { data } = await this.form.patch(url)
      // console.log(this.form.resume_ServerFileName);
      await this.form.patch(url)
      .then(response => {
        this.status = response.data.status
        //clear the form errors
        this.form.clear()
        this.form.reset()
        let messageText = ''
        if (this.add_update_mode == '1') {
          messageText = this.$t('add success')
        } else {
          messageText = this.$t('update success')
        }
        // var app = new Framework7();
        var self = this
        // var app = self.$f7;
        // Create toast
        var toastSuccess = self.$f7.toast.create({
          text: messageText,
          position: 'center',
          closeTimeout: 2000,
        })
        toastSuccess.open()
        // this.$f7router.back()
        this.$f7router.navigate('/manage/')
      })
      .catch(error => {
        if(error.response.status == 422){
          if (error.response.data.errors) {
          } else {
            if (error.response.data.message) {
              swal({
                icon: "warning",
                text: error.response.data.message,
              });
            }
          }
        } else {
          swal({
            icon: "error",
            text: error,
          });
        }
      });
    },
    async get_skill_list() {
      this.foreign_language_list = []
      this.skill_list = []
      this.process_list = []
      this.position_list = []
      this.business_list = []
      const { data } = await axios.get('/api/engineer/get_skill_list')
      this.foreign_language_list = data.foreign_language_list
      this.skill_list = data.skill_list
      this.process_list = data.process_list
      this.position_list = data.position_list
      this.business_list = data.business_list
    },
    async get_customer_company_123_list() {
      this.customer_company_123_list = []
      this.customer_list = []
      const { data } = await axios.get('/api/admin/customer/get_customer_company_123_list')
      this.customer_company_123_list = data.customer_company_123_list
    },
    async get_customer_list() {
      if (this.form.customer_company_id) {
        let id = this.form.customer_company_id
        this.customer_list = []
        const { data } = await axios.get('/api/admin/engineer/get_customer_list?customer_company_id=' + id)
        this.customer_list = data.customer_list
      } else {
        this.customer_list = []
      }
    },
    get_engineer() {
      const options = {
        params: {
          id: this.engineer_id,
        }
      };
      axios.get('/api/admin/engineer/get_engineer', options)
      .then(response => {
        let item = response.data.engineer
        this.form.keys().forEach(key => {
          this.form[key] = item[key]
        })
        this.form.foreign_language_list_f7 = []
        for (var i=0; i<this.form.foreign_language_list.length; i++) {
          this.form.foreign_language_list_f7.push(this.form.foreign_language_list[i][1])
        }
        this.form.skill_list_f7 = []
        for (var i=0; i<this.form.skill_list.length; i++) {
          this.form.skill_list_f7.push(this.form.skill_list[i][1])
        }
        this.form.birthday_array = [moment(this.form.birthday)]
        this.form.available_day_array = [moment(this.form.available_day)]
        // this.get_customer_list()
      })
      .catch(function (error) {
        swal({
          icon: "error",
          text: error,
        })
      })
    },
    handleExceed(files, fileList) {
        // this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    handleRemove(file, fileList) {
      this.form.fileList = fileList
      axios.post('/api/engineer/resume_remove',{
        file:this.form.resume_ServerFileName
      }).then(response => {
        if (this.add_update_mode == '1') {
          this.form.resume_ServerFileName = '';
        } else {
          if (response.data.result == '1') {
            this.form.resume_ServerFileName = '';
          }
        }
      }).catch(error => {
        if (error.response.status == 422) {
          if (error.response.data.message){
            swal({
              icon: "warning",
              text: error.response.data.message,
            });
          }
        } else {
          swal({
            icon: "error",
            text: error,
          });
        }
      })
    },
    handleChange(file, fileList) {
      if (fileList[0].size > 500*1024) {
        fileList = []
        this.form.fileList = fileList
        var self = this;
        var toastSizeExceed = self.$f7.toast.create({
          text: '500kb以下のEXCELファイルのみUPLOAD可',
          position: 'center',
          closeTimeout: 2000,
        })
        toastSizeExceed.open();
        return
      } else {
        this.form.fileList = fileList
      }
      // var $$ = this.Dom7;
      // var array = $$('input[name="file"]')
      // // var inputVal = $$('#myInput').val();
      let formData = new FormData()
      formData.append('file', fileList[0].raw)
      axios.post('/api/engineer/resume_upload', formData)
      .then(response => {
        this.form.resume_ServerFileName = response.data.path
      })
      .catch(error => {
        swal({
          icon: "error",
          text: error,
        });
      });
    },
    resetForm() {
        // this.form.resetFields();
        this.form.clear()
        this.form.reset()
    },
  }
}
</script>
<style>
</style>
