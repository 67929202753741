window._ = require('lodash');
window.Popper = require('popper.js').default;
// require('foundation-sites');
// window.axios = require('axios');

// //for Echo
// import Echo from "laravel-echo"
// window.io = require('socket.io-client');
//
// // Have this in case we stop running our Laravel Echo server
// if (typeof io !== 'undefined') {
//   //接続情報
//   window.Echo = new Echo({
//       broadcaster: 'socket.io',
//       // host: window.location.hostname + ':6001'
//       // host: 'http://sales.newways:6001',
//       // host: 'http://echo.newways.cloud',
//       http: 'http://ec2-13-231-209-161.ap-northeast-1.compute.amazonaws.com:6001',
//       // auth: {
//       //     headers: {
//       //         'Authorization' : 'Bearer hogehoge',
//       //     }
//       // }
//   });
// }

// Import Vue
import Vue from 'vue';
import store from '~/js/store';
import router from '~/js/router';
import i18n from '~/js/plugins/i18n';
import '~/js/plugins';
import '~/js/components';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

Vue.config.productionTip = false;

// import echarts from "echarts";
// Vue.prototype.$echarts = echarts;
//
// // https://materialdesignicons.com/bootstrap
// import 'mdi/css/materialdesignicons.min.css';
// import '@mdi/font/css/materialdesignicons.min.css';

// import ArrowUpBold from 'vue-material-design-icons/ArrowUpBold.vue';
// import ArrowDownBold from 'vue-material-design-icons/ArrowDownBold.vue';
// import AccountMultiple from 'vue-material-design-icons/AccountMultiple.vue';
// import CurrencyCny from 'vue-material-design-icons/CurrencyCny.vue';
// import AccountTie from 'vue-material-design-icons/AccountTie.vue';
// import AccountCardDetailsOutline from 'vue-material-design-icons/AccountCardDetailsOutline.vue';
// import Email from 'vue-material-design-icons/Email.vue';
// Vue.component('arrow-up-bold-icon', ArrowUpBold);
// Vue.component('arrow-down-bold-icon', ArrowDownBold);
// Vue.component('account-multiple-icon', AccountMultiple);
// Vue.component('currency-cny-icon', CurrencyCny);
// Vue.component('account-tie-icon', AccountTie);
// Vue.component('account-card-details-outline-icon', AccountCardDetailsOutline);
// Vue.component('email-icon', Email);

// Import Framework7
import Framework7 from 'framework7/framework7-lite.esm.bundle.js';
// import Framework7 from 'framework7/framework7.esm.bundle.js';

// Import Framework7-Vue Plugin
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js';

// Import Framework7 Styles
import 'framework7/css/framework7.bundle.css';
import 'framework7-icons/css/framework7-icons.css'; //牛追加

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.css';

// Import App Component
import App from '@/components/app.vue';
// import App from '@/js/components/App';

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

// Init App
new Vue({
  el: '#app',
  render: (h) => h(App),

  i18n,
  store,

  // render: c => c(App),
  // Register App Component
  components: {
    app: App
  },
});
