<template>
  <f7-page class="grid-demo"
      infinite
      :infinite-distance="50"
      :infinite-preloader="showPreloader"
      @infinite="loadMore">
    <f7-navbar>
      <!-- <f7-nav-left>
        <f7-link popover-open=".popover-menu-setting">
          <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
        </f7-link>
      </f7-nav-left> -->
      <f7-nav-title>
        <f7-link href="/account_info/" color="blue">{{ user.name }}</f7-link>
      </f7-nav-title>
      <!-- <f7-nav-right>
        <f7-link href="/account_info/">》</f7-link>
      </f7-nav-right> -->
    </f7-navbar>

    <!-- top left menu  -->
    <!-- <f7-popover ref="popoverMenu" class="popover-menu-setting">
      <f7-list>
        <f7-list-item v-on:click.stop.prevent="AddAccount()" :title="$t('account add')">
        </f7-list-item>
      </f7-list>
    </f7-popover> -->

    <!-- <f7-block-title v-show="user_role_id<=5">会社アカウント情報管理</f7-block-title> -->
    <f7-list accordion-list inset v-if="user_role_id<=5">
      <f7-list-item accordion-item :title="$t('settings')">
        <f7-icon slot="media" ios="f7:gear_alt" aurora="f7:gear_alt" md="material:settings"></f7-icon>
        <f7-accordion-content>
          <f7-list inset>
            <f7-list-item
              swipeout
              link="/update_company_info/"
              :title="$t('update company info')">
            </f7-list-item>
            <f7-list-item
              swipeout
              link="/admin_email_change/"
              :title="$t('update email')">
            </f7-list-item>
            <f7-list-item
              swipeout
              link="/admin_password_change/"
              :title="$t('update password')">
            </f7-list-item>
            <f7-list-button v-if="platformId=='ios'"
                color="red"
                :title="$t('logout')"
                v-on:click.stop.prevent="logout()">
            </f7-list-button>
            <f7-list-item v-else>
                <f7-col></f7-col>
                <f7-col>
                  <f7-button color="red" large v-on:click.stop.prevent="logout()">
                    {{ $t('logout') }}
                  </f7-button>
                </f7-col>
                <f7-col></f7-col>
            </f7-list-item>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>
    </f7-list>

    <f7-list accordion-list inset v-if="user_role_id>=10">
      <f7-list-item accordion-item :title="$t('settings')">
        <f7-icon slot="media" ios="f7:gear_alt" aurora="f7:gear_alt" md="material:settings"></f7-icon>
        <f7-accordion-content>
          <f7-list inset>
            <f7-list-item
              swipeout
              link="/admin_email_change/"
              :title="$t('update email')">
            </f7-list-item>
            <f7-list-item
              swipeout
              link="/admin_password_change/"
              :title="$t('update password')">
            </f7-list-item>
          </f7-list>
          <f7-list media-list>
            <f7-list-button v-if="platformId=='ios'"
                color="red"
                :title="$t('logout')"
                v-on:click.stop.prevent="logout()">
            </f7-list-button>
            <f7-list-item v-else>
                <f7-col></f7-col>
                <f7-col>
                  <f7-button color="red" large v-on:click.stop.prevent="logout()">
                    {{ $t('logout') }}
                  </f7-button>
                </f7-col>
                <f7-col></f7-col>
            </f7-list-item>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>
    </f7-list>

    <!-- <f7-block-title v-show="user_role_id<=10">営業アカウント管理</f7-block-title> -->
    <f7-list accordion-list inset v-if="user_role_id<=10">
      <f7-list-item accordion-item :title="$t('account manage')" :badge="pagination.total" badge-color="green">
        <f7-icon slot="media" ios="f7:person_2" aurora="f7:person_2" md="material:person_add"></f7-icon>
        <f7-accordion-content>
          <f7-list inset media-list>
            <f7-list-item v-for="(item,index) in account_items"
              swipeout
              :header="item.account_id"
              :title="item.account_name"
              :subtitle="item.role_name"
              :key="item.id">
              <f7-swipeout-actions right>
                <f7-swipeout-button @click="more(index,item); $refs.actionsOneGroup_account.open()">...</f7-swipeout-button>
                <f7-swipeout-button color="orange" @click="UpdateAccount(index,item)">{{ $t('edit') }}</f7-swipeout-button>
              </f7-swipeout-actions>
            </f7-list-item>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>
      <f7-list-button v-if="platformId=='ios'"
          :title="$t('account add')"
          v-on:click.stop.prevent="AddAccount()">
      </f7-list-button>
      <f7-list-item v-else>
          <f7-col></f7-col>
          <f7-col>
            <f7-button large v-on:click.stop.prevent="AddAccount()">
              {{ $t('account add') }}
            </f7-button>
          </f7-col>
          <f7-col></f7-col>
      </f7-list-item>
    </f7-list>

    <f7-actions ref="actionsOneGroup_account" id="actionsOneGroup_account">
      <f7-actions-group>
        <f7-actions-button color="red" @click="handleDelete()">
            {{ $t('delete') }}
        </f7-actions-button>
        <f7-actions-button>
            {{ $t('cancel') }}
        </f7-actions-button>
      </f7-actions-group>
    </f7-actions>

    <f7-list accordion-list inset>
      <f7-list-item accordion-item :title="$t('about app')">
        <f7-icon slot="media" ios="f7:info_circle" aurora="f7:info_circle" md="material:info"></f7-icon>
        <f7-accordion-content>
          <f7-list inset>
            <f7-list-item
              swipeout
              link="/contact/"
              :title="$t('contact')">
            </f7-list-item>
            <f7-list-item
              :title="$t('app version')"
              :after="app_version">
            </f7-list-item>
            <f7-list-item v-if="app_version!=app_version_newest"
              :title="$t('newest version')"
              :badge="app_version_newest"
              badge-color="orange">
            </f7-list-item>
            <f7-list-item
              swipeout
              link="/serviceterms/"
              :title="$t('service terms')">
            </f7-list-item>
            <f7-list-item
              swipeout
              link="/privacypolicy/"
              :title="$t('privacy policy')">
            </f7-list-item>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>
    </f7-list>

    <f7-list accordion-list inset>
      <f7-list-item accordion-item :title="$t('notice')" :badge="notice_not_readed_count" badge-color="red">
        <f7-icon slot="media" ios="f7:speaker_2" aurora="f7:speaker_2" md="material:notifications_none"></f7-icon>
        <f7-accordion-content>
          <f7-list media-list inset>
            <f7-list-item v-for="(item,index) in notice_items"
              :badge="item.readed? '': $t('not readed')"
              badge-color="red"
              @click="detail_notice(item,index)"
              :header="item.updated_at"
              :title="item.subject"
              :text="item.content"
              :key="item.id">
            </f7-list-item>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>
    </f7-list>

  </f7-page>
</template>

<script>
import { Device }  from 'framework7/framework7-lite.esm.bundle.js';
import axios from 'axios'
import swal from "sweetalert";
import { mapGetters } from 'vuex'

export default {
  metaInfo() {
    return { title: this.$t('own company') }
  },
  data() {
    return {
      allowInfinite: true,
      showPreloader: true,
      curr_item: {
        item: null,
        index: null
      },
      account_items: [],
      app_version: '',
      app_version_newest: '',
      pagination: {
        per_page: 50,    // 每页件数 固定
        pager_count: 3,  // 显示页数 固定
        current_page: 1, // 当前页 変わる
        total: 0,        // 总条目数
      },
      notice_items: [],
      notice_not_readed_count: '',
      platformId: 'ios',
    }
  },
  created() {
    if (!Device.cordova) {
      this.platformId = 'ios'  //pcの場合iosとする
    } else {
      this.platformId = cordova.platformId //ios,android,osx
    }
  },
  mounted() {
    const app = this.$f7
    this.app_version = app.version
    this.get_newest_5_notice_mobile(null)
    this.get_app_version_info()
    this.init()
    this.loadMore()
  },
  computed: mapGetters({
    user: 'auth/user',
    user_role_id: 'auth/role_id',
    pushed_app_version_newest: 'pushed/app_version_newest',
    pushed_added_notice_id: 'pushed/added_notice_id',
  }),
  watch: {
    pushed_app_version_newest: function (newVal, oldVal) {
      this.app_version_newest = newVal
    },
    pushed_added_notice_id: function (newVal, oldVal) {
      this.get_newest_5_notice_mobile(newVal)
    },
  },
  methods: {
    async get_newest_5_notice_mobile(notice_id) {
      const options = {
        params: {
          pushed_notice_id: notice_id
        }
      }
      await axios.get('/api/admin/get_newest_5_notice_mobile', options)
      .then(response => {
        if (!notice_id) {
          this.notice_items = response.data.notice_items
          this.notice_not_readed_count = response.data.notice_not_readed_count
        } else {
          let data = response.data.notice_items
          this.notice_items.unshift(...data)
          this.notice_not_readed_count = this.notice_not_readed_count + response.data.notice_not_readed_count
        }
      })
      .catch(function (error) {
        if (error.response.status == 422) {
          if (error.response.data.message){
            swal({
              icon: "warning",
              text: error.response.data.message,
            })
          }
        } else {
          swal({
            icon: "error",
            text: error,
          })
        }
      })
    },
    async get_app_version_info() {
      await axios.get('/api/admin/setting/get_app_version_info')
      .then(response => {
        this.app_version_newest = response.data.version.version
      })
      .catch(function (error) {
        if (error.response.status == 422) {
          if (error.response.data.message){
            swal({
              icon: "warning",
              text: error.response.data.message,
            })
          }
        } else {
          swal({
            icon: "error",
            text: error,
          })
        }
      })
    },
    init() {
      this.pagination.current_page = 0
      this.pagination.total = 0
      this.account_items = []
      this.curr_item.item = null
      this.curr_item.index = null
      this.allowInfinite = true
      this.showPreloader = true
    },
    loadMore() {
      // const self = this;
      if (!this.allowInfinite) return;
      this.allowInfinite = false;
      setTimeout(() => {
        this.pagination.current_page = this.pagination.current_page + 1
        this.get_account_list()
        this.allowInfinite = true;
      }, 1000);
    },
    async get_account_list() {
      const options = {
        params: {
          paginate: this.pagination.per_page,
          page: this.pagination.current_page,
        }
      };
      await axios.get('/api/admin/get_account_list', options)
      .then(response => {
        let data = response.data.list.data
        this.pagination.total = response.data.list.total
        this.pagination.current_page = response.data.list.current_page
        this.account_items.push(...data)
        if (this.account_items.length === this.pagination.total) {
          this.showPreloader = false
          this.allowInfinite = false
        }
      })
      .catch(function (error) {
        swal({
          icon: "error",
          text: error,
        })
      })
    },
    more(index, item) {
      this.curr_item.item = item
      this.curr_item.index = index
    },
    handleDelete() {
      let url = '/api/admin/delete_account?id=' + this.curr_item.item.id
      swal({
        title: this.$t('confirm delete'),
        dangerMode: true,
        buttons: [this.$t('cancel'), this.$t('delete')],
      }).then((value) => {
        if (value) {
          axios.get(url)
          .then(response => {
            this.account_items.splice(this.curr_item.index, 1)
            this.curr_item.item = null
            this.curr_item.index = null
          })
          .catch(error => {
            if (error.response.status == 422) {
              if (error.response.data.message){
                swal({
                  icon: "warning",
                  text: error.response.data.message,
                });
              }
            } else {
              swal({
                icon: "error",
                text: error,
              })
            }
          })
        }
      })
    },
    detail_notice(item,index) {
      if (!item.readed) {
        item.readed = true
        this.notice_not_readed_count = this.notice_not_readed_count - 1
      }
      let options = { notice_id: item.id }
      this.$f7router.navigate('/detail_notice/' + JSON.stringify(options))
    },
    AddAccount() {
      // this.$refs.popoverMenu.close()
      // this.$f7router.navigate('/add_engineer/', {props: { add_update_mode: '1' }})
      let options = { add_update_mode: '1' }
      this.$f7router.navigate('/add_account/' + JSON.stringify(options))
    },
    UpdateAccount(index,item) {
      // this.$f7router.navigate("/update_engineer/", {props: { add_update_mode: '2', current_row: item}})
      let options = { add_update_mode: '2', account_id: item.id }
      this.$f7router.navigate('/update_account/' + JSON.stringify(options))
    },
    async logout () {
      // Log out the user.
      await this.$store.dispatch('auth/logout')
      // Redirect to login.
      // this.$f7router.navigate('/admin/login/')
      //this.$f7router.navigate('/index/')
    }
  }
}
</script>
<style>
</style>
