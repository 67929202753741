<template>
  <f7-page>
    <f7-navbar :title="$t('complaint')" :back-link="$t('back')"></f7-navbar>
    <f7-list inline-labels no-hairlines-md form class="">
        <!-- complaint reason -->
        <f7-list-item
            :title="$t('complaint reason')" smart-select :smart-select-params="{openIn: 'sheet'}">
            <select name="complaint_reason_list" multiple v-model="form.complaint_reason_list" @change="item_change('complaint_reason_list')">
              <option v-for="item in complaint_reason_list" :value="item.value">
                  {{ item.value }}
              </option>
            </select>
            <span style="color:#ff0000" v-if="form.errors.get('complaint_reason_list')" v-text="form.errors.get('complaint_reason_list')"></span>
        </f7-list-item>
        <f7-list-button v-if="platformId=='ios'"
            type="submit"
            :loading="form.busy"
            v-on:click.stop.prevent="complaint()">
            {{ $t('confirm') }}
        </f7-list-button>
        <f7-list-item v-else>
            <f7-col></f7-col>
            <f7-col>
              <f7-button large v-on:click.stop.prevent="complaint()">
                {{ $t('confirm') }}
              </f7-button>
            </f7-col>
            <f7-col></f7-col>
        </f7-list-item>
    </f7-list>

  </f7-page>
</template>

<script>
import { Device }  from 'framework7/framework7-lite.esm.bundle.js';
import axios from 'axios'
import swal from "sweetalert"
import Form from 'vform'

export default {
  name: 'ComplaintProject',
  data() {
    return {
      complaint_reason_list: [],
      form: new Form({
          project_id: '',
          complaint_reason_list: [],
      }),
      platformId: 'ios',
    }
  },
  created() {
    let options = JSON.parse(this.$f7route.params.options)
    this.form.project_id = options.project_id
    this.get_complaint_reason_list()
  },
  mounted() {
    if (!Device.cordova) {
      this.platformId = 'ios'  //pcの場合iosとする
    } else {
      this.platformId = cordova.platformId //ios,android,osx
    }
  },
  methods: {
    async get_complaint_reason_list() {
      this.complaint_reason_list = []
      const { data } = await axios.get('/api/admin/get_complaint_reason_list')
      this.complaint_reason_list = data.complaint_reason_list
    },
    item_change(item_name) {
      this.form.errors.clear(item_name)
    },
    async complaint () {
      await this.form.patch('/api/admin/add_complaint_mobile')
      .then(response => {
        this.status = response.data.status
        let messageText = ''
        messageText = this.$t('send success')
        var self = this
        // Create toast
        var toastSuccess = self.$f7.toast.create({
          text: messageText,
          position: 'center',
          closeTimeout: 2000,
        })
        toastSuccess.open()
        // this.$f7router.back()
        this.$f7router.navigate('/projects_open/')
      })
      .catch(error => {
        if(error.response.status == 422){
          if (error.response.data.errors) {
          } else {
            if (error.response.data.message) {
              swal({
                icon: "warning",
                text: error.response.data.message,
              });
            }
          }
        } else {
          swal({
            icon: "error",
            text: error,
          });
        }
      });
    },
  }
}
</script>
