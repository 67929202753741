<template>
  <f7-page :page-content="false">
    <!-- <f7-navbar title="Tabbar" back-link="Back">
      <f7-nav-right>
        <f7-link icon-ios="f7:reload" icon-md="material:compare_arrows" @click="isBottom = !isBottom"></f7-link>
      </f7-nav-right>
    </f7-navbar> -->

    <!-- <f7-navbar>
      <f7-nav-left>
        <f7-link icon-if-ios="f7:menu" icon-if-md="material:menu" panel-open="left"></f7-link>
      </f7-nav-left>
      <f7-nav-title>newProject</f7-nav-title>
      <f7-nav-right>
        <f7-link icon-if-ios="f7:menu" icon-if-md="material:menu" panel-open="right"></f7-link>
      </f7-nav-right>
    </f7-navbar> -->
    <f7-views tabs class="safe-areas">
      <f7-toolbar tabbar labels :position="isBottom ? 'bottom' : 'top'">
        <f7-link tab-link="#project" tab-link-active
            icon-ios="f7:building_2_fill" icon-aurora="f7:building_2_fill" icon-md="material:business"
            :text="$t('project')">
        </f7-link>
        <f7-link tab-link="#engineer"
            icon-ios="f7:person_2_fill" icon-aurora="f7:person_2_fill" icon-md="material:person"
            :text="$t('engineer')">
        </f7-link>
        <!-- icon-ios="f7:plus_circle_fill" icon-aurora="f7:plus_circle_fill" icon-md="material:add_circle" -->
        <f7-link tab-link="#manage"
            icon-ios="f7:arrow_up_arrow_down_circle_fill" icon-aurora="f7:arrow_up_arrow_down_circle_fill" icon-md="material:swap_vertical_circle"
            :text="$t('manage')">
        </f7-link>
        <f7-link tab-link="#company"
            icon-ios="f7:house_fill" icon-aurora="f7:house_fill" icon-md="material:home"
            :text="$t('own company')">
        </f7-link>
      </f7-toolbar>

      <f7-tabs animated>
        <!-- <f7-tab id="proposal" class="page-content" tab-active>
          <f7-block>
            <p>Tab 1 content</p>
            ...
          </f7-block>
        </f7-tab>
        <f7-tab id="engineer">
          <f7-view id="engineer-view" url="/engineers/"></f7-view>
          <Engineers/>
        </f7-tab> -->
        <f7-view id="project"  tab main tab-active
            url="/projects_open/"
            :animate="false"
            :ios-dynamic-navbar="false"
            :push-state="false">
        </f7-view>
        <f7-view id="engineer" tab
            url="/engineers_open/"
            :animate="false"
            :ios-dynamic-navbar="false"
            :push-state="false">
        </f7-view>
        <f7-view id="manage" tab
            url="/manage/"
            :animate="false"
            :ios-dynamic-navbar="false"
            :push-state="false">
        </f7-view>
        <f7-view id="company" tab
            url="/company_setting/"
            :animate="false"
            :ios-dynamic-navbar="false"
            :push-state="false">
        </f7-view>
      </f7-tabs>
    </f7-views>
  </f7-page>
</template>

<script>
  // import CompanySetting from '~/js/pages/settings/index.vue';
  export default {
    name: 'mobile_tab_home',
    metaInfo () {
      return { title: this.$t('home') }
    },
    // components: {
    //     CompanySetting
    // },
    data() {
      return {
        isBottom: true,
      };
    }
  }
</script>
