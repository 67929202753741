<template>
  <f7-page>
    <f7-navbar :title="$t('contact info setting')" :back-link="$t('back')"></f7-navbar>
    <f7-list inline-labels no-hairlines-md form class="">
        <!-- wechat_no_1 -->
        <f7-list-input
            :label="$t('wechat')"
            type="text"
            :value="form.wechat_no_1"
            @input="form.wechat_no_1 = $event.target.value"
            @change="item_change('wechat_no_1')"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('wechat_no_1') }}</span>
        </f7-list-input>
        <!-- wechat_no_2 -->
        <f7-list-input
            :label="$t('wechat')"
            type="text"
            :value="form.wechat_no_2"
            @input="form.wechat_no_2 = $event.target.value"
            @change="item_change('wechat_no_2')"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('wechat_no_2') }}</span>
        </f7-list-input>
        <!-- wechat_no_2 -->
        <f7-list-input
            :label="$t('wechat')"
            type="text"
            :value="form.wechat_no_3"
            @input="form.wechat_no_3 = $event.target.value"
            @change="item_change('wechat_no_3')"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('wechat_no_3') }}</span>
        </f7-list-input>
        <!-- wechat_no_2 -->
        <f7-list-input
            :label="$t('wechat')"
            type="text"
            :value="form.wechat_no_4"
            @input="form.wechat_no_4 = $event.target.value"
            @change="item_change('wechat_no_4')"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('wechat_no_4') }}</span>
        </f7-list-input>
        <!-- wechat_no_2 -->
        <f7-list-input
            :label="$t('wechat')"
            type="text"
            :value="form.wechat_no_5"
            @input="form.wechat_no_5 = $event.target.value"
            @change="item_change('wechat_no_5')"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('wechat_no_5') }}</span>
        </f7-list-input>
        <!-- email_1 -->
        <f7-list-input
            :label="$t('email')"
            type="text"
            :value="form.email_1"
            @input="form.email_1 = $event.target.value"
            @change="item_change('email_1')"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('email_1') }}</span>
        </f7-list-input>
        <!-- email_2 -->
        <f7-list-input
            :label="$t('email')"
            type="text"
            :value="form.email_2"
            @input="form.email_2 = $event.target.value"
            @change="item_change('email_2')"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('email_2') }}</span>
        </f7-list-input>
        <!-- email_3 -->
        <f7-list-input
            :label="$t('email')"
            type="text"
            :value="form.email_3"
            @input="form.email_3 = $event.target.value"
            @change="item_change('email_3')"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('email_3') }}</span>
        </f7-list-input>
        <!-- email_4 -->
        <f7-list-input
            :label="$t('email')"
            type="text"
            :value="form.email_4"
            @input="form.email_4 = $event.target.value"
            @change="item_change('email_4')"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('email_4') }}</span>
        </f7-list-input>
        <!-- email_5 -->
        <f7-list-input
            :label="$t('email')"
            type="text"
            :value="form.email_5"
            @input="form.email_5 = $event.target.value"
            @change="item_change('email_5')"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('email_5') }}</span>
        </f7-list-input>
        <f7-list-button v-if="platformId=='ios'"
            type="submit"
            :loading="form.busy"
            v-on:click.stop.prevent="update">
            {{ $t('confirm') }}
        </f7-list-button>
        <f7-list-item v-else>
            <f7-col></f7-col>
            <f7-col>
              <f7-button large v-on:click.stop.prevent="update">
                {{ $t('confirm') }}
              </f7-button>
            </f7-col>
            <f7-col></f7-col>
        </f7-list-item>
    </f7-list>
  </f7-page>
</template>

<script>
import { Device }  from 'framework7/framework7-lite.esm.bundle.js';
import axios from 'axios'
import swal from "sweetalert"
import Form from 'vform'

export default {
  name: 'ContactUpdate',
  data() {
    return {
      form: new Form({
          wechat_no_1: '',
          wechat_no_2: '',
          wechat_no_3: '',
          wechat_no_4: '',
          wechat_no_5: '',
          email_1: '',
          email_2: '',
          email_3: '',
          email_4: '',
          email_5: '',
      }),
      platformId: 'ios',
    }
  },
  created() {
    if (!Device.cordova) {
      this.platformId = 'ios'  //pcの場合iosとする
    } else {
      this.platformId = cordova.platformId //ios,android,osx
    }
  },
  mounted() {
    this.get_contact_info()
  },
  methods: {
    async get_contact_info() {
      await axios.get('/api/admin/setting/get_contact_info')
      .then(response => {
        let contact_items_wechat_id = response.data.support_wechat_id
        for (var i=0; i<contact_items_wechat_id.length; i++) {
          if (i==0) {
            this.form.wechat_no_1 = contact_items_wechat_id[i]
          } else if (i==1) {
            this.form.wechat_no_2 = contact_items_wechat_id[i]
          } else if (i==2) {
            this.form.wechat_no_3 = contact_items_wechat_id[i]
          } else if (i==3) {
            this.form.wechat_no_4 = contact_items_wechat_id[i]
          } else if (i==4) {
            this.form.wechat_no_5 = contact_items_wechat_id[i]
          }
        }
        let contact_items_email = response.data.support_email
        for (var i=0; i<contact_items_email.length; i++) {
          if (i==0) {
            this.form.email_1 = contact_items_email[i]
          } else if (i==1) {
            this.form.email_2 = contact_items_email[i]
          } else if (i==2) {
            this.form.email_3 = contact_items_email[i]
          } else if (i==3) {
            this.form.email_4 = contact_items_email[i]
          } else if (i==4) {
            this.form.email_5 = contact_items_email[i]
          }
        }
      })
      .catch(function (error) {
        if (error.response.status == 422) {
          if (error.response.data.message){
            swal({
              icon: "warning",
              text: error.response.data.message,
            })
          }
        } else {
          swal({
            icon: "error",
            text: error,
          })
        }
      })
    },
    item_change(item_name) {
      this.form.errors.clear(item_name)
    },
    async update () {
      await this.form.patch('/api/system/update_contact_info')
      .then(response => {
        let messageText = ''
        messageText = this.$t('update success')
        var self = this
        // Create toast
        var toastSuccess = self.$f7.toast.create({
          text: messageText,
          position: 'center',
          closeTimeout: 2000,
        })
        toastSuccess.open()
        this.$f7router.back()
      })
      .catch(error => {
        if(error.response.status == 422){
        } else {
          swal({
            icon: "error",
            text: error,
          });
        }
      });
    },
  }
}
</script>
<style>
</style>
