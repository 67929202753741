<template>
  <f7-page>
    <f7-navbar :title="myTitle" :back-link="$t('back')"></f7-navbar>
    <f7-list inline-labels no-hairlines-md form class="">
        <!-- send_flag -->
        <f7-list-input v-if="form.id"
            :label="$t('send status')"
            type="text"
            :value="form.send_flag"
            readonly>
        </f7-list-input>
        <!-- subject -->
        <f7-list-input
            :label="$t('notice subject')"
            type="text"
            :value="form.subject"
            @input="form.subject = $event.target.value"
            @change="item_change('subject')"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('subject') }}</span>
        </f7-list-input>
        <!-- content -->
        <f7-list-input
            :label="$t('notice content')"
            type="textarea"
            resizable
            :value="form.content"
            @input="form.content = $event.target.value"
            @change="item_change('content')"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('content') }}</span>
        </f7-list-input>
        <f7-list-button v-if="platformId=='ios'"
            type="submit"
            :loading="form.busy"
            v-on:click.stop.prevent="AddOrUpdateNotice">
            {{ $t('confirm') }}
        </f7-list-button>
        <f7-list-item v-else>
            <f7-col></f7-col>
            <f7-col>
              <f7-button large v-on:click.stop.prevent="AddOrUpdateNotice">
                {{ $t('confirm') }}
              </f7-button>
            </f7-col>
            <f7-col></f7-col>
        </f7-list-item>
    </f7-list>
  </f7-page>
</template>

<script>
import { Device }  from 'framework7/framework7-lite.esm.bundle.js';
import axios from 'axios'
import swal from "sweetalert"
import Form from 'vform'

export default {
  name: 'AddUpdateNotice',
  data() {
    return {
      myTitle: '',
      add_update_mode: '1',
      notice_id: null,
      form: new Form({
          id: '',
          notice_id: '',
          subject: '',
          content: '',
          send_flag: false,
      }),
      platformId: 'ios',
    }
  },
  created() {
    let options = JSON.parse(this.$f7route.params.options)
    this.add_update_mode = options.add_update_mode
    if (this.add_update_mode == '2') {
      this.notice_id = options.notice_id
    }
    if (!Device.cordova) {
      this.platformId = 'ios'  //pcの場合iosとする
    } else {
      this.platformId = cordova.platformId //ios,android,osx
    }
  },
  mounted() {
    if (this.add_update_mode == '1') {
      this.myTitle = this.$t('add notice')
      this.resetForm()
    } else if (this.add_update_mode == '2') {
      this.myTitle = this.$t('update notice')
      this.get_notice_detail_mobile()
    }
  },
  methods: {
    item_change(item_name) {
      this.form.errors.clear(item_name)
    },
    async AddOrUpdateNotice() {
      let url = ''
      if (this.add_update_mode == '1') {
        url = '/api/system/add_notice_mobile'
      } else {
        url = '/api/system/update_notice_mobile'
      }
      await this.form.patch(url)
      .then(response => {
        this.status = response.data.status
        //clear the form errors
        // this.resetForm()
        let messageText = ''
        if (this.add_update_mode == '1') {
          messageText = this.$t('add success')
        } else {
          messageText = this.$t('update success')
        }
        var self = this
        // Create toast
        var toastSuccess = self.$f7.toast.create({
          text: messageText,
          position: 'center',
          closeTimeout: 2000,
        })
        toastSuccess.open()
        //this.$f7router.back()
        //Redirect.
        this.$f7router.navigate('/system/')
      })
      .catch(error => {
        if (error.response.status == 422) {
          if (error.response.data.errors) {
          } else {
            if (error.response.data.message) {
              swal({
                icon: "warning",
                text: error.response.data.message,
              });
            }
          }
        } else {
          swal({
            icon: "error",
            text: error,
          });
        }
      });
    },
    get_notice_detail_mobile() {
      const options = {
        params: {
          id: this.notice_id,
        }
      }
      axios.get('/api/system/get_notice_detail_mobile', options)
      .then(response => {
        let item = response.data.notice
        if (item) {
          if (item.send_flag) {
            item.send_flag = this.$t('sent')
          } else {
            item.send_flag = this.$t('not sent')
          }
          this.form.keys().forEach(key => {
            this.form[key] = item[key]
          })
        }
      })
      .catch(function (error) {
        swal({
          icon: "error",
          text: error,
        })
      })
    },
    resetForm() {
        this.form.clear()
        this.form.reset()
    },
  }
}
</script>
<style>
</style>
