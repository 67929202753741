<template>
  <f7-page>
    <f7-navbar :title="$t('account info')" :back-link="$t('back')"></f7-navbar>
    <f7-list inline-labels no-hairlines-md form class="">
        <!-- 名称 -->
        <f7-list-input
            :label="$t('name')"
            type="text"
            :value="form.name"
            readonly>
        </f7-list-input>
        <!-- email -->
        <f7-list-input
            :label="$t('email')"
            type="text"
            :value="form.email"
            readonly>
        </f7-list-input>
        <!-- 所属会社 -->
        <f7-list-input
            :label="$t('company name')"
            type="text"
            :value="form.company_name"
            readonly>
        </f7-list-input>
    </f7-list>
  </f7-page>
</template>

<script>
import Form from 'vform'
import { mapGetters } from 'vuex'

export default {
  name: 'AccountInfo',
  data() {
      return {
        form: new Form({
            name: '',
            email: '',
            company_name: '',
        }),
      }
  },
  computed: mapGetters({
    user: 'auth/user'
  }),
  created () {
    // Fill the form with user data.
    this.form.keys().forEach(key => {
      this.form[key] = this.user[key]
    })
    this.form.company_name = this.user.company.name
  },
  methods: {
  }
}
</script>
<style>
</style>
