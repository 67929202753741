<template>
  <f7-page>
    <f7-navbar :back-link="$t('back')"></f7-navbar>
    <f7-list no-hairlines-md form class="">
        <!-- 登録日 -->
        <f7-list-input
            :label="$t('updated at')"
            type="text"
            :value="form.updated_at"
            readonly>
        </f7-list-input>
        <!-- send_flag -->
        <f7-list-input
            :label="$t('send status')"
            type="text"
            :value="form.send_flag"
            readonly>
        </f7-list-input>
        <!-- notice subject -->
        <f7-list-input
            :label="$t('notice subject')"
            type="text"
            :value="form.subject"
            readonly>
        </f7-list-input>
        <!-- notice content -->
        <f7-list-input
            :label="$t('notice content')"
            type="textarea"
            resizable
            :value="form.content"
            readonly>
        </f7-list-input>
        <f7-list-button v-if="platformId=='ios'"
            v-on:click.stop.prevent="$f7router.back()">
            {{ $t('back') }}
        </f7-list-button>
        <f7-list-item v-else>
            <f7-col></f7-col>
            <f7-col>
              <f7-button large v-on:click.stop.prevent="$f7router.back()">
                {{ $t('back') }}
              </f7-button>
            </f7-col>
            <f7-col></f7-col>
        </f7-list-item>
    </f7-list>
  </f7-page>
</template>

<script>
import { Device }  from 'framework7/framework7-lite.esm.bundle.js'
import axios from 'axios'
import swal from "sweetalert"

export default {
  name: 'DetailNotice',
  data() {
    return {
      notice_id: null,
      form: {},
      platformId: 'ios',
    };
  },
  created() {
    if (!Device.cordova) {
      this.platformId = 'ios'  //pcの場合iosとする
    } else {
      this.platformId = cordova.platformId //ios,android,osx
    }
  },
  mounted() {
    this.notice_id = this.$f7route.params.id
    this.get_notice_detail_mobile()
  },
  methods: {
    get_notice_detail_mobile() {
      const options = {
        params: {
          id: this.notice_id,
        }
      }
      axios.get('/api/system/get_notice_detail_mobile', options)
      .then(response => {
        let item = response.data.notice
        if (item) {
          if (item.send_flag) {
            item.send_flag = this.$t('sent')
          } else {
            item.send_flag = this.$t('not sent')
          }
        }
        this.form = item
      })
      .catch(function (error) {
        swal({
          icon: "error",
          text: error,
        })
      })
    },
  }
}
</script>
