<template>
  <f7-page>
    <f7-navbar :title="$t('your info')" :back-link="$t('back')"></f7-navbar>
    <f7-list no-hairlines-md form class="">
        <!-- 名称 -->
        <f7-list-input
            :label="$t('name')"
            type="text"
            :value="form.name"
            @input="form.name = $event.target.value"
            @change="item_change('name')"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('name') }}</span>
        </f7-list-input>
        <!-- email -->
        <f7-list-input
            :label="$t('email')"
            type="text"
            :value="form.email"
            @input="form.email = $event.target.value"
            @change="item_change('email')"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('email') }}</span>
        </f7-list-input>
        <!-- wechat -->
        <f7-list-input
            :label="$t('wechat')"
            type="text"
            :value="form.wechat"
            @input="form.wechat = $event.target.value"
            @change="item_change('wechat')"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('wechat') }}</span>
        </f7-list-input>
        <f7-list-button v-if="platformId=='ios'"
            type="submit"
            :loading="form.busy"
            v-on:click.stop.prevent="update()">
            {{ $t('confirm') }}
        </f7-list-button>
        <f7-list-item v-else>
            <f7-col></f7-col>
            <f7-col>
              <f7-button large v-on:click.stop.prevent="update()">
                {{ $t('confirm') }}
              </f7-button>
            </f7-col>
            <f7-col></f7-col>
        </f7-list-item>
    </f7-list>
  </f7-page>
</template>

<script>
import { Device }  from 'framework7/framework7-lite.esm.bundle.js';
import axios from 'axios'
import swal from "sweetalert"
import Form from 'vform'
import { mapGetters } from 'vuex'

export default {
  name: 'UpdateCompany',
  data() {
    return {
      form: new Form({
          name: '',
          email: '',
          wechat: '',
      }),
      platformId: 'ios',
    }
  },
  computed: mapGetters({
    user: 'auth/user'
  }),
  created () {
    // Fill the form with user data.
    this.form.keys().forEach(key => {
      this.form[key] = this.user[key]
    })
  },
  mounted() {
    if (!Device.cordova) {
      this.platformId = 'ios'  //pcの場合iosとする
    } else {
      this.platformId = cordova.platformId //ios,android,osx
    }
  },
  methods: {
    item_change(item_name) {
      this.form.errors.clear(item_name)
    },
    async update () {
      await this.form.patch('/api/admin/settings/profile')
      .then(response => {
        this.$store.dispatch('auth/updateUser', { user: response.data })
        let messageText = ''
        messageText = this.$t('update success')
        var self = this
        // Create toast
        var toastSuccess = self.$f7.toast.create({
          text: messageText,
          position: 'center',
          closeTimeout: 2000,
        })
        toastSuccess.open()
        this.$f7router.back()
      })
      .catch(error => {
        if(error.response.status == 422){
        } else {
          swal({
            icon: "error",
            text: error,
          });
        }
      });
    },
  }
}
</script>
<style>
</style>
