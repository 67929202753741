<template>
  <f7-page>
    <f7-navbar :title="myTitle" :back-link="$t('back')"></f7-navbar>
    <f7-list inline-labels no-hairlines-md form class="">
        <!-- 稼働可能日 -->
        <f7-list-input
            :label="$t('available day')"
            type="datepicker"
            readonly
            :placeholder="$t('please select')"
            :value="form.available_day_array"
            @calendar:change="available_day_calendar_change"
            :calendar-params="{openIn: 'customModal', header: true, footer: true, dateFormat: 'yyyy/mm/dd'}"
            @change="item_change('available_day')"
            clear-button>
            <!-- <span slot="info">
              <f7-list-item checkbox title="即日" name="checkbox-1"
                  :checked="form.available_day_checked"
                  @change="handleChecked">
              </f7-list-item>
            </span> -->
            <span slot="info" style="color:#ff0000">{{ form.errors.get('available_day') }}</span>
        </f7-list-input>
        <f7-list-item>
            <f7-col></f7-col>
            <f7-col>
              <f7-checkbox
                name="checkbox-1"
                :checked="form.available_day_checked"
                @change="handleChecked">
              </f7-checkbox>　即日
            </f7-col>
            <f7-col></f7-col>
        </f7-list-item>
        <!-- 件名 -->
        <f7-list-input
            :label="$t('project name')"
            type="text"
            :value="form.project_name"
            @input="form.project_name = $event.target.value"
            @change="item_change('project_name')"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('project_name') }}</span>
        </f7-list-input>
        <!-- 案件説明 -->
        <f7-list-input
            :label="$t('req description')"
            type="textarea"
            resizable
            :value="form.req_desc"
            @input="form.req_desc = $event.target.value"
            @change="item_change('req_desc')"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('req_desc') }}</span>
        </f7-list-input>
        <!-- wechat_no -->
        <f7-list-input
            :label="$t('wechat no')"
            type="text"
            :value="form.wechat_no"
            @input="form.wechat_no = $event.target.value"
            @change="item_change('wechat_no')"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('wechat_no') }}</span>
        </f7-list-input>
        <f7-list-button v-if="platformId=='ios'"
            :title="$t('input detail')"
            v-on:click.stop.prevent="DetailShowControl">
        </f7-list-button>
        <f7-list-item v-else>
            <f7-col></f7-col>
            <f7-col>
              <f7-button large v-on:click.stop.prevent="DetailShowControl">
                {{ $t('input detail') }}
              </f7-button>
            </f7-col>
            <f7-col></f7-col>
        </f7-list-item>
        <!-- スキル -->
        <f7-list-item v-show="form.detail_show"
            :title="$t('skill')" smart-select :smart-select-params="{openIn: 'popup', searchbar: true, searchbarPlaceholder: ''}">
            <select name="skill_list" multiple v-model="form.skill_list_f7" @change="item_change('skill_list_f7')">
              <optgroup v-for="items in skill_list" :label="items.label">
                <option v-for="item in items.children" :value="item.value">{{ item.label }}</option>
              </optgroup>
            </select>
            <span style="color:#ff0000" v-if="form.errors.has('skill_list')" v-text="form.errors.get('skill_list')"></span>
            <div slot="after-title" v-show="form_show.skill_list_f7">
              {{ form.skill_list_f7.join(',').length>30? form.skill_list_f7.join(',').substr(0,30)+'...' : form.skill_list_f7.join(',')}}</div>
        </f7-list-item>
        <!-- 語学能力 -->
        <f7-list-item v-show="form.detail_show"
            :title="$t('language skills')" smart-select :smart-select-params="{openIn: 'popup', searchbar: true, searchbarPlaceholder: ''}">
            <select name="foreign_language_list" multiple v-model="form.foreign_language_list_f7" @change="item_change('foreign_language_list_f7')">
              <optgroup v-for="items in foreign_language_list" :label="items.label">
                <option v-for="item in items.children" :value="item.value">{{ item.label }}</option>
              </optgroup>
            </select>
            <span style="color:#ff0000" v-if="form.errors.get('foreign_language_list')" v-text="form.errors.get('foreign_language_list')"></span>
            <div slot="after-title" v-show="form_show.foreign_language_list_f7">
              {{ form.foreign_language_list_f7.join(',').length>30? form.foreign_language_list_f7.join(',').substr(0,30)+'...' : form.foreign_language_list_f7.join(',')}}</div>
        </f7-list-item>
        <!-- 参画工程 -->
        <f7-list-item v-show="form.detail_show"
            :title="$t('process')" smart-select>
            <select name="process_list" multiple v-model="form.process_list" @change="item_change('process_list')">
              <option v-for="item in process_list" :value="item.value">
                  {{ item.value }}
              </option>
            </select>
            <span style="color:#ff0000" v-if="form.errors.get('process_list')" v-text="form.errors.get('process_list')"></span>
            <div slot="after-title" v-show="form_show.process_list">
              {{ form.process_list.join(',').length>18? form.process_list.join(',').substr(0,18)+'...' : form.process_list.join(',')}}</div>
        </f7-list-item>
        <!-- 管理経験 -->
        <f7-list-item v-show="form.detail_show"
            :title="$t('manage experience')" smart-select>
            <select name="position_list" multiple v-model="form.position_list" @change="item_change('position_list')">
              <option v-for="item in position_list" :value="item.value">
                  {{ item.value }}
              </option>
            </select>
            <span style="color:#ff0000" v-if="form.errors.get('position_list')" v-text="form.errors.get('position_list')"></span>
            <div slot="after-title" v-show="form_show.position_list">
              {{ form.position_list.join(',').length>30? form.position_list.join(',').substr(0,30)+'...' : form.position_list.join(',')}}</div>
        </f7-list-item>
        <!-- 業務経験 -->
        <!-- <f7-list-item :title="$t('business experience')" smart-select :smart-select-params="{openIn: 'popover'}">
            <select name="business_list" multiple v-model="form.business_list" @change="item_change('business_list')">
              <option v-for="item in business_list" :value="item.value">
                  {{ item.value }}
              </option>
            </select>
            <span style="color:#ff0000" v-if="form.errors.get('business_list')" v-text="form.errors.get('business_list')"></span>
            <div slot="after-title" v-show="form_show.business_list">
              {{ form.business_list.join(',').length>30? form.business_list.join(',').substr(0,30)+'...' : form.business_list.join(',')}}</div>
        </f7-list-item> -->
        <!-- 経験年数 -->
        <f7-list-input v-show="form.detail_show"
            :label="$t('experience years')"
            type="text"
            :value="form.experience_years"
            pattern="[0-9]*"
            @input="form.experience_years = $event.target.value"
            @change="item_change('experience_years')"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('experience_years') }}</span>
        </f7-list-input>
        <!-- 希望単価 -->
        <f7-list-input v-show="form.detail_show"
            :label="$t('desired price')"
            type="text"
            :value="form.desired_price"
            pattern="[0-9]*"
            @input="form.desired_price = $event.target.value"
            @change="item_change('desired_price')"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('desired_price') }}</span>
        </f7-list-input>
        <!-- 最寄りの駅 -->
        <f7-list-input v-show="form.detail_show"
            :label="$t('nearest station')"
            type="text"
            :value="form.nearest_station"
            @input="form.nearest_station = $event.target.value"
            @change="item_change('nearest_station')"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('nearest_station') }}</span>
        </f7-list-input>
        <!-- コメント -->
        <f7-list-input v-show="form.detail_show"
            :label="$t('comment')"
            type="textarea"
            :value="form.comment"
            @input="form.comment = $event.target.value"
            @change="item_change('comment')"
            clear-button>
            <span slot="info" style="color:#ff0000">{{ form.errors.get('comment') }}</span>
        </f7-list-input>
        <f7-list-button v-if="platformId=='ios'"
            type="submit"
            :loading="form.busy"
            v-on:click.stop.prevent="AddOrUpdateProject">
            {{ $t('confirm') }}
        </f7-list-button>
        <f7-list-item v-else>
            <f7-col></f7-col>
            <f7-col>
              <f7-button large v-on:click.stop.prevent="AddOrUpdateProject">
                {{ $t('confirm') }}
              </f7-button>
            </f7-col>
            <f7-col></f7-col>
        </f7-list-item>
    </f7-list>
  </f7-page>
</template>

<script>
import { Device }  from 'framework7/framework7-lite.esm.bundle.js';
import axios from 'axios'
import swal from "sweetalert"
import Form from 'vform'
import moment from 'moment';
import { mapGetters } from 'vuex'

export default {
  name: 'AddUpdateProject',
  data() {
      return {
        add_update_mode: '1',
        project_id: null,
        myTitle: '',
        foreign_language_list: [],
        skill_list: [],
        process_list: [],
        position_list: [],
        business_list: [],
        form: new Form({
            detail_show: false,
            id: '',
            project_name: '',
            req_desc: '',
            foreign_language_list_f7: [],
            foreign_language_list: [],
            skill_list_f7: [],
            skill_list: [],
            available_day: '',
            available_day_array: [],
            available_day_checked: false,
            process_list: [],
            nearest_station: '',
            desired_price: '',
            experience_years: '',
            position_list: [],
            wechat_no: '',
            comment: '',
        }),
        form_show: new Form({
            foreign_language_list_f7: false,
            skill_list_f7: false,
            process_list: false,
            experience_years: false,
            position_list: false,
        }),
        platformId: 'ios',
      }
  },
  computed: mapGetters({
    user: 'auth/user',
  }),
  created() {
    let options = JSON.parse(this.$f7route.params.options)
    this.add_update_mode = options.add_update_mode
    if (this.add_update_mode == '2') {
      this.project_id = options.project_id
      this.form_show.keys().forEach(key => {
        this.form_show[key] = true
      })
    }
    if (!Device.cordova) {
      this.platformId = 'ios'  //pcの場合iosとする
    } else {
      this.platformId = cordova.platformId //ios,android,osx
    }
  },
  mounted() {
    if (this.add_update_mode == '1') {
      this.myTitle = this.$t('project add')
      this.form.clear()
      this.form.reset()
      this.form.wechat_no = this.user.wechat
      this.get_skill_list()
      // this.resetForm()
    } else if (this.add_update_mode == '2') {
      this.myTitle = this.$t('project update')
      this.get_skill_list()
      this.get_project()
    }
  },
  methods: {
    DetailShowControl() {
      if (this.form.detail_show) {
        this.form.detail_show = false
      } else {
        this.form.detail_show = true
      }
    },
    handleChecked(event) {
      if (event.target.checked) {
        this.form.available_day_checked = true
      } else {
        this.form.available_day_checked = false
      }
      this.form.available_day_array = []
      this.form.available_day = ''
      this.form.errors.clear('available_day')
    },
    available_day_calendar_change(val) {
      if (val.length==0) {
        this.form.available_day_array = []
        this.form.available_day = ''
      } else {
        this.form.available_day_array = val
        this.form.available_day = moment(val[0]).format("YYYY/MM/DD")
        this.form.available_day_checked = false
      }
    },
    item_change(item_name) {
      this.form.errors.clear(item_name)
      // this.$refs[item_name].clearValidate() // 清除验证
      if (this.add_update_mode == '2') {
        this.form_show[item_name] = false
      }
    },
    async AddOrUpdateProject() {
      let url = ''
      if (this.add_update_mode == '1') {
        url = '/api/admin/project/add_project_mobile'
      } else {
        url = '/api/admin/project/update_project_mobile'
      }
      this.form.skill_list = [];
      for (var i=0; i<this.form.skill_list_f7.length; i++) {
        for (var j=0; j<this.skill_list.length; j++) {
          for (var k=0; k<this.skill_list[j].children.length; k++) {
            if (this.skill_list[j].children[k].value == this.form.skill_list_f7[i]) {
              this.form.skill_list.push([this.skill_list[j].value,this.form.skill_list_f7[i]])
              break;
            }
          }
        }
      }
      this.form.foreign_language_list = [];
      for (var i=0; i<this.form.foreign_language_list_f7.length; i++) {
        for (var j=0; j<this.foreign_language_list.length; j++) {
          for (var k=0; k<this.foreign_language_list[j].children.length; k++) {
            if (this.foreign_language_list[j].children[k].value == this.form.foreign_language_list_f7[i]) {
              this.form.foreign_language_list.push([this.foreign_language_list[j].value,this.form.foreign_language_list_f7[i]])
              break;
            }
          }
        }
      }
      if (this.form.available_day_checked) {
        this.form.available_day = '即日'
      }
      await this.form.patch(url)
      .then(response => {
        this.status = response.data.status
        //clear the form errors
        this.form.clear()
        this.form.reset()
        let messageText = ''
        if (this.add_update_mode == '1') {
          messageText = this.$t('add success')
        } else {
          messageText = this.$t('update success')
        }
        var self = this
        // Create toast
        var toastSuccess = self.$f7.toast.create({
          text: messageText,
          position: 'center',
          closeTimeout: 2000,
        })
        toastSuccess.open()
        // this.$f7router.back()
        this.$f7router.navigate('/manage/')
      })
      .catch(error => {
        if(error.response.status == 422){
          if (error.response.data.errors) {
          } else {
            if (error.response.data.message) {
              swal({
                icon: "warning",
                text: error.response.data.message,
              });
            }
          }
        } else {
          swal({
            icon: "error",
            text: error,
          });
        }
      });
    },
    async get_skill_list() {
      this.foreign_language_list = []
      this.skill_list = []
      this.process_list = []
      this.position_list = []
      this.business_list = []
      const { data } = await axios.get('/api/engineer/get_skill_list')
      this.foreign_language_list = data.foreign_language_list
      this.skill_list = data.skill_list
      this.process_list = data.process_list
      this.position_list = data.position_list
      this.business_list = data.business_list
    },
    get_project() {
      const options = {
        params: {
          id: this.project_id,
        }
      };
      axios.get('/api/admin/project/get_project', options)
      .then(response => {
        let item = response.data.project
        this.form.keys().forEach(key => {
          this.form[key] = item[key]
        })
        this.form.detail_show = true
        this.form.foreign_language_list_f7 = []
        for (var i=0; i<this.form.foreign_language_list.length; i++) {
          this.form.foreign_language_list_f7.push(this.form.foreign_language_list[i][1])
        }
        this.form.skill_list_f7 = []
        for (var i=0; i<this.form.skill_list.length; i++) {
          this.form.skill_list_f7.push(this.form.skill_list[i][1])
        }
        if (this.form.available_day_checked) {
          this.form.available_day_array = []
        } else {
          this.form.available_day_array = [moment(this.form.available_day)]
        }
      })
      .catch(function (error) {
        swal({
          icon: "error",
          text: error,
        })
      })
    },
    resetForm() {
        // this.form.resetFields();
        this.form.clear()
        this.form.reset()
    },
  }
}
</script>
<style>
</style>
