<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left :back-link="$t('back')" back-link-url="/admin/login/" back-link-force>
        <!-- <f7-link href="/admin/login/"></f7-link> -->
      </f7-nav-left>
      <f7-nav-title>{{ $t('reset password') }}</f7-nav-title>
    </f7-navbar>
    <f7-list no-hairlines-md form>
      <f7-list-input
          :label="$t('admin email')"
          type="email"
          :value="form.email"
          @input="form.email = $event.target.value"
          @change="item_change('email')"
          clear-button>
          <span slot="info" style="color:#ff0000">{{ form.errors.get('email') }}</span>
      </f7-list-input>
      <f7-list-button v-if="platformId=='ios'"
          type="submit"
          :loading="form.busy"
          v-on:click.stop.prevent="send()">
          {{ $t('send password reset link') }}
      </f7-list-button>
      <f7-list-item v-else>
          <f7-col></f7-col>
          <f7-col>
            <f7-button large v-on:click.stop.prevent="send()">
              {{ $t('send password reset link') }}
            </f7-button>
          </f7-col>
          <f7-col></f7-col>
      </f7-list-item>
    </f7-list>
  </f7-page>
</template>

<script>
import { Device }  from 'framework7/framework7-lite.esm.bundle.js';
import axios from 'axios'
import swal from "sweetalert"
import Form from 'vform'

export default {
  middleware: 'guest',
  metaInfo () {
    return { title: this.$t('reset password') }
  },
  data: () => ({
    status: '',
    form: new Form({
      email: ''
    }),
    platformId: 'ios',
  }),
  mounted() {
    if (!Device.cordova) {
      this.platformId = 'ios'  //pcの場合iosとする
    } else {
      this.platformId = cordova.platformId //ios,android,osx
    }
  },
  methods: {
    item_change(item_name) {
      this.form.errors.clear(item_name)
    },
    async send () {
      await this.form.post('/api/admin/password/email')
      .then(response => {
        this.status = response.data.status
        //clear the form errors
        this.form.clear()
        this.form.reset()
        let messageText = ''
        messageText = this.status  //this.$t('mail send success')
        var self = this
        // Create toast
        var toastSuccess = self.$f7.toast.create({
          text: messageText,
          position: 'center',
          closeTimeout: 2000,
        })
        toastSuccess.open()
        // this.$f7router.navigate('/admin/login/')
      })
      .catch(error => {
        if (error.response.status == 422) {
          if (error.response.data.errors) {
          } else {
            if (error.response.data.message) {
              swal({
                icon: "warning",
                text: error.response.data.message,
              });
            }
          }
        } else {
          swal({
            icon: "error",
            text: error,
          })
        }
      })
    },
  }
}
</script>
